import { MultiGraphAddDescriptorModal } from './../../add-descriptor-multi/add-descriptor-multi.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Graph, Translatable, GraphType, CampaignStates, Workflow, Descriptor, DescriptorGroup, DNAGraphs, KeyBoolean, AttributeData, States, Metier, Metiers, Hub } from '../../../../../../../types';
import * as _ from 'lodash';
import { ComponentModalEditNameComponent } from '../../../../../../../blocks/detail/component/edit-name/component-modal-edit-name.component';
import { NgbModalOptions, NgbModal, NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { PowerBIModalAddDescriptorComponent } from '../../add-descriptor/power-bi-modal-add-descriptor.component';
import { ReferenceTypeService } from '../../../../../../../shared/services/reference-type.service';
import { UtilService } from '../../../../../../../shared/services/util.service';
import { Subject, Subscription } from 'rxjs';
import { DNATranslateService } from '../../../../../../../shared/services/translate.service';
import { DragulaOptions, DragulaService } from 'ng2-dragula';
import { AddCharacterizationComponent } from '../../add-caracterization/add-characterization.component';
import { CampaignService } from '../../../../../../../campaigns/campaigns.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'dna-graphs-page',
  templateUrl: './graphs-page.component.html',
  styleUrls: ['./graphs-page.component.less']
})
export class GraphsPageComponent implements OnInit {

  @Input() descriptorsInWorkflow: Descriptor[];
  @Input() graphs: Graph[];
  @Input() workflow: Workflow;
  @Input() descriptorGroup: DescriptorGroup[];
  @Input() editMode: boolean;
  @Input() isMulti: boolean;
  @Input() hub: Hub;
  @Input() protocol_methods: any[];
  @Output() isWorkflowChanged: EventEmitter<Graph[]> = new EventEmitter();
  @Output() isDescriptorsChanged: EventEmitter<DescriptorGroup[]> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgbAccordionDirective) private accordion: NgbAccordionDirective;
  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };
  campaignStates: typeof CampaignStates = CampaignStates;
  typeGraphs: GraphType[] = [];
  currentLanguage: string;
  eventsSubject: Subject<void> = new Subject<void>();
  groupEventsSubject: Subject<DescriptorGroup[]> = new Subject<DescriptorGroup[]>();
  collapsedGraphsTab: KeyBoolean [] = [];
  keyDragulaGraphs: string;
  subs = new Subscription();
  checkedGraphsTab = {};
  isCheckedAllGraphsToCapture: boolean = false;
  workflowStates: typeof States = States;
  noodlesGalenics: string[] = ['Noodles other', 'Noodles cream', 'Noodles serum'];
  evaluationTypes: string[] = ['Single', 'Classical routine', 'Brand routine', 'Single + Classical routine', 'Single + Brand routine'];
  protocolMethodsNoodles: boolean;
  routerSubscription: Subscription;
  currentUrl: string;

  constructor(
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private utilService: UtilService,
    private DNATranslate: DNATranslateService,
    private dragulaService: DragulaService,
    private campaignService: CampaignService,
    private router: Router
  ) {
    const dOptions: DragulaOptions = {
      moves: function (el: Element, container: Element, handle: Element): boolean {
        return handle.className === "card-header" || handle.id === "fa-arrows-div" || handle.id === "fa-arrows";
      }
    }
    this.dragulaService.createGroup("DRAGULA_EVENTS", dOptions);
    this.subs.add(this.dragulaService.drop("DRAGULA_EVENTS").subscribe(() => this.isWorkflowChanged.emit(this.graphs)))
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.protocolMethodsNoodles = this.protocol_methods?.some(item => item.key === "NOODLES");
    this.typeGraphs = this.getGraphType();
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.collapseAll(this.graphs.length-1);
    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });
  }

  public ngOnDestroy(): void {
    this.dragulaService.destroy("DRAGULA_EVENTS");
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  isGenericChart(chartType: GraphType) {
    return chartType.id === DNAGraphs.BarTwoSided.id ||
    chartType.id === DNAGraphs.BulletScale3.id ||
    chartType.id === DNAGraphs.BulletScale5.id ||
    chartType.id === DNAGraphs.LineChartNormalized.id ||
    chartType.id === DNAGraphs.Radar.id ||
    chartType.id === DNAGraphs.LineChart.id ||
    chartType.id === DNAGraphs.Sankey.id ||
    chartType.id === DNAGraphs.Tornado.id ||
    chartType.id === DNAGraphs.HistogramMultiMedian.id ||
    chartType.id === DNAGraphs.TableWithBar.id
  }

  onChange(data: any, indexGraph: number, key: string) {
    this.isMulti
      ? this.graphs[indexGraph].descriptorsGroupIds = data.map(d => d.isGroup ? d : this.formatDescriptorId(d))
      : this.graphs[indexGraph][key] = data;
    this.isWorkflowChanged.emit(this.graphs);
  }

  isGraphToCapture(graph) {
    return _.get(graph, 'toCapture', false);
  }

  /**
   * 20765 Method to return if all graphs are selected to capture
   * @returns boolean
   */
  areAllGraphsSelected() {
    this.isCheckedAllGraphsToCapture = _.every(this.graphs, 'toCapture');
    return this.isCheckedAllGraphsToCapture;
  }

  /**
   * 20765 Select or unselect all graphs to capture
   */
  selectUnSelectAllGraphs() {
    this.isCheckedAllGraphsToCapture = !this.isCheckedAllGraphsToCapture;
    let atLeastOnGraphStatusChange = false;
    this.graphs.forEach(graph => {
      if(this.isCheckedAllGraphsToCapture) {
        if(!graph.toCapture) {
          _.set(graph, 'toCapture', true);
          atLeastOnGraphStatusChange = true;
        }
      } else {
        if(graph.toCapture) {
          _.set(graph, 'toCapture', false);
          atLeastOnGraphStatusChange = true;
        }
      }
    });
    if(atLeastOnGraphStatusChange) {
      this.isWorkflowChanged.emit(this.graphs);
    }
  }

  onChangeGraphToCapture(graph) {
    graph.toCapture = !_.get(graph, 'toCapture', false);
    this.isWorkflowChanged.emit(this.graphs);
  }

  addGraph() {
    let newGraph = new Graph();
    if (newGraph.type && newGraph.type.id === 'PeluchesRisks' && this.protocolMethodsNoodles && (this.hub == 'Japan' || this.hub == 'China') ) {
      newGraph.noodlesGalenic = this.noodlesGalenics[0];
      newGraph.evaluationType = this.evaluationTypes[0];
      newGraph.hub = this.hub;
    }
    this.graphs.push(newGraph);
    this.isWorkflowChanged.emit(this.graphs);
    this.utilService.scrollToBottom();
  }

  compareGraph(a: GraphType, b: GraphType) {
    return a && b && a.id === b.id;
  }

  removeGraph(index: number) {
    if(this.descriptorGroup) {
      _.get(this.graphs[index], 'descriptorsGroupIds', []).forEach((idDescriptor) => {
        const descriptorIndex = this.descriptorGroup.findIndex(dg => dg.id === idDescriptor.id);
        if(_.get(this.descriptorGroup[descriptorIndex],'isGroup', false)) {
          this.descriptorGroup.splice(descriptorIndex, 1);
        }
      });
    }
    this.graphs.splice(index, 1);
    this.isWorkflowChanged.emit(this.graphs);
  }

  duplicateGraph(index: number) {
    let duplicatedGraph = _.omit(_.cloneDeep(this.graphs[index]), 'id');
    this.graphs.push(duplicatedGraph);
    this.isWorkflowChanged.emit(this.graphs);
    this.utilService.scrollToBottom();
  }

  openModalEditComponentName(name: Translatable, indexGraph: number) {
    const modal = this.modalService.open(ComponentModalEditNameComponent, this.modalOption);
    modal.componentInstance.inactive = false;
    modal.componentInstance.label = _.cloneDeep(name);
    modal.result.then(
      (translatedLabel: Translatable) => {
        if (!_.isEqual(name, translatedLabel)) {
          this.isWorkflowChanged.emit(this.graphs);
        }
        this.graphs[indexGraph].name = translatedLabel;
      },
      (reason) => { }
    );
  }

  getGraphType = (): GraphType[] => {
    return this.referenceTypeService.getGraphs(this.isMulti);
  }

  onGraphDetailChange() {
    this.isWorkflowChanged.emit(this.graphs);
  }

  updateGraphType(graphType: GraphType, graph: Graph) {
    graph.type = _.cloneDeep(graphType);
    if (graph.type.id === 'PeluchesRisks' && this.protocolMethodsNoodles && (this.hub == 'Japan' || this.hub == 'China')) {
      graph.noodlesGalenic = this.noodlesGalenics[0];
      graph.evaluationType = this.evaluationTypes[0];
      graph.hub = this.hub;
    }
    this.isWorkflowChanged.emit(this.graphs);
  }

  addDescriptor(graph: Graph, indexGraph: number, workflow: Workflow, isGroup: boolean) {
    let options = _.cloneDeep(this.modalOption);
    options.windowClass = "dna-modal-campaign";

    if (!this.isMulti) {
      const modal = this.modalService.open(PowerBIModalAddDescriptorComponent, options);
      modal.componentInstance.descriptorsInWorkflow = _.cloneDeep(this.descriptorsInWorkflow);
      modal.componentInstance.descriptorsInGraph = _.cloneDeep(graph.descriptors);
      modal.componentInstance.useSwitchComponent = false;
      modal.componentInstance.workflow = workflow;
      if (isGroup) modal.componentInstance.isGroup = isGroup;
      modal.result.then(
        (descriptors: Descriptor[]) => {
          this.graphs[indexGraph].descriptors = this.addSufixOnDescriptors(workflow, descriptors);
          this.isWorkflowChanged.emit(this.graphs);
        },
        (reason) => { }
      );
    }
    else {
      const modal = this.modalService.open(MultiGraphAddDescriptorModal, options);
      modal.componentInstance.descriptorGroupInGraph = this.descriptorGroup.filter(d => _.get(graph, 'descriptorsGroupIds', []).find(dg =>dg.id === d.id)) ;
      modal.componentInstance.descriptorGroup = this.descriptorGroup;
      let groupDescriptorGrouped = _.get(graph, 'descriptorsGroupIds', []).filter(d => d.isGroup);
      if (isGroup) modal.componentInstance.isGroup = isGroup;
      modal.result.then(
        (descriptors: DescriptorGroup[]) => {
          if(isGroup) {
            if (_.isNil(this.graphs[indexGraph].descriptorsGroupIds)) {
              this.graphs[indexGraph].descriptorsGroupIds = [];
            }
            this.graphs[indexGraph].descriptorsGroupIds.push(_.omit(descriptors[0], ['descriptorGroup', 'descriptors']));
          } else {
            this.graphs[indexGraph].descriptorsGroupIds = descriptors.map(d => this.formatDescriptorId(d));
            if (!_.isEmpty(groupDescriptorGrouped)) {
              this.graphs[indexGraph].descriptorsGroupIds = this.graphs[indexGraph].descriptorsGroupIds.concat(groupDescriptorGrouped);
            }
          }
          this.eventsSubject.next();
          this.isWorkflowChanged.emit(this.graphs);
        },
        (reason) => { }
      );
    }
  }

  addSufixOnDescriptors(workflow: Workflow, descriptors: Descriptor[]) {
    return descriptors.map(d => {
      if (d.isGroup) {
        d.listDescriptors.forEach(gd => {
          gd = this.addSuffix(gd, workflow)
        });
      } else {
        d = this.addSuffix(d, workflow);
      }
      return d;
    });
  }

  addSuffix(descripteur: Descriptor, workflow: Workflow) {
    const blockInWorkflow = _.get(workflow, 'blocks', []).find(block => block.id === descripteur.idBlock && block.idInQuestionnaire === descripteur.idInQuestionnaire);
    if(!_.isUndefined(blockInWorkflow)) {
      _.set(descripteur, 'blockSuffix', _.get(blockInWorkflow, 'suffix', ''));
    }
    return descripteur;
  }

  formatDescriptorId(d: DescriptorGroup) {
    return  _.omit(new DescriptorGroup(d.id), ['name', 'descriptorGroup', 'descriptors', 'descriptorsGroupIds'])
  }

  close() {
    this.onCancel.emit();
  }


  private collapseAll(blockNumber) {
    for (let i=0; i<=blockNumber; i++) {
      this.collapsedGraphsTab[i.toString()] = true;
    }
  }

  reduceGraphs() {
    this.accordion.collapseAll();
  }

  chooseCaracterisation(graph: Graph, indexGraph: number) {
    let options = _.cloneDeep(this.modalOption);
    const modal = this.modalService.open(AddCharacterizationComponent, options);
    modal.componentInstance.characterizations = _.cloneDeep(_.get(graph, 'characterizations', []));
    modal.componentInstance.hub = this.campaignService.getArcsSystemFromHub(this.hub);
    modal.componentInstance.metier = new Metier(Metiers.Hair);
    modal.result.then(
      (carac: AttributeData[]) => {
        this.graphs[indexGraph].characterizations = carac.filter(c => c.isSelected);
        this.isWorkflowChanged.emit(this.graphs)
      },
      (reason) => {}
    );
  }

  isConfortChart(graph) {
    return graph.id === DNAGraphs.ConfortCineticLine.id || graph.id === DNAGraphs.ConfortHistogram.id;
  }

}
