<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="modal-header">
  <button class="btn-close" type="button" (click)="cancel()">
    <span class="sr-only">Close</span>
  </button>
</div>
<div class="modal-body">
    <div class="ibox">
      <div class="ibox-content">

        <div *ngIf="urlPhotoReplaced; else displayPhotoSelected">
          <div class="thumbnail-container">
            <img [src]="urlPhotoReplaced | safe" alt="photo replaced" class="thumbnail">
          </div>


        </div>

        <ng-template #displayPhotoSelected>
          <div class="thumbnail-container">
            <!-- <img [src]="photoSelected.photoData | safe" alt="image photo photoSelected" class="thumbnail"/> -->
            <img [src]="(photo && photo.photoData ? photo.photoData : this.fallback) | safe" alt="image photo photoSelected" class="thumbnail" />
          </div>
        </ng-template>
        <span>{{ photoSelected.photoName }}</span>
        <br/>
        <div class="modal-footer" *ngIf="urlPhotoReplaced">
          <button id="cancel" class="btn btn-default mb-4" type="button" (click)="cancel()" translate>CANCEL</button>
          <button class="btn btn-primary mb-4" type="button" (click)="savePhotoReplaced()" translate>SAVE</button>
        </div>
      </div>
    </div>
</div>
<div class="modal-footer">
  <div class="button-container" *ngIf="!urlPhotoReplaced">
    <input type="file" #fileInput name="file" accept="image/*" style="display: none;" (change)="onChangePhoto($event)">
    <div class="wrap-buttons">
        <button class="modal-button" type="button" (click)="chooseFile()" translate>REPLACE</button>
        <button class="modal-button" type="button" (click)="downloadImage()" translate>DOWNLOAD</button>
    </div>
    <div>
        <button class="modal-button" type="button" (click)="addToSelection(photoSelected)" translate>ADD_SELECTION_PHOTOS</button>
    </div>
  </div>
</div>
