
import {catchError, map, tap} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Router
} from '@angular/router';

import * as _ from 'lodash';
import {
  Observable,
  of
} from 'rxjs';

import {
  ActionBarButton,
  CampaignStates,
  DNATypes,
  Evaluation,
  GroupedFormula,
  LightCampaign,
  User,
  UserInCampaign
} from '../../../types';
import {
  UtilService
} from '../../../shared/services/util.service';
import { environment } from '../../../../environments/environment';
import { HttpRestService } from '@src/app/shared/services/httpRest.service';

@Component({
  selector: '[dna-campaigns-row]',
  templateUrl: './campaigns-row.component.html',
  styleUrls: ['./campaigns-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignsRowComponent implements OnChanges {

  @Input() campaign: LightCampaign;
  @Input() users: User[];

  @Output() onClickAction: EventEmitter<ActionBarButton> = new EventEmitter();
  @Output() onClickSwitch: EventEmitter<boolean> = new EventEmitter();
  @Output() onSend: EventEmitter<any> = new EventEmitter();

  actionsButtons: Observable<ActionBarButton[]>;
  evaluationIds: string[] = [];
  formulasNames: string[] = [];
  userName: string;
  referentsOrContributors: UserInCampaign[] = [];
  idWorkflows: string[];

  constructor(
    private router: Router,
    public utilService: UtilService,
    private httpRestService: HttpRestService
  ) { }

  ngOnInit() {
    if (_.get(this.campaign, "formulas")) {
      this.campaign.formulas.forEach((group: GroupedFormula) => this.formulasNames.push(group.name));
      this.formulasNames = _.uniq(this.formulasNames);
      this.userName = this.getReferentsOrContributorsName(this.campaign, this.users);
    }
    if (_.get(this.campaign, "evaluations")) {
      this.evaluationIds = this.campaign.evaluations.reduce(
        (arr: string[], e: Evaluation) => {
          arr.push(e.id);
          return arr;
        },
        []
      );
    }
    this.idWorkflows = _.get(this.campaign, 'workflows', []).map(w => w.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.campaign && changes.campaign.currentValue && changes.campaign.currentValue.state) {
      this.updateActionsButtons();
    }
  }

  private getReferentsOrContributorsName(campaign: LightCampaign, users: User[] = []): string {
    if (campaign.synergy) {
      if (campaign.synergy.referent && campaign.synergy.referent.length) {
        this.referentsOrContributors = this.referentsOrContributors.concat(campaign.synergy.referent);
      }
      if (campaign.synergy.contributors && campaign.synergy.contributors.length) {
        this.referentsOrContributors = this.referentsOrContributors.concat(campaign.synergy.contributors);
      }
    }

    this.referentsOrContributors = this.referentsOrContributors.concat(campaign.accountables);

    if (this.referentsOrContributors && this.referentsOrContributors.length > 0) {
      let user: User;
      this.referentsOrContributors = this.referentsOrContributors.map(
        (refco: UserInCampaign) => {
          user = users.find((user: User) => user.key === _.get(refco, 'key', ''));
          if(refco) refco.name = user === undefined ? "" : user.name;
          return refco;
        }
      );

      this.referentsOrContributors = _.uniqBy(this.referentsOrContributors, "key");
      return _.get(this.referentsOrContributors, ['0', 'name'], '');
    }
    else {
      return "";
    }
  }

  onClickButton(actionsButton: ActionBarButton) {
    this.onClickAction.emit(actionsButton);
  }

  onSendDatalakeData(event: any) {
    this.onSend.emit(event);
  }

  onSwitchChanged(switchState: boolean) {
    this.onClickSwitch.emit(switchState);
  }

  openCampaign(event: MouseEvent): void {
    const url = this.campaign.state === CampaignStates.Published && !this.campaign.isTemplate
      ? ['campaigns', this.campaign.id, 'edit', 'rawdata']
      : ['campaigns', this.campaign.id, 'edit'];
  
    if (event.ctrlKey || event.metaKey) {
      window.open('#'+ this.router.serializeUrl(this.router.createUrlTree(url)), '_blank');
    } else {
      this.router.navigate(url);
    }
  }

  updateActionsButtons() {
    this.actionsButtons = this.utilService.createActionsButtons(DNATypes.Campaign).pipe(
      map(this.utilService.filterByArchive(this.campaign.state)));
  }

  goToActiview(id: string): void {
    if (this.campaign?.x_study_link) {
      window.open(this.campaign.x_study_link);
    } else {      
      this.httpRestService.getActiview(id, true).pipe(
        catchError(err => {
          console.error('Unable to load actiview data. Please try again.');
          return of(err);
        }),
        tap(actiview => {
          if (actiview.output?.x_study_link) {
            window.open(actiview.output.x_study_link);
          } else {
            console.error('x_study_link is not available');
            window.open(environment.actiview_url() + id);
          }
        })
      ).subscribe();
    }
  }

  goToSynergy(id) {
    window.open(environment.synergy_url() + id)
  }

  goToPerformanceCenter(activiewNumber) {
    window.open(environment.pfc_base_url()+ "studies/" + activiewNumber)
  }
}