
import {forkJoin as observableForkJoin,
  Observable
} from 'rxjs';

import {finalize, flatMap, tap} from 'rxjs/operators';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';

import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  CampaignService
} from '../../campaigns/campaigns.service';
import {
  DNATranslateService
} from '../../shared/services/translate.service';
import {
  HttpRestService
} from '../../shared/services/httpRest.service';
import {
  Language,
  User,
  UserWorkspace
} from '../../types';
import {
  UserService
} from '../../shared/services/user.service';
import { SynergyDemandService } from '../../shared/services/synergy-demand.service';
import { MsalService } from '@azure/msal-angular';
import { FilterService } from '../../shared/services/filter.service';
import { WorkspaceService } from '../my-profile/workspaces/workspaces.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'dna-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})

export class LayoutComponent implements OnInit {

  currentLanguage: string;
  currentTheme: string;
  isOpen: boolean;
  languages: string[];
  navItem: string;
  showSpinner: boolean = false;
  theme: string;
  user: User;
  userWorkspaces: UserWorkspace[];

  constructor(
    private campaignService: CampaignService,
    private httpRestService: HttpRestService,
    private modalService: NgbModal,
    private router: Router,
    private translateService: DNATranslateService,
    private userService: UserService,
    private synergyDemandService: SynergyDemandService,
    private worspaceService: WorkspaceService,
    private authService: MsalService,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    this.user = this.userService.getUser();
    if (this.user) {
      this.userWorkspaces = this.user.workspaces || [];
    }
    this.currentTheme = this.theme;
    this.isOpen = false;
    const urltree = this.router.parseUrl(this.router.url);
    this.navItem = urltree.root.children.primary.segments[0].path;
    this.currentLanguage = this.translateService.getLanguage();

    this.translateService.getLanguages().then((res) => {
      this.languages = res;
    });

    this.userService.onUserChanged().subscribe((user: User) => {
      this.user = user;
    });

    this.translateService.isLanguageChanges.subscribe(isChanged => {
      if (isChanged) this.currentLanguage = this.translateService.getLanguage();
    });

    if(this.user && this.user.track){
      this.userService.getFavorites(this.user.track).pipe(
        tap(settings => this.userService.setFavorites(settings.favorites)),
      ).subscribe();
    }
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this.user.language = language;
    this.userService.setUser(this.user);
    this.httpRestService.setFavoriteLanguage(language, this.user.track).subscribe();
    this.translateService.setLanguage(language);
  }

  changeWorkspace(workspace: UserWorkspace = new UserWorkspace()) {
    this.showSpinner = true;
    this.campaignService.removeMetadataOnly();
    this.campaignService.resetCampaigns();
    this.campaignService.setCampaignsSelected([]);
    this.synergyDemandService.resetLocalSynergy();
    this.userService.setUser(this.user, false);
    this.httpRestService.setFavoriteWorkspace(workspace, this.user.track).subscribe();
    this.worspaceService.getWorkspace(workspace.id, this.user.track).pipe(
      tap(workspace => this.user.currentWorkspace = workspace),
      flatMap(() => this.campaignService.getCampaignMetadata()),
      tap(metadata => this.campaignService.setMetadataFromLocalStorage(metadata)),
      //On reset le user pour cette fois ci envoyer l'evenement de refresh aux differentes page de l'application
      tap(() => this.userService.setUser(this.user)),
      tap(() => this.filterService.updateFilterCampaign()),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  isAuthorized(role: string) {
    return this.userService.isAuthorized(role);
  }

  linkDataPrivacy(type: string = "") {
    if (this.translateService.getLanguage() === "french") {
      if (type === "TERMS_OF_USE") {
        window.open("http://workspace-ri.loreal.wans/communities/IMO/DPO/PublicDocumentLibrary/Get%20it%20Right/CGU/Conditions%20G%C3%A9n%C3%A9rales%20utilisation/CONDITIONS%20UTILISATION-FR.pdf?Web=1", "_blank");
      } else {
        window.open("http://workspace-ri.loreal.wans/communities/IMO/DPO/PublicDocumentLibrary/Documents%20de%20r%C3%A9f%C3%A9rences/Politique%20-%20Policies%20Rules/Privacy%20Policy%20Recherche%20et%20Innovation_FR.pdf", "_blank");
      }
    } else {
      if (type === "TERMS_OF_USE") {
        window.open("http://workspace-ri.loreal.wans/communities/IMO/DPO/PublicDocumentLibrary/Get%20it%20Right/CGU/Terms%20of%20use/Terms%20of%20use%20-%20EN.pdf", "_blank");
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  shouldBeDisabled() {
    let segments = this.router.parseUrl(this.router.url).root.children.primary.segments;
    return segments.length === 1 || (segments.length === 2 && segments[1].path === 'multi') || segments[0].path === 'myprofile' ? true : false;
  }

  goToPerformanceCenter() {
    window.open(environment.pfc_base_url());
  }

  navigateToCampaignWithFilterReset(){
    this.filterService.resetStudiesFilter();
    this.router.navigate(['/campaigns']);
  }
}
