<dna-layout>
  <ngx-loading [show]="showSpinner"></ngx-loading>
  <h1 class="mb-4" id="banner-title" translate>LIBRARY_OF_WORKFLOWS</h1>
  <nav aria-label="breadcrumb" id="banner-nav">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" translate>DASHBOARD</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>LIBRARY_OF_WORKFLOWS</li>
    </ol>
  </nav>
  <div class="actions" id="banner-actions">
    <a id="openModal" class="btn btn-primary mb-4 d-flex flex-row gap-1" (click)="openModalNewWF()" >
      <span translate>CREATE </span>
      <span translate>WORKFLOW</span>
    </a>
  </div>
  <div class="block">
    <div class="ibox float-e-margins">
      <div class="ibox-title row cursor-pointer noMarginSide" aria-controls="blockFilter" (click)="isCollapsedWorkflowsFilters = !isCollapsedWorkflowsFilters">
        <h5 class="col-md-6" translate>FILTERS</h5>
        <div class="ibox-tools col-md-6">
            <dna-button-display-only logo="fa fa-star" class="favorite-btn" textToDisplay=" {{'ONLY_DISPLAY_FAVORITE' | translate}}"
              [value]="filter.displayFavoritesOnly"
              (onClick)="updateData(!filter.displayFavoritesOnly, 'displayFavoritesOnly')">
            </dna-button-display-only>
          <button class="btn btn-primary" type="button"
            (click)="searchWorkflows(filter)" translate>SEARCH
          </button>
          <a>
            <i *ngIf="isCollapsedWorkflowsFilters; else isNotCollapsedBlocksFilter" class="fa fa-chevron-down"></i>
            <ng-template #isNotCollapsedBlocksFilter><i class="fa fa-chevron-up"></i></ng-template>
          </a>
        </div>
      </div>
      <div id="workflowsFilter" class="ibox-content" [ngbCollapse]="isCollapsedWorkflowsFilters">
        <div class="row">
          <div class="col-6 my-2">
            <div class="search">
              <dna-search-text [data]="filter.name" (keydown.enter)="searchWorkflows(filter)" (onSearch)="updateData($event, 'name')"></dna-search-text>
            </div>
          </div>
          <div class="col-3 my-2">
            <dna-filter-list [array]="hubs" [filterValue]="filter.hubs"></dna-filter-list>
          </div>
          <div class="col-3 my-2">
            <dna-filter-list [array]="states" [filterValue]="filter.states"></dna-filter-list>
          </div>
        </div>
      </div>
    </div>
    <input id="tourSign" type="hidden" value="workflow">
    <dna-table2 [data]="workflows" [headers]="tableHeaders$ |async" [numberOfObjectsPerPage]="filter.numberOfObjectsPerPage" [pageIndex]="filter.pageIndex"
      (parametersChanged)="onParametersChanged($event)" [reverse]="true" [sortFunction]="sort" [sortable]="true" [title]="'WORKFLOWS'" [totalItems]="totalItems">
      <ng-template #row let-data="data">
        <tr dna-workflow-row class="cursor-pointer" [workflow]="data" [currentLanguage]="currentLanguage"
          (onClick)="onClickActionButton($event, data.id)"></tr>
      </ng-template>
    </dna-table2>
  </div>
</dna-layout>
