import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { VisitsBlocksModalComponent } from './visits-blocks-modal/visits-blocks-modal.component';
import { BlocksSelectedInVisit, CampaignVisit, CampaignVisits, VisitsUnit, VisitBlock, VisitUnitTab, CampaignStates } from '../../../../../types';
import { ReferenceTypeService } from '../../../../../shared/services/reference-type.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-visits-page',
  templateUrl: './visits-page.component.html',
  styleUrls: ['./visits-page.component.less']
})

export class VisitsPageComponent implements OnInit {

  @Input() campaignVisits: CampaignVisits;
  @Input() initSelectedBlocks: Subject<void>;
  @Input() onErrorVisitsValues: boolean;
  @Output() visitsChanged: EventEmitter<any> = new EventEmitter();

  collapsedVisitTab = {};
  units: VisitUnitTab[];
  allBlocks: VisitBlock[];
  blocksSelected: BlocksSelectedInVisit[];
  blocksSelectedUntouched: BlocksSelectedInVisit[];
  campaignStates: typeof CampaignStates = CampaignStates;
  currentLanguage: string;
  routerSubscription: Subscription;
  currentUrl: string;

  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };

  constructor(
    private referenceType: ReferenceTypeService,
    private utilService: UtilService,
    private translateService: DNATranslateService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
   }


  ngOnInit() {
    this.currentLanguage = this.translateService.getLanguage();
    this.allBlocks = _.cloneDeep(_.get(this.campaignVisits, 'blocks', [])).filter(b => b.isActive);
    this.units = this.referenceType.getVisitUnits();
    this.indexingVisits();
    this.calculBlocksByVisit(_.get(this.campaignVisits, 'visits', []), this.allBlocks);
    this.blocksSelectedUntouched = _.cloneDeep(this.blocksSelected);
    this.collapseAllVisitsBlocks();
    this.initSelectedBlocks.subscribe(() => this.blocksSelected = this.blocksSelectedUntouched);
  }

  indexingVisits() {
    _.get(this.campaignVisits, 'visits', []).forEach((v, indexVisit) => {
      v.index = indexVisit;
    });
  }

  private collapseAllVisitsBlocks() {
    _.get(this.campaignVisits, 'visits', []).forEach((visit, indexVisit) => {
      this.collapsedVisitTab[indexVisit] = false;
    });
  }

  calculBlocksByVisit(visits: CampaignVisit[], allBlocks: VisitBlock[]) {
    const blocksInVisits = _.flattenDeep(visits.map(v => {
      v.blocks.map(b => b.index = v.index);
      return v.blocks;
    }));
    this.blocksSelected = allBlocks.map(ab => {
      let blockFoundedInVisit = blocksInVisits.find(bv => bv.id === ab.id && bv.idInQuestionnaire === ab.idInQuestionnaire);
      return !_.isUndefined(blockFoundedInVisit) ?
      {
        id: ab.id,
        idWorkflow: ab.idWorkflow,
        workflowName: ab.workflowName,
        idInQuestionnaire: ab.idInQuestionnaire,
        name: ab.name,
        description: ab.description,
        selected: true,
        index: blockFoundedInVisit.index,
        isActive: blockFoundedInVisit.isActive
      } :
      {
        id: ab.id,
        idWorkflow: ab.idWorkflow,
        workflowName: ab.workflowName,
        idInQuestionnaire: ab.idInQuestionnaire,
        name: ab.name,
        description: ab.description,
        selected: false,
        isActive: ab.isActive
      }
    });
    this.setNameToDisplay(visits, this.blocksSelected);
  }

  /**
   * set the name to display of each block with the name of the visit (block suffix)
   * @param blocksSelected
   */
  private setNameToDisplay(visits: CampaignVisit[], blocksSelected: BlocksSelectedInVisit[]) {
    this.blocksSelected = blocksSelected.map(block => {
      const foundedVisit = visits.find(visit => block.index === visit.index);
      const blockName = !_.isEmpty(block.name[this.currentLanguage]) ? block.name[this.currentLanguage] : block.name['english'];
      block.nameToDisplay = !_.isUndefined(foundedVisit) && !_.isEmpty(foundedVisit.name) ? `${blockName}_${foundedVisit.name}` : blockName;
      return block;
    });
  }


  addVisit() {
    let newVisit = new CampaignVisit();
    newVisit = this.setVisitAdded(newVisit, this.campaignVisits.visits);
    this.campaignVisits.visits.push(newVisit);
    this.indexingVisits();
    this.collapseAllVisitsBlocks();
    this.utilService.scrollToBottom();
    this.visitsChanged.emit();
  }

  setVisitAdded(visit: CampaignVisit, allVisits: CampaignVisit[]) {
    visit.unit = _.get(allVisits, [allVisits.length-1, 'unit'], VisitsUnit.NONE);
    let value = _.get(allVisits, [allVisits.length-1, 'value'], 0);
    visit.value = value+1;
    this.translateService.translateMessage(VisitsUnit[visit.unit.name].symbol)
    .pipe(
      tap((trad: string) => visit.name = trad.concat(visit.value.toString()))
    ).subscribe();
    return visit;
  }

  setVisitUnit(indexVisit: number, unit: string) {
    this.visitsChanged.emit();
    this.campaignVisits.visits[indexVisit].unit = VisitsUnit[unit];
    this.onUnitChange(indexVisit, unit);
  }

  onUnitChange(indexVisit: number, unit: string) {
    this.visitsChanged.emit();
    if(!_.isUndefined(_.get(VisitsUnit[unit], 'symbol'))) {
      this.translateService.translateMessage(VisitsUnit[unit].symbol)
      .subscribe((trad: string) => {
        this.campaignVisits.visits[indexVisit].name = unit === VisitsUnit.NONE.name
          ? this.campaignVisits.visits[indexVisit].value.toString()
          : trad.concat(this.campaignVisits.visits[indexVisit].value.toString())
        if(indexVisit === 0) {
          this.campaignVisits.visits.map((v, index) => {
            v.name = trad.concat(this.campaignVisits.visits[index].value.toString());
            v.unit = this.campaignVisits.visits[indexVisit].unit;
          });
        }
      });
    }
  }

  removeVisit(indexVisit: number) {
    this.campaignVisits.visits.splice(indexVisit, 1);
    this.calculBlocksByVisit(this.campaignVisits.visits, this.allBlocks);
    this.visitsChanged.emit();
  }

  manageBlocks(visit: CampaignVisit, indexVisit: number) {
    this.calculBlocksByVisit(this.campaignVisits.visits, this.allBlocks);
    const modal = this.modalService.open(VisitsBlocksModalComponent, this.modalOption);
    modal.componentInstance.blocksSelected = _.cloneDeep(this.blocksSelected.filter(b => b.isActive));
    modal.componentInstance.indexCurrentVisit = indexVisit;
    modal.componentInstance.visit = visit;
    modal.result.then((blocksSelectedUpdated) => {
      if(this.utilService.isDifferent(this.blocksSelected, blocksSelectedUpdated)) {
        this.visitsChanged.emit();
      }
      this.blocksSelected = blocksSelectedUpdated;
      this.campaignVisits.visits = this.updateVisitBlock(_.cloneDeep(this.campaignVisits.visits), blocksSelectedUpdated, this.allBlocks);
    }, (reason) => { });
  }

  private updateVisitBlock(visits: CampaignVisit[], blocksSelected: BlocksSelectedInVisit[], allBlocks: VisitBlock[]) {
    return visits.map(visit => {
      visit.blocks = allBlocks.filter(block =>
        {
          return !_.isUndefined(blocksSelected.find(b => b.id === block.id && b.idInQuestionnaire === block.idInQuestionnaire && b.index === visit.index && b.selected))
      });
      return visit;
    });
  }

  blockNameWithSuffix(block: VisitBlock, visit: CampaignVisit) {
    const blockName = !_.isEmpty(block.name[this.currentLanguage]) ? block.name[this.currentLanguage] : block.name['english'];
    const visitName = !_.isEmpty(_.get(visit, 'name', [])) ? visit.name : visit.value.toString();
    return `${blockName}_${visitName}`;
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
