import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { throwError as observableThrowError, Subscription } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { Graph, WorkflowConfiguration, CampaignsWorkflows } from '.././../../types';
import * as _ from 'lodash';
import { DNATranslateService } from '../../../shared/services/translate.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowEditModalComponent } from '../../../campaigns/detail/edit-campaign/workflows/workflow-edit/workflow-edit-modal.component';
import { GraphsPageComponent } from '../../../campaigns/detail/edit-campaign/workflows/power-bi/page/graphs-page/graphs-page.component';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-workflows-multi',
  templateUrl: './workflows-multi.component.html',
  styleUrls: ['./workflows-multi.component.less']
})
export class WorkflowsMultiComponent implements OnInit, AfterViewInit {

  showSpinner: boolean = false;
  workflowsOk: boolean = false;
  campaignMultiId: string;
  campaignMultiWorkflows: CampaignsWorkflows[];
  language: string;
  workflowsTab= [];
  initTime = performance.now();
  routerSubscription: Subscription;
  currentUrl: string;

  graphs: Graph[]= [];
  grapsClicked: boolean = false;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
    windowClass: 'dna-modal-campaign'
  };

  constructor(
    private campaignMultiService: MultiCampaignsService,
    private route: ActivatedRoute,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private translateService: DNATranslateService,
    private modalService: NgbModal,
    private router: Router
  ) { 
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Workflows', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.language = this.translateService.getLanguage();
    this.showSpinner = true;
    this.campaignMultiId = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.campaignMultiService.getCampaignsMultiWorkflows(this.campaignMultiId).pipe(
      catchError(err => {
        this.workflowsOk = false;
        return this.onErrorRequest(err);
      }),
      tap(() => this.workflowsOk = true),
      tap(campaignMulti => this.campaignMultiWorkflows = _.cloneDeep(campaignMulti.studies)),
      tap(campaignMulti => this.campaignMultiService.hasNameChanged.next(campaignMulti.name)),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    return observableThrowError(err);
  }

  getWorkflowConfiguration(campaignId: string, workflowId: string) {
    this.showSpinner = true;
    this.campaignMultiService.getCampaignsMultiWorkflowConfiguration(campaignId, workflowId).pipe(
      catchError(err => {
        return this.onErrorRequest(err);
      }),
      tap(workflowConfiguration => this.openWorkflowConfiguration(workflowConfiguration)),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  private openWorkflowConfiguration(workflowConfiguration: WorkflowConfiguration) {
    const modal = this.modalService.open(WorkflowEditModalComponent, this.modalOption);
    modal.componentInstance.isWorkflowEditable = false;
    modal.componentInstance.workflowName = workflowConfiguration;
    modal.componentInstance.workflowConfiguration = workflowConfiguration;
    modal.componentInstance.consultationMode = true;
    modal.result.then(
      (result) => {},
      (reason) => { }
    );
  }

  getWorkflowGraphs(campaignId: string, workflowId: string) {
    this.showSpinner = true;
    this.campaignMultiService.getCampaignsMultiWorkflowGraphs(campaignId, workflowId).pipe(
      catchError(err => {
        return this.onErrorRequest(err);
      }),
      tap(result => this.openWorkflowGraphs(result.list)),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  private openWorkflowGraphs(workflowGraphs: Graph[]) {
    if(!_.isEmpty(workflowGraphs)) {
      const modal = this.modalService.open(GraphsPageComponent, this.modalOption);
      modal.componentInstance.graphs = workflowGraphs;
      modal.componentInstance.editMode = false;
      modal.componentInstance.onCancel.subscribe(() => {
        this.modalService.dismissAll();
      })
      modal.result.then(
        (result) => {},
        (reason) => { }
      );
    } else {
      this.errorManager.displayMessage('VIZUALIZATIONS.BOARD.EMPTY', 'info');
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
