import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from './shared/services/util.service';
import { Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { HttpRestService } from './shared/services/httpRest.service';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { DNATranslateService } from './shared/services/translate.service';
import { User } from './types';
import { environment } from '@env';
import * as _ from 'lodash';

@Component({
  selector: 'dna-app',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  applicationIn = false;
  account: AccountInfo | null = null;
  isIframe = window !== window.parent && !window.opener;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private utilService: UtilService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private sessionUser: UserService,
    private httpRestService: HttpRestService,
    private dnaTranslateService: DNATranslateService
  ) {
    this.utilService.saveRedirectURL(window.location.href.split('#')[1]);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit() {
    this.authService.initialize().subscribe();
    this.authService.handleRedirectObservable().subscribe();
    this.broadcastService.inProgress$
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        const account = this.authService.instance.getAllAccounts()[0];
        if (account || [InteractionStatus.Login, InteractionStatus.Startup, InteractionStatus.HandleRedirect,
          InteractionStatus.None].every(status => status !== result)) {
          this.authService.instance.setActiveAccount(account);
          this.checkoutAccount();
        }
      });
  }

  deleteCacheAndReload() {
    localStorage.clear();
    location.reload();
  }

  redirect() {
    const url = this.utilService.getUrl();
    this.utilService.removeUrl();
    if (!this.sessionUser.getUser().workspaces) {
      this.router.navigate(['noWorkspaces']);
    } else if (_.isEmpty(url) || '/' === url) {
      this.router.navigate([this.router.url]);
    } else if (url.includes('code')) {
      this.router.navigate(['campaigns']);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  checkoutAccount() {
    this.account = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];
    this.loggedIn = !!this.account;

    if (this.loggedIn && !this.applicationIn) {
      const silentRequest: SilentRequest = {
        scopes: ['user.read'],
        account: this.account,
      };
      this.authService.acquireTokenSilent(silentRequest).subscribe({
        next: (response) => {
          this.account = response.account;
          this.applicationIn = true;
          this.login();
        },
        error: (err) => {
          this.loggedIn = false;
          this.login();
        }
      });
    } else {
      this.login();
    }
  }

  login() {
    this.httpRestService.loginV2(this.getNavigatorLanguage()).pipe(
      tap(this.setDefaultLanguage),
      tap(data => this.sessionUser.setPeopleUserKey(data.peopleUserKey)),
      mergeMap((resp) => this.httpRestService.getBuiltUser(resp.peopleKey).pipe(
        tap((builtUser: User) => {
          const user = this.sessionUser.getUser();
          if (!user) {
            this.sessionUser.setUser(builtUser);
          }
          this.dnaTranslateService.setLanguage(builtUser.language || this.getNavigatorLanguage());
          this.dnaTranslateService.isLanguageChanges.next(true);
        }),
      )),
    ).subscribe(() => this.redirect());
  }

  setDefaultLanguage = () => {
    this.translate.setDefaultLang(this.getNavigatorLanguage());
  }

  getNavigatorLanguage() {
    const language = window.navigator.language;
    switch (language.substr(0, 2)) {
      case 'fr':
        return 'french';
      case 'es':
        return 'spanish';
      case 'ja':
        return 'japanese';
      case 'pt':
        return 'portuguese';
      case 'zh':
        return 'chinese';
      default:
        return 'english';
    }
  }
}
