<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-lg my-auto">
        <h5 class="mr-1">{{ "TARGET" | translate }}</h5>
      </div>
      <div class="col-12 col-lg my-auto">
        <dna-checkbox class="d-inline-block" [isChecked]="allFormChecked" [disabled]="publishedTemplate" [text]="(allFormChecked ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate" (onBooleanChanged)="allFormChecked ? uncheckAllForm() : checkAllForm()"></dna-checkbox>
      </div>
      <div class="col-12 col-lg my-auto">
        <div class="float-end d-flex flex-row gap-1" *ngIf="isChangedTarget()">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit()" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div class="ibox-content" *ngIf="target">
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'LENGTH'" ngbTooltip="{{ (lineSelectedObject.length ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.length"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.length = selectUnselectLine(target.length, lineSelectedObject.length)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let length of target.length; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="length.IsValue" [disabled]="publishedTemplate" [text]="length.Text" (onBooleanChanged)="onChangeData(target.length, index)">
            </dna-checkbox>
          </div>
        </div> 
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'DEGRE_FRISURE'" ngbTooltip="{{ (lineSelectedObject.degreFrisure ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.degreFrisure"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.degreFrisure = selectUnselectLine(target.degreFrisure, lineSelectedObject.degreFrisure)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let degreFrisure of target.degreFrisure; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="degreFrisure.IsValue" [disabled]="publishedTemplate" [text]="degreFrisure.Text" (onBooleanChanged)="onChangeData(target.degreFrisure, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'REGULARITE_FRISURE'" ngbTooltip="{{ (lineSelectedObject.regulariteFrisure ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.regulariteFrisure"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.regulariteFrisure = selectUnselectLine(target.regulariteFrisure, lineSelectedObject.regulariteFrisure)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let regulariteFrisure of target.regulariteFrisure; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="regulariteFrisure.IsValue" [disabled]="publishedTemplate" [text]="regulariteFrisure.Text" (onBooleanChanged)="onChangeData(target.regulariteFrisure, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>  
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'FRISOTTIS'" ngbTooltip="{{ (lineSelectedObject.frisottis ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.frisottis"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.frisottis = selectUnselectLine(target.frisottis, lineSelectedObject.frisottis)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let frisottis of target.frisottis; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="frisottis.IsValue" [disabled]="publishedTemplate" [text]="frisottis.Text" (onBooleanChanged)="onChangeData(target.frisottis, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'ALOPECIE_FEMME'" ngbTooltip="{{ (lineSelectedObject.alopecieFemme ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.alopecieFemme"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.alopecieFemme = selectUnselectLine(target.alopecieFemme, lineSelectedObject.alopecieFemme)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let alopecieFemme of target.alopecieFemme; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="alopecieFemme.IsValue" [disabled]="publishedTemplate" [text]="alopecieFemme.Text" (onBooleanChanged)="onChangeData(target.alopecieFemme, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'ALOPECIE_HOMME'" ngbTooltip="{{ (lineSelectedObject.alopecieHomme ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.alopecieHomme"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.alopecieHomme = selectUnselectLine(target.alopecieHomme, lineSelectedObject.alopecieHomme)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let alopecieHomme of target.alopecieHomme; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="alopecieHomme.IsValue" [disabled]="publishedTemplate" [text]="alopecieHomme.Text" (onBooleanChanged)="onChangeData(target.alopecieHomme, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'BASE_NATURELLE'" ngbTooltip="{{ (lineSelectedObject.baseNaturelle ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.baseNaturelle"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.baseNaturelle = selectUnselectLine(target.baseNaturelle, lineSelectedObject.baseNaturelle)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let baseNaturelle of target.baseNaturelle; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="baseNaturelle.IsValue" [disabled]="publishedTemplate" [text]="baseNaturelle.Text" (onBooleanChanged)="onChangeData(target.baseNaturelle, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'PAS_CHEVEUX_BLANCS'" ngbTooltip="{{ (lineSelectedObject.cheveuxBlancs ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheveuxBlancs"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheveuxBlancs = selectUnselectLine(target.cheveuxBlancs, lineSelectedObject.cheveuxBlancs)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheveuxBlancs of target.cheveuxBlancs; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheveuxBlancs.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancs.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancs, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'POURCENT_CHEVEUX_BLANCS_DEVANT'" ngbTooltip="{{ (lineSelectedObject.cheveuxBlancsPourcentDevant ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheveuxBlancsPourcentDevant"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheveuxBlancsPourcentDevant = selectUnselectLine(target.cheveuxBlancsPourcentDevant, lineSelectedObject.cheveuxBlancsPourcentDevant)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheveuxBlancsPourcentDevant of target.cheveuxBlancsPourcentDevant; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheveuxBlancsPourcentDevant.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancsPourcentDevant.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancsPourcentDevant, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'POURCENT_CHEVEUX_BLANCS_NUQUE'" ngbTooltip="{{ (lineSelectedObject.cheveuxBlancsPourcentNuque ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheveuxBlancsPourcentNuque"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheveuxBlancsPourcentNuque = selectUnselectLine(target.cheveuxBlancsPourcentNuque, lineSelectedObject.cheveuxBlancsPourcentNuque)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheveuxBlancsPourcentNuque of target.cheveuxBlancsPourcentNuque; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheveuxBlancsPourcentNuque.IsValue" [disabled]="publishedTemplate" [text]="cheveuxBlancsPourcentNuque.Text" (onBooleanChanged)="onChangeData(target.cheveuxBlancsPourcentNuque, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'ABONDANCE_CHEVEUX'" ngbTooltip="{{ (lineSelectedObject.abondanceCheveux ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.abondanceCheveux"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.abondanceCheveux = selectUnselectLine(target.abondanceCheveux, lineSelectedObject.abondanceCheveux)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let abondanceCheveux of target.abondanceCheveux; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="abondanceCheveux.IsValue" [disabled]="publishedTemplate" [text]="abondanceCheveux.Text" (onBooleanChanged)="onChangeData(target.abondanceCheveux, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'SENSIBILISATION'" ngbTooltip="{{ (lineSelectedObject.sensibilisation ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.sensibilisation"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.sensibilisation = selectUnselectLine(target.sensibilisation, lineSelectedObject.sensibilisation)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let sensibilisation of target.sensibilisation; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="sensibilisation.IsValue" [disabled]="publishedTemplate" [text]="sensibilisation.Text" (onBooleanChanged)="onChangeData(target.sensibilisation, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'GROSSEUR_CHEVEUX'" ngbTooltip="{{ (lineSelectedObject.grosseur ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.grosseur"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.grosseur = selectUnselectLine(target.grosseur, lineSelectedObject.grosseur)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let grosseur of target.grosseur; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="grosseur.IsValue" [disabled]="publishedTemplate" [text]="grosseur.Text" (onBooleanChanged)="onChangeData(target.grosseur, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'DURETE'" ngbTooltip="{{ (lineSelectedObject.durete ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.durete"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.durete = selectUnselectLine(target.durete, lineSelectedObject.durete)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let durete of target.durete; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="durete.IsValue" [disabled]="publishedTemplate" [text]="durete.Text" (onBooleanChanged)="onChangeData(target.durete, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'NATURE_CHEVEUX'" ngbTooltip="{{ (lineSelectedObject.natureCheveux ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.natureCheveux"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.natureCheveux = selectUnselectLine(target.natureCheveux, lineSelectedObject.natureCheveux)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let nature of target.natureCheveux; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="nature.IsValue" [disabled]="publishedTemplate" [text]="nature.Text" (onBooleanChanged)="onChangeData(target.natureCheveux, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'NATURE_CUIR_CHEVELU'" ngbTooltip="{{ (lineSelectedObject.natureCuirChevelu ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.natureCuirChevelu"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.natureCuirChevelu = selectUnselectLine(target.natureCuirChevelu, lineSelectedObject.natureCuirChevelu)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let nature of target.natureCuirChevelu; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="nature.IsValue" [disabled]="publishedTemplate" [text]="nature.Text" (onBooleanChanged)="onChangeData(target.natureCuirChevelu, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'CHEVEUX_NATURELS'" ngbTooltip="{{ (lineSelectedObject.cheveuxNaturels ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheveuxNaturels"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheveuxNaturels = selectUnselectLine(target.cheveuxNaturels, lineSelectedObject.cheveuxNaturels)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheveuxNaturels of target.cheveuxNaturels; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheveuxNaturels.IsValue" [disabled]="publishedTemplate" [text]="cheveuxNaturels.Text" (onBooleanChanged)="onChangeData(target.cheveuxNaturels, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'HAUTEUR_TON_COLORATION'" ngbTooltip="{{ (lineSelectedObject.hauteurTonColoration ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.hauteurTonColoration"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.hauteurTonColoration = selectUnselectLine(target.hauteurTonColoration, lineSelectedObject.hauteurTonColoration)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let hauteurTonColoration of target.hauteurTonColoration; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="hauteurTonColoration.IsValue" [disabled]="publishedTemplate" [text]="hauteurTonColoration.Text" (onBooleanChanged)="onChangeData(target.hauteurTonColoration, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'ROUTINE_SHADES'" ngbTooltip="{{ (lineSelectedObject.categorieNuance ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.categorieNuance"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.categorieNuance = selectUnselectLine(target.categorieNuance, lineSelectedObject.categorieNuance)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let categorieNuance of target.categorieNuance; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="categorieNuance.IsValue" [disabled]="publishedTemplate" [text]="categorieNuance.Text" (onBooleanChanged)="onChangeData(target.categorieNuance, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12">
        <dna-checkbox class="d-inline-block" [text]="'COSMETIC_EQUILIBRE'" ngbTooltip="{{ (lineSelectedObject.cosmetiqueEquilibre ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cosmetiqueEquilibre"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cosmetiqueEquilibre = selectUnselectLine(target.cosmetiqueEquilibre, lineSelectedObject.cosmetiqueEquilibre)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cosmetiqueEquilibre of target.cosmetiqueEquilibre; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cosmetiqueEquilibre.IsValue" [text]="cosmetiqueEquilibre.Text" (onBooleanChanged)="onChangeData(target.cosmetiqueEquilibre, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'TINCTORIAL_EQUILIBRE'" ngbTooltip="{{ (lineSelectedObject.tinctorialEquilibre ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.tinctorialEquilibre"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.tinctorialEquilibre = selectUnselectLine(target.tinctorialEquilibre, lineSelectedObject.tinctorialEquilibre)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let tinctorialEquilibre of target.tinctorialEquilibre; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="tinctorialEquilibre.IsValue" [disabled]="publishedTemplate" [text]="tinctorialEquilibre.Text" (onBooleanChanged)="onChangeData(target.tinctorialEquilibre, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'CASSE'" ngbTooltip="{{ (lineSelectedObject.casse ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.casse"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.casse = selectUnselectLine(target.casse, lineSelectedObject.casse)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let casse of target.casse; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="casse.IsValue" [disabled]="publishedTemplate" [text]="casse.Text" (onBooleanChanged)="onChangeData(target.casse, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
    <div class="row border-bottom">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'BARBE'" ngbTooltip="{{ (lineSelectedObject.hommePortBarbe ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.hommePortBarbe"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.hommePortBarbe = selectUnselectLine(target.hommePortBarbe, lineSelectedObject.hommePortBarbe)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let barbe of target.hommePortBarbe; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="barbe.IsValue" [disabled]="publishedTemplate" [text]="barbe.Text" (onBooleanChanged)="onChangeData(target.hommePortBarbe, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>
