import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { NgbActiveModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ICustomButtons } from './shared/modals/customModal/ICustomButtons';
import { Type as TypeCore } from '@angular/core';
// import {
//   SimultaneousComparisonComponent
// } from './campaigns/detail/edit-campaign/formula/simultaneous-comparison/simultaneous-comparison.component';

/*************** COLLECTIONS ***************/

export type DNACollection = DNACollections.Blocks | DNACollections.Campaigns | DNACollections.Evaluations | DNACollections.Workflows;

export enum DNACollections {
  Blocks = 'blocks',
  Campaigns = 'campaigns',
  Evaluations = 'evaluations',
  Workflows = 'workflows'
}

export type ApplicationSide = ApplicationSides.Left | ApplicationSides.Right | ApplicationSides.None;

export enum ApplicationSides {
  Left = 'LEFT',
  Right = 'RIGHT',
  None = 'NONE'
}

/*************** CAMPAIGN INFOS GEN ***************/

export class CampaignPublicationPut {
  evaluations: Evaluation[];
  allSidesNotDefined: boolean;
  errorUpdateSideWithAnswers: boolean;
}

export class CampaignInfosGen {
  id: string;
  name: string;
  actiview?: Actiview;
  arcs?: Arcs[];
  description?: string;
  studyType: Type;
  evaluationType: Type;
  focus: Type;
  synergy: SynergyDemand;
  startDate?: any;
  endDate?: any;
  hub?: Hub;
  isTemplate?: boolean;
  metier?: Metier;
  state: StateCampaign;
  users: UserCampaign;
  fieldWork: any;
  workspaces: string[];
  groupsPanelistsHorsArcs: any[];
  groupsWithStartedEvaluations?: string[];

  constructor() {
    this.name = '';
    this.arcs = [];
    this.description = '';
    this.hub = Hubs.France;
    this.state = CampaignStates.Draft;
    this.evaluationType = new TypeEvaluation();
  }
}

export class CampaignProtocol {
  id: string;
  name: string;
  actiview?: Actiview;
  metier: Metier;
  protocol: Protocol;
  state: StateCampaign;
  isTemplate: boolean;
}

export class CampaignTarget {
  id: string;
  name: string;
  metier: Metier;
  target: Target;
  state: StateCampaign;
  isTemplate: boolean;
}

export class CampaignParameters {
  id: string;
  name: string;
  users: UserCampaign;
  parameters: CampaignParameter;
  state: StateCampaign;
  startDate: any;
}

export type BlocksSelectedInVisit = VisitBlock & { selected?: boolean, nameToDisplay?: string };

export class CampaignVisits {
  id: string;
  name: string;
  visits: CampaignVisit[];
  blocks: VisitBlock[];
  index: number;
  state: StateCampaign;
  visitSelection: boolean;
  nbVisitsByVolunteer: number;
  isTemplate: boolean;
}

export class VisitBlock {
  id: string;
  idWorkflow: string;
  workflowName: Translatable;
  idInQuestionnaire: string;
  name: Translatable;
  description: Translatable;
  index?: number;
  isActive: boolean;
}

export class CampaignVisitsByWorkflow {
  workflow: {
    id: string;
    name: string;
    blocks: Block[];
  };
  visits: CampaignVisit[];
  unitsTab: any;
}


export class CampaignVisit {
  id?: string;
  name: string;
  unit: VisitUnit;
  value: number;
  blocks: VisitBlock[];
  index: number;
  startDate?: any;
  pages: number[];

  constructor() {
    this.name = '';
    this.value = 0;
    this.blocks = [];
  }
}

export const VisitsUnit = {
  NONE: {
    name: 'NONE',
    symbol: ''
  },
  MINUTE: {
    name: 'MINUTE',
    symbol: 'MINUTE_SYMBOL'
  },
  HOUR: {
    name: 'HOUR',
    symbol: 'HOUR_SYMBOL'
  },
  DAY: {
    name: 'DAY',
    symbol: 'DAY_SYMBOL'
  },
  WEEK: {
    name: 'WEEK',
    symbol: 'WEEK_SYMBOL'
  },
  MONTH: {
    name: 'MONTH',
    symbol: 'MONTH_SYMBOL'
  },
  SHAMPOOING: {
    name: 'SHAMPOOING',
    symbol: 'SHAMPOOING_SYMBOL'
  }
};

export enum VisitUnitTab {
  none = 'NONE',
  hour = 'HOUR',
  minute = 'MINUTE',
  day = 'DAY',
  week = 'WEEK',
  month = 'MONTH',
  shampooing = 'SHAMPOOING'
}

export type VisitUnit = typeof VisitsUnit['NONE'] | typeof VisitsUnit['HOUR'] | typeof VisitsUnit['DAY'] | typeof VisitsUnit['WEEK']
  | typeof VisitsUnit['MINUTE'] | typeof VisitsUnit['MONTH'] | typeof VisitsUnit['SHAMPOOING'];

export interface DisplayModePage {
  [key: string]: number[];
}

export class CampaignPublication {
  id: string;
  name: string;
  startDate: any;
  toxAgreement: boolean;
  isTemplate: boolean;
  isRoutine: boolean;
  state: StateCampaign;
  progress?: any;
  users: UserCampaign;
  hasSynergy: boolean;
  allSidesNotDefined: boolean;
  evaluations: Evaluation[];
  workflows: Workflow[];
  formula: FormulaCampaign;
  applicationZone: KeyValue;
  loopAndNoVisits?: {
    idWorkflow: string;
    loopAndNoVisits: boolean;
  }[];
  missVisits: boolean;
  actiview?: Actiview;
  characterizations: AttributeData[];
  metier: Metier;
  hub: Hub;
}

export class CampaignDisplayMode {
  id: string;
  name: string;
  parameters: CampaignParameter;
  state: StateCampaign;
  workflows: Workflow[];
  visits: CampaignVisit[];
  metier: Metier;
}

export class CampaignRawData {
  id: string;
  name: string;
  startDate: any;
  endDate?: any;
  state: StateCampaign;
  isVolunteer: boolean;
  evaluations: Evaluation[];
  workflows: {
    id: string,
    name: Translatable,
    blocks: Block[]
  }[];
  accountables: UserInCampaign[];
  containsPhotos: boolean;
  containsVideos: boolean;
  isConfort: boolean;
  requestNumber: string;
}

export class CampaignAnalyse {
  id: string;
  name: string;
  workflows: Workflow[];
}

export class CampaignWorkflow {
  id: string;
  name: string;
  hub: Hub;
  state: StateCampaign;
  workflows: Workflow[];
  actiview: Actiview;
  parameters: CampaignParameter;
  hasEvaluationsStarted: boolean;
}

export class CampaignWorkflowGraphs {
  id: string;
  name: string;
  state: StateCampaign;
  hub: Hub;
  workflows: Workflow[];
}

export class CampaignOnePager {
  id: string;
  arcs?: Arcs[];
  name: string;
  idOnePager: string;
  state: StateCampaign;
  users: UserCampaign;
  formula: FormulaCampaign;
  protocol?: Protocol;
  startDate?: any;
  updated_on: number;
  endDate?: any;
  synergy?: SynergyDemand;
  hub?: Hub;
  parameters: CampaignParameter;
  target?: Target;
  fieldWork: any;
  actiview?: Actiview;
  orchestra: Orchestra;
  evaluations?: Evaluation[];
  visits: CampaignVisit[];
  workflows?: {
    id?: string;
    name?: Translatable;
  }[];
  metier?: Metier;
}

export interface VisitsByVolunteer {
  visits: {
    id: string,
    name: string,
    stardDate: string
  };
  volunteerName: string;
}

export class CampaignFormulas {
  id: string;
  name: string;
  state: StateCampaign;
  parameters: CampaignParameter;
  formula: FormulaCampaign;
  metier: Metier;
  visits: CampaignVisit[];
}

export class CampaignUsers {
  id: string;
  name: string;
  users: UserCampaign;
  state: StateCampaign;
  actiview?: Actiview;
  arcs?: Arcs[];
  groupsPanelistsHorsArcs?: Arcs[];
}


/*************** ACTION BAR ***************/

export type ActionType =
  ActionTypes.Archive
  | ActionTypes.PutBack
  | ActionTypes.CreateCampaign
  | ActionTypes.Duplicate
  | ActionTypes.Edit
  | ActionTypes.EditChartsSettings
  |
  ActionTypes.EditSynergyDemand
  | ActionTypes.EditList
  | ActionTypes.EditListBlocks
  | ActionTypes.EditListCampaigns
  | ActionTypes.EditListWorkflows
  |
  ActionTypes.EditUsersPreferences
  | ActionTypes.GoToCampaign
  | ActionTypes.GoToMobile
  | ActionTypes.LinkCampaign
  | ActionTypes.Previewing
  | ActionTypes.Remove
  |
  ActionTypes.UnlinkSynergyDemand
  | ActionTypes.Delete
  | ActionTypes.ExportConfort;

export enum ActionTypes {
  Archive = 'ARCHIVE',
  CreateCampaign = 'CAMPAIGN_CREATE',
  Duplicate = 'DUPLICATE',
  Edit = 'EDIT',
  EditChartsSettings = 'EDIT_CHARTS_SETTINGS',
  EditSynergyDemand = 'BRIDGE_EDIT',
  EditList = 'EDIT_LIST',
  EditListBlocks = 'EDIT_LIST_BLOCKS',
  EditListCampaigns = 'EDIT_LIST_CAMPAIGNS',
  EditListWorkflows = 'EDIT_LIST_WORKFLOWS',
  EditUsersPreferences = 'EDIT_PREFERENCES',
  ExportConfort = 'EXPORT_CONFORT',
  GoToCampaign = 'BRIDGE_GO_TO_CAMPAIGN',
  GoToMobile = 'LINK_MOBILE',
  LinkCampaign = 'CAMPAIGN_LINK',
  Previewing = 'PREVIEWING',
  Remove = 'REMOVE',
  PutBack = 'PUT_BACK',
  Delete = 'Delete',
  UnlinkSynergyDemand = 'BRIDGE_UNLINK'
}

export class ActionBarButton {
  id: ActionType;
  icon: string;
  tooltip: string;

  constructor(id: ActionType, icon: string, tooltip: string) {
    this.id = id;
    this.icon = icon;
    this.tooltip = tooltip;
  }
}

/*************** ARGS ***************/

export type SensorTagFunctionalities =
  'ACCELEROMETER'
  | 'BAROMETER'
  | 'GYROSCOPE'
  | 'HUMIDITY'
  | 'LUXOMETER'
  | 'MAGNETOMETER'
  | 'THERMOMETER';

export enum AnswersLeftRight {
  OneAnswerTotal = 1,
  OneAnswerPerSide = 2,
  MultipleAnswer = 3
}

export class Args {
  activatedFunctionalities?: SensorTagFunctionalities[];
  active?: string;
  addOtherOption?: boolean;
  camera?: boolean;
  graph: string;
  cameraMode?: string;
  centralmain?: number | string;
  cmInputKeys?: string[];
  descriptionLeft?: Translatable;
  descriptionRight?: Translatable;
  descriptorKeyType?: any;
  firstValToCalculate?: string;
  fromFile?: boolean;
  fromURL?: boolean;
  functionalities?: SensorTagFunctionalities[];
  answersLeftRight?: AnswersLeftRight.OneAnswerTotal | AnswersLeftRight.OneAnswerPerSide | AnswersLeftRight.MultipleAnswer;
  gallery?: boolean;
  hasFrame?: boolean;
  healthDuration?: number;
  commentary?: boolean;
  hideLabel?: boolean;
  hideLimits?: boolean;
  imgBinary?: any;
  inactive?: boolean;
  interval?: number;
  isIndex?: boolean;
  keys?: any[];
  keysLeft?: DNAKey[];
  centralKey?: DNAKey;
  keysRight?: DNAKey[];
  label: Translatable;
  labelLeft?: Translatable;
  labelRight?: Translatable;
  landscape?: boolean;
  leftRightMode?: boolean;
  leftLegend?: string;
  middleLegend?: string;
  rightLegend?: string;
  link?: string;
  linkRadioVersus?: LinkRadioVersus;
  mediaType?: string;
  mediaURL?: string;
  mediaUUID?: any;
  miliPure2?: string;
  min?: any;
  max?: any;
  minL?: number;
  maxL?: number;
  minR?: number;
  maxR?: number;
  onlabel?: Translatable;
  offlabel?: Translatable;
  operator?: string;
  otherSensorsConnectionDuration?: number;
  placeholder?: Translatable;
  quality?: number;
  rangeMode?: 'single' | 'range';
  recordMode?: string;
  richText?: string;
  size?: PhotoSize;
  step?: number;
  stepL?: number;
  stepR?: number;
  tag: string;
  timer?: number;
  typeSelected?: boolean;
  uploadDone?: boolean;
  uploadStart?: boolean;
  values?: any;
  valuesLeft?: any;
  valuesRight?: any;
  verticalAlignment?: boolean;
  hasCentralValue: boolean;
  centralValue?: any;
  isReversed?: boolean;
  confortCode?: string;
  confortComponent?: string;
  confortValues?: {};
  confortStep?: string;
  /* just for temporary build fix*/
  selectedCondition?: any;
  selectedJump?: any;
  selectedQuestion?: any;

  constructor(label: Translatable = new Translatable(), tag: string = '') {
    this.label = label;
    this.tag = tag;
  }
}

export const DESCRIPTOR_KEY = {
  DEFAULT: {
    id: '1',
    name: 'a b c - a b c'
  },
  ONE: {
    id: '2',
    name: 'c b a - c b a'
  },
  TWO: {
    id: '3',
    name: 'a b c - c b a'
  },
  THREE: {
    id: '4',
    name: 'c b a - a b c'
  },
  FOUR: {
    id: '5',
    name: 'c b a = a b c'
  }
};

export const DescriptorKeyTypes = [
  DESCRIPTOR_KEY.DEFAULT,
  DESCRIPTOR_KEY.ONE,
  DESCRIPTOR_KEY.TWO,
  DESCRIPTOR_KEY.THREE
];

export interface PhotoSize {
  id: number;
  height: number;
  width: number;
}

export class LinkRadioVersus {
  idInBlock: string;
  idInQuestionnaire: string;
  operator?: OperatorType;

  constructor(idInQuestionnaire, idInBlock) {
    this.idInBlock = idInBlock;
    this.idInQuestionnaire = idInQuestionnaire;
    this.operator = OperatorType.INDEX_MOINS_SCALE_SCORE;
  }
}

/*************** BLOCK ***************/

export interface BlockItem extends Block {
  idBlockOrigin: string;
  idWorkflow: string;
  index: number;
  operator?: string;
  type: string;
}

export class Block implements DNAObject {
  id?: string;
  name: Translatable;
  description: Translatable;
  components?: DNAComponent[];
  createdBy?: {
    user: string
  };
  datalakeStatus?: boolean;
  created_on?: number;
  updated_on?: number;
  idInQuestionnaire?: string;
  index?: number;
  isEditable?: boolean;
  outOfDate?: boolean;
  isSuspended?: boolean;
  parameters?: BlockParameter;
  suffix?: string;
  sequenceInWorkflow?: {
    name?: string,
    index?: number,
    idInQuestionnaire?: string,
  };
  state?: State;
  workspaces?: string[];
  _etag?: string;
  parentId?: string;
  referenceId?: string;
  // idInQuestionnaire?: string;

  constructor() {
    this.name = new Translatable();
    this.description = new Translatable();
  }
}

export class BlockParameter {
  delayStart: number;
  delayEnd: number;
  logicJumps: LogicJump[];
  logicJumpNext: LogicJumpNext;
  inactive: boolean;
  isContextual?: boolean;
  isContextualPerEval?: boolean;
  isContextualPerFormula?: boolean;
  mandatory?: boolean;
  report: {
    chartName: Translatable,
    chartType: string,
    dimension: Translatable
    display: boolean,
    order: number
  };
  timeEnd?: any;
  timeStart?: any;

  constructor() {
    this.delayStart = 0;
    this.delayEnd = 0;
    this.inactive = false;
    this.isContextual = false;
    this.logicJumps = [];
    this.logicJumpNext = new LogicJumpNext();
    this.report = {
      chartName: new Translatable(),
      chartType: CHART_TYPE.RAW_DATA_TABLE.key,
      dimension: new Translatable(),
      display: true,
      order: 1
    };
  }
}

export interface BlockLinks {
  blocks: LinkObject;
  cancel: LinkObject;
  publish: LinkObject;
  remove: LinkObject;
  save: LinkObject;
  self: LinkObject;
  suspend: LinkObject;
}

export interface LinkObject {
  allowed: string[];
  href: string;
  link: string;
  query: string[];
  validation?: any;
}

export class DNAProject {
  arcs: ArcsUser[];
  bridge?: string;
  listFormulas: Formula[];
  methods: string[];
  number?: string;
  objectives?: string;
  orchestra?: string;
  startDate?: number;
  endDate?: number;
  studyType?: string;
  title?: string;
  volunteersNumber?: number;

  constructor() {
    this.arcs = [];
    this.listFormulas = [];
    this.methods = [];
  }
}

/*************** CAMPAIGN ***************/

export class Campaign {
  actiview?: Actiview;
  arcs?: Arcs[];
  createdBy?: any;
  datalakeStatus: boolean;
  created_on: number;
  updated_on: number;
  description?: string;
  evaluations?: Evaluation[];
  formula: FormulaCampaign;
  hub?: Hub;
  id: string;
  idOnePager: string;
  isTemplate?: boolean;
  metier?: Metier;
  name: string;
  orchestra: Orchestra;
  parameters: CampaignParameter;
  progress?: any;
  protocol?: Protocol;
  startDate?: any;
  endDate?: any;
  synergy?: SynergyDemand;
  state: StateCampaign;
  oldState?: StateCampaign;
  target?: Target;
  timeZone?: number;
  studyType: Type;
  evaluationType: Type;
  users: UserCampaign;
  workflows?: Workflow[];
  workspaces: any[];
  tabSide?: TabSide[];
  focus: Type;
  fieldWork: any;
  visits: CampaignVisit[];

  constructor(pref: Preference = new Preference()) {
    this.arcs = [];
    this.datalakeStatus = false;
    this.description = '';
    this.formula = new FormulaCampaign();
    this.hub = Hubs.France;
    this.name = '';
    this.parameters = new CampaignParameter(pref);
    this.progress = 0;
    this.state = CampaignStates.Draft;
    this.studyType = pref.campaignType;
    this.evaluationType = new TypeEvaluation();
    this.users = new UserCampaign(pref.users);
    this.workflows = [];
  }
}

/*************** LIGHT CAMPAIGN ***************/

export class LightCampaign {
  accountables?: UserInCampaign[];
  activityNumber?: string;
  x_study_link: string;
  datalakeStatus: boolean;
  created_on: number;
  updated_on: number;
  description: string;
  evaluations: Evaluation[];
  formulas: Formula[];
  hub?: Hub;
  id: string;
  isSelected?: boolean;
  isExported?: boolean;
  isTemplate?: boolean;
  name: string;
  metier?: Metier;
  progress?: any;
  startDate?: number;
  endDate?: number;
  state: StateCampaign;
  oldState?: StateCampaign;
  studyType: Type;
  evaluationType: Type;
  synergy?: SynergyDemand;
  volunteersNumber?: number;
  workflows: {
    id: string,
    name: Translatable
  }[];
  workspaces: string[];
  hasArcs: boolean;

  constructor() {
    this.datalakeStatus = false;
    this.description = '';
    this.formulas = [];
    this.hub = Hubs.France;
    this.name = '';
    this.progress = 0;
    this.state = CampaignStates.Draft;
    this.studyType = new TypeCampaign('CAMPAIGN');
    this.evaluationType = new TypeEvaluation();
  }
}

export class ModalContent {
  title: string;
  message: string;
}

export class CustomModalContent {
  title?: string;
  message?: string;
  windowClass?: string;
  size?: 'sm' | 'lg';
  customButtons?: ICustomButtons[];
  bodyComponent?: TypeCore<any>;
  bodyComponentInputs?: {};
  bodyComponentOutputs?: string[];
  activeModal?: NgbActiveModal;
}

export interface CampaignMetadata {
  users: User[];
  volunteers: User[];
  workflows: Workflow[];
}

export class CampaignParameter {
  displayBlockName: boolean;
  displayFeedback: boolean;
  previousAvailable: boolean;
  pushNotification: boolean;
  pdfReportUserByCol: boolean;
  isRoutine: boolean;
  isTemplate: boolean;
  reminder: {
    active: boolean,
    delay: number
  };
  sequentialMode: boolean;
  randomMode: boolean;
  viewGroup?: ViewGroup;
  editSubmitted: boolean;
  viewType: ViewType;

  constructor(pref: Preference) {
    this.displayBlockName = pref.parameters.blocksName.defaultValue;
    this.displayFeedback = pref.parameters.feedback.defaultValue;
    this.pdfReportUserByCol = pref.parameters.pdfReportUserByCol.defaultValue;
    this.previousAvailable = pref.parameters.returnButton.defaultValue;
    this.editSubmitted = pref.parameters.editSubmitted.defaultValue;
    this.pushNotification = pref.parameters.notificationPush.defaultValue;
    this.isRoutine = false;
    this.isTemplate = false;
    this.reminder = {
      active: pref.parameters.notificationRecall.defaultValue,
      delay: pref.parameters.notificationDelay.value
    };
    this.sequentialMode = false;
    this.randomMode = false;
    this.viewType = pref.defaultView;
  }
}

export class FormulaCampaign {
  listFormulas: GroupedFormula[];
  routines: Routine2[];
  formulas: Formula[];
  anonymousFormula: AnonymousFormula;
  chosenBench: GroupedFormula;
  applicationZone: KeyValue;
  simultaneousComparison: {
    isActive: boolean,
    couples: SimComp[]
  };

  constructor() {
    this.listFormulas = [];
    this.formulas = [];
    this.routines = [];
    this.simultaneousComparison = {
      isActive: false,
      couples: []
    };
    this.anonymousFormula = new AnonymousFormula();
  }
}

export class AnonymousFormula {
  isAnonymous: boolean;
  anonymousCode: object;

  constructor() {
    this.isAnonymous = false;
    this.anonymousCode = {};
  }
}

export class GroupedFormula {
  id: string;
  name: string;
  formula: Formula[];
  index?: number;

  constructor(id: string, name: string, formula: Formula[]) {
    this.id = id;
    this.name = name;
    this.formula = formula;
  }
}

export const TYPE_CAMPAIGN = {
  CONSUMERS: {
    id: '1',
    name: 'CONSUMERS'
  },
  SENSORY_EXPERT: {
    id: '2',
    name: 'SENSORY_EXPERT'
  },
  INSTRUMENTAL: {
    id: '3',
    name: 'INSTRUMENTAL'
  },
  CLINICAL: {
    id: '4',
    name: 'CLINICAL'
  },
  CONFORT: {
    id: '5',
    name: 'SENSORY_CONFORT'
  }
};

export const FOCUS = {
  FORMULATION_SUPPORT: {
    id: '00001738',
    name: 'FORMULATION_SUPPORT'
  },
  COMMUNICATION: {
    id: '00001741',
    name: 'COMMUNICATION'
  },
  KNOWLEDGE: {
    id: '00001739',
    name: 'KNOWLEDGE'
  },
  FOLDER_CLAIM: {
    id: '00001740',
    name: 'FOLDER_CLAIM'
  },
};

export class Image {
  name: string;
  title?: string;
  url: string;
  data?: Observable<string>;
  isSelected?: boolean;
  pagesAdded?: number;
  id?: string;
  indexImage?: number;
  width?: number;
  height?: number;
  size?: any;

  constructor(url: string = '', name: string = '') {
    this.name = name;
    this.url = url;
    this.pagesAdded = 0;
  }
}

export enum Orientation {
  Landscape = 'LANDSCAPE', Portrait = 'PORTRAIT'
}

export enum GridContentType {
  Image, Comment
}

export class Page {
  id: string;
  orientation: Orientation;
  options: GridsterConfig;
  content: Array<Content>;
  totalHeight: any;
}

export interface Content extends GridsterItem {
  type: GridContentType;
  data?: Observable<string>;
  url?: string;
  comment?: string;
  name?: string;
}

export class ImageBlob {
  media: string;
  type: string;

  constructor(media: string = '', type: string = '') {
    this.media = media;
    this.type = type;
  }
}

export class Protocol {
  description: string;
  estimatedContribution: number;
  researchAxes: ActiviewStudyAxe;
  studyType: StudyType;
  method: Method[];


  constructor() {
    this.description = '';
  }
}

export enum Methods {
  absolute = 'ABSOLUTE',
  simultaneousComparison = 'SIMULTANEOUS_COMPARISON',
  versusBareSkin = 'VERSUS_BARE_SKIN'
}

export interface Method {
  key: string;
  value: string;
  code: string;
}

export interface References {
  methods: Method[];
  types: StudyType[];
}


export class ProtocolHair extends Protocol {
  applicationAreas: TypeApplicationArea[];
  applicationOrder: any;
  applicationType: any;
  controlLock: boolean;
  // researchAxes: ActiviewStudyAxe;
  hairSampling: boolean;
  protocol_methods: TypeMethod[];
  // method: Method[];
  scales: TypeScale[];
  simultaneousApplication: boolean;
  studies: ProductsType[];
  // studyType: StudyType;
  substrate: TypeSubstrate;
  test: TypeTest;
  timepoints: number;
  timepointsInterval: string[];

  constructor(
    applicationOrder: string = 'none',
    applicationType: any = TypeApplicationTypes.EntireHead.key,
    controlLock: boolean = false,
    simultaneousApplication: boolean = false,
    substrate: TypeSubstrate = {
      key: TypeSubstrates.Volunteers,
      value: 'Volunteers'
    },
    test: TypeTest = {
      key: TypeTests.MonoApplication,
      value: ''
    },
    timepoints: number = 1
  ) {
    super();
    this.applicationAreas = [];
    this.applicationOrder = applicationOrder;
    this.applicationType = applicationType;
    this.controlLock = controlLock;
    this.protocol_methods = [];
    this.scales = [];
    this.simultaneousApplication = simultaneousApplication;
    this.studies = [];
    this.substrate = substrate;
    this.test = test;
    this.timepoints = timepoints;
    this.timepointsInterval = [''];
  }
}

export class ProtocolSkin extends Protocol {
  applicationAreas: TypeApplicationArea[];
  applicators: TypeApplicationMode[];
  protocol_methods: TypeMethod[];
  studies: ProductsType[];
  test: TypeTest;
  timepoints: number;
  timepointsInterval: string[];

  constructor(
    test: TypeTest = {
      key: TypeTests.VersusBareSkin,
      value: ''
    },
    timepoints: number = 1
  ) {
    super();
    this.applicationAreas = [];
    this.applicators = [];
    this.protocol_methods = [];
    this.studies = [];
    this.test = test;
    this.timepoints = timepoints;
    this.timepointsInterval = [''];
  }
}

export const PelliculesRange = {
  PAS: {
    id: '0',
    name: 'PAS'
  },
  PEU: {
    id: '1',
    name: 'PEU'
  },
  ASSEZ: {
    id: '2',
    name: 'ASSEZ'
  },
  BEAUCOUP: {
    id: '3',
    name: 'BEAUCOUP'
  }
};

export const CheveuxBlancsRange = {
  Pourcent0: {
    id: '0',
    name: '0%'
  },
  Pourcent10_20: {
    id: '1',
    name: '10-20%'
  },
  Pourcent30_40: {
    id: '2',
    name: '30-40%'
  },
  Pourcent50_60: {
    id: '3',
    name: '50-60%'
  },
  Pourcent70_90: {
    id: '4',
    name: '70-90%'
  },
  Pourcent100: {
    id: '5',
    name: '100%'
  }
};

export const AbondanceCheveuxRange = {
  PeuAbondant: {
    id: '0',
    name: 'PEU_ABONDANT'
  },
  MoyenAbondant: {
    id: '1',
    name: 'MOYEN_ABONDANT'
  },
  Abondant: {
    id: '2',
    name: 'ABONDANT'
  },
  TresAbondant: {
    id: '3',
    name: 'TRES_ABONDANT'
  }
};

export const GrosseurCheveuxRange = {
  TresFin: {
    id: '0',
    name: 'TRES_FIN'
  },
  Fin: {
    id: '1',
    name: 'FIN'
  },
  Moyen: {
    id: '2',
    name: 'MOYEN'
  },
  Gros: {
    id: '3',
    name: 'GROS'
  },
  TresGros: {
    id: '4',
    name: 'TRES_GROS'
  }
};

export const DureteCheveuxRange = {
  Mou: {
    id: '0',
    name: 'MOU'
  },
  Moyen: {
    id: '1',
    name: 'MOYEN'
  },
  Dur: {
    id: '2',
    name: 'DUR'
  }
};

export const NatureCuirCheveluRange = {
  Gras: {
    id: '0',
    name: 'GRAS'
  },
  Normal: {
    id: '1',
    name: 'NORMAL'
  },
  Sec: {
    id: '2',
    name: 'SEC'
  }
};

export const NatureCheveuxRange = {
  Gras: {
    id: '0',
    name: 'GRAS'
  },
  Mixte: {
    id: '1',
    name: 'MIXTE'
  },
  Normal: {
    id: '2',
    name: 'NORMAL'
  },
  Sec: {
    id: '3',
    name: 'SEC'
  }
};

export const EtatCuirCheveluRange = {
  SAIN: {
    id: '0',
    name: 'SAIN'
  },
  IRRITE: {
    id: '1',
    name: 'IRRITE'
  }
};

export const CategorieNuanceRange = {
  Dore: {
    id: '0',
    name: 'DORE'
  },
  Cuivre: {
    id: '1',
    name: 'CUIVRE'
  },
  Violine: {
    id: '2',
    name: 'VIOLINE'
  },
  Rouge: {
    id: '3',
    name: 'ROUGE'
  },
  Fondamentales: {
    id: '4',
    name: 'FONDAMENTALE'
  },
  Acajou5: {
    id: '5',
    name: 'ACAJOU_5'
  },
  Acajou15: {
    id: '6',
    name: 'ACAJOU_15'
  },
  Acajou25: {
    id: '7',
    name: 'ACAJOU_25'
  },
  Acajou35: {
    id: '8',
    name: 'ACAJOU_35'
  },
  Eclaircissant: {
    id: '9',
    name: 'ECLAIRCISSANT'
  },
  SuperEclaircissant: {
    id: '10',
    name: 'SUPER_ECLAIRCISSANT'
  },
  RefletCendre: {
    id: '11',
    name: 'ASH_REFLECT'
  },
  RefletMate: {
    id: '12',
    name: 'MATTE_REFLECT'
  }
};

export const PanelistDispoRange = {
  PlusieursJourSemaine: {
    id: '0',
    name: 'PLUSIEURS_JOURS_SEMAINE'
  },
  DeuxFoisSemaine: {
    id: '1',
    name: 'DEUX_FOIS_SEMAINE'
  },
  PlusieursJourMois: {
    id: '2',
    name: 'PLUSIEURS_JOURS_MOIS'
  },
  UneFoisDeuxMois: {
    id: '3',
    name: 'UN_FOIS_DEUX_MOIS'
  },
  MoinsRegulierement: {
    id: '4',
    name: 'MOINS_REGULIEREMENT'
  }
};

export const BarbeRange = {
  PasDeBarbe: {
    id: '0',
    name: 'PAS_BARBE'
  },
  BarbeOccasionnelle: {
    id: '1',
    name: 'BARBE_OCCASIONNELLE'
  },
  BarbeRecurrente: {
    id: '2',
    name: 'BARBE_RECURRENTE'
  }
};

export const AllGraph = {
  id: 'AllGraph',
  name: 'GRAPH_PREFERENCES',
  show_default_language: true,
  show_attributes_blocks: true
};

export const DNAGraphs = {
  ColorationTable: {
    id: 'ColorationTable',
    name: 'CHARTS.COLORATION_TABLE',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  ColorationTableUS: {
    id: 'ColorationTableUS',
    name: 'CHARTS.COLORATION_TABLE_US',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataTable: {
    id: 'RawDataTable',
    name: 'CHARTS.RAW_DATA_TABLE',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataCountTable: {
    id: 'RawDataCountTable',
    name: 'CHARTS.RAW_DATA_COUNT_TABLE',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataMedianTable: {
    id: 'RawDataMedianTable',
    name: 'CHARTS.RAW_DATA_MEDIAN_TABLE',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataTableHorizontal: {
    id: 'RawDataTableHorizontal',
    name: 'CHARTS.RAW_DATA_TABLE_HORIZONTAL',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataCountTableHorizontal: {
    id: 'RawDataCountTableHorizontal',
    name: 'CHARTS.RAW_DATA_COUNT_TABLE_HORIZONTAL',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  RawDataMedianTableHorizontal: {
    id: 'RawDataMedianTableHorizontal',
    name: 'CHARTS.RAW_DATA_MEDIAN_TABLE_HORIZONTAL',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  LineChart: {
    id: 'LineChart',
    name: 'CHARTS.LINE',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true
  },
  LineChartNormalized: {
    id: 'LineChartNormalized',
    name: 'CHARTS.LINE_NORMALIZED',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_grey_zone: true
  },
  TableWithBar: {
    id: 'TableWithBar',
    name: 'CHARTS.TABLEWITHBAR',
    index: 0,
    translations: null,
    decisionRules: true,
    scale: true,
    plotBands: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_grey_zone: true
  },
  BulletScale3: {
    id: 'BulletScale3',
    name: 'CHARTS.BULLETSCALE3',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  BulletScale5: {
    id: 'BulletScale5',
    name: 'CHARTS.BULLETSCALE5',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  Histogram: {
    id: 'Histogram',
    name: 'CHARTS.HISTOGRAM',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true,
    show_column_zero: true
  },
  Matrix3: {
    id: 'Matrix3',
    name: 'CHARTS.MATRIX3',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  MatrixComment: {
    id: 'MatrixComment',
    name: 'CHARTS.MATRIXCOMMENT',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  MatrixCommentAdHoc: {
    id: 'MatrixCommentAdHoc',
    name: 'CHARTS.MATRIXCOMMENT_ADHOC',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  Tornado: {
    id: 'Tornado',
    name: 'CHARTS.TORNADO',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true
  },
  HistogramMultiAttributs: {
    id: 'HistogramMultiAttributs',
    name: 'CHARTS.HISTOGRAM_MULTI_ATTRIBUTES',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true
  },
  HistogramMultiSomme: {
    id: 'HistogramMultiSomme',
    name: 'CHARTS.HISTOGRAM_MULTI_SOMME',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true
  },
  HistogramMultiMedian: {
    id: 'HistogramMultiMedian',
    name: 'CHARTS.HISTOGRAM_MULTI_MEDIAN',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true
  },
  HistoConfortPercentage: {
    id: 'HistoConfortPercentage',
    name: 'CHARTS.HISTOGRAM_CONFORT_PERCENTAGE',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true
  },
  Bubble: {
    id: 'Bubble',
    name: 'CHARTS.BUBBLE',
    index: 0,
    translations: null,
    scale: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_data_label: true
  },
  Radar: {
    id: 'Radar',
    name: 'CHARTS.RADAR',
    index: 0,
    translations: null,
    scale: true,
    visibilityThreshold: true,
    show_default_language: true,
    show_attributes_blocks: true,
    show_visibility_threshold: true
  },
  BarTwoSided: {
    id: 'BarTwoSided',
    name: 'CHARTS.BAR_TWO_SIDED',
    index: 0,
    translations: null,
    scale: true,
    plotBands: true,
    show_default_language: true,
    show_attributes_blocks: true
  },
  Sankey: {
    id: 'Sankey',
    name: 'CHARTS.SANKEY',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  Peluches: {
    id: 'Peluches',
    name: 'CHARTS.PELUCHES_CHARTS',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  PeluchesRisks: {
    id: 'PeluchesRisks',
    name: 'CHARTS.PELUCHES_RISKS_CHARTS',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  LineChartMulti: {
    id: 'LineChartMulti',
    name: 'CHARTS.LINE_CHART_MULTI_STUDIES',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true,
    scale: true
  },
  ConfortHistogram: {
    id: 'ConfortHistogram',
    name: 'CHARTS.CONFORT_HISTOGRAM',
    index: 0,
    scale: true,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  ConfortCineticLine: {
    id: 'ConfortCineticLine',
    name: 'CHARTS.CONFORT_CINETIC',
    index: 0,
    scale: true,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true
  },
  PreferencesHistogram: {
    id: 'PreferencesHistogram',
    name: 'CHARTS.PREF_HISTO',
    index: 0,
    translations: null,
    show_default_language: true,
    show_attributes_blocks: true,
    scale: true
  }
};

export const DNAGraphsTypes = [
  DNAGraphs.BarTwoSided,
  DNAGraphs.ConfortCineticLine,
  DNAGraphs.ConfortHistogram,
  DNAGraphs.BulletScale3,
  DNAGraphs.BulletScale5,
  DNAGraphs.ColorationTable,
  DNAGraphs.ColorationTableUS,
  DNAGraphs.Histogram,
  DNAGraphs.HistogramMultiAttributs,
  DNAGraphs.LineChart,
  DNAGraphs.LineChartNormalized,
  DNAGraphs.Matrix3,
  DNAGraphs.MatrixComment,
  DNAGraphs.MatrixCommentAdHoc,
  DNAGraphs.Radar,
  DNAGraphs.HistogramMultiSomme,
  DNAGraphs.HistogramMultiMedian,
  DNAGraphs.HistoConfortPercentage,
  DNAGraphs.Bubble,
  DNAGraphs.TableWithBar,
  DNAGraphs.Tornado,
  DNAGraphs.Sankey,
  DNAGraphs.LineChartMulti,
  DNAGraphs.RawDataTable,
  DNAGraphs.RawDataCountTable,
  DNAGraphs.RawDataMedianTable,
  DNAGraphs.RawDataTableHorizontal,
  DNAGraphs.RawDataCountTableHorizontal,
  DNAGraphs.RawDataMedianTableHorizontal,
  DNAGraphs.Peluches,
  DNAGraphs.PeluchesRisks,
  DNAGraphs.PreferencesHistogram
];

export class Target {
  constructor() {
  }
}

export class TargetHair extends Target {
  length: AttributeDetails[];
  degreFrisure: AttributeDetails[];
  regulariteFrisure: AttributeDetails[];
  frisottis: AttributeDetails[];
  alopecieFemme: AttributeDetails[];
  alopecieHomme: AttributeDetails[];
  baseNaturelle: AttributeDetails[];
  cheveuxBlancs: AttributeDetails[];
  cheveuxBlancsPourcentDevant: AttributeDetails[];
  cheveuxBlancsPourcentNuque: AttributeDetails[];
  abondanceCheveux: AttributeDetails[];
  sensibilisation: AttributeDetails[];
  grosseur: AttributeDetails[];
  durete: AttributeDetails[];
  natureCuirChevelu: AttributeDetails[];
  natureCheveux: AttributeDetails[];
  cheveuxNaturels: AttributeDetails[];
  hauteurTonColoration: AttributeDetails[];
  categorieNuance: AttributeDetails[];
  cosmetiqueEquilibre: AttributeDetails[];
  tinctorialEquilibre: AttributeDetails[];
  casse: AttributeDetails[];
  hommePortBarbe: AttributeDetails[];

  constructor() {
    super();

    this.length = [
      new AttributeDetails(1, 'HAIR_VERY_SHORT_A'),
      new AttributeDetails(2, 'HAIR_VERY_SHORT_B'),
      new AttributeDetails(3, 'HAIR_SHORT'),
      new AttributeDetails(4, 'HAIR_MID_SHORT_LAYERED'),
      new AttributeDetails(5, 'HAIR_MID_SHORT_BOB'),
      new AttributeDetails(6, 'HAIR_MEDIUM'),
      new AttributeDetails(7, 'HAIR_LONG'),
      new AttributeDetails(8, 'HAIR_VERY_LONG'),
    ];

    this.regulariteFrisure = [new AttributeDetails(0, 'YES'), new AttributeDetails(1, 'NO')];
    this.cheveuxNaturels = [new AttributeDetails(0, 'YES', false), new AttributeDetails(1, 'NO')];
    this.cosmetiqueEquilibre = [new AttributeDetails(0, 'YES'), new AttributeDetails(1, 'NO')];
    this.tinctorialEquilibre = [new AttributeDetails(0, 'YES'), new AttributeDetails(1, 'NO')];
    this.hommePortBarbe = [new AttributeDetails(0, 'YES'), new AttributeDetails(1, 'NO')];

    this.degreFrisure = [];
    for (let i = 1; i < 9; i++) {
      this.degreFrisure.push(new AttributeDetails(i, i.toString()));
    }

    this.frisottis = [
      new AttributeDetails(PelliculesRange.PAS.id, PelliculesRange.PAS.name),
      new AttributeDetails(PelliculesRange.PEU.id, PelliculesRange.PEU.name),
      new AttributeDetails(PelliculesRange.ASSEZ.id, PelliculesRange.ASSEZ.name),
      new AttributeDetails(PelliculesRange.BEAUCOUP.id, PelliculesRange.BEAUCOUP.name)
    ];

    this.alopecieFemme = [];
    for (let i = 0; i < 4; i++) {
      this.alopecieFemme.push(new AttributeDetails(i, i.toString()));
    }

    this.alopecieHomme = [];
    for (let i = 0; i < 8; i++) {
      this.alopecieHomme.push(new AttributeDetails(i, i.toString()));
    }

    this.hauteurTonColoration = [];
    for (let i = 1; i < 11; i++) {
      this.hauteurTonColoration.push(new AttributeDetails(i, i.toString()));
    }

    this.baseNaturelle = [new AttributeDetails(0, '100%BLANC')];
    for (let i = 1; i < 20; i++) {
      this.baseNaturelle.push(new AttributeDetails(i, (i / 2.0 + 0.5).toString()));
    }

    this.cheveuxBlancs = [new AttributeDetails(0, 'YES')];

    this.cheveuxBlancsPourcentDevant = [
      new AttributeDetails(CheveuxBlancsRange.Pourcent0.id, CheveuxBlancsRange.Pourcent0.name),
      new AttributeDetails(CheveuxBlancsRange.Pourcent10_20.id, CheveuxBlancsRange.Pourcent10_20.name),
      new AttributeDetails(CheveuxBlancsRange.Pourcent30_40.id, CheveuxBlancsRange.Pourcent30_40.name),
      new AttributeDetails(CheveuxBlancsRange.Pourcent50_60.id, CheveuxBlancsRange.Pourcent50_60.name),
      new AttributeDetails(CheveuxBlancsRange.Pourcent70_90.id, CheveuxBlancsRange.Pourcent70_90.name),
      new AttributeDetails(CheveuxBlancsRange.Pourcent100.id, CheveuxBlancsRange.Pourcent100.name)
    ];
    this.cheveuxBlancsPourcentNuque = _.cloneDeep(this.cheveuxBlancsPourcentDevant);

    this.abondanceCheveux = [
      new AttributeDetails(AbondanceCheveuxRange.PeuAbondant.id, AbondanceCheveuxRange.PeuAbondant.name),
      new AttributeDetails(AbondanceCheveuxRange.MoyenAbondant.id, AbondanceCheveuxRange.MoyenAbondant.name),
      new AttributeDetails(AbondanceCheveuxRange.Abondant.id, AbondanceCheveuxRange.Abondant.name),
      new AttributeDetails(AbondanceCheveuxRange.TresAbondant.id, AbondanceCheveuxRange.TresAbondant.name)
    ];

    this.sensibilisation = [];
    for (let i = 1; i < 6; i++) {
      this.sensibilisation.push(new AttributeDetails(i, i.toString()));
    }

    this.grosseur = [
      new AttributeDetails(GrosseurCheveuxRange.TresFin.id, GrosseurCheveuxRange.TresFin.name),
      new AttributeDetails(GrosseurCheveuxRange.Fin.id, GrosseurCheveuxRange.Fin.name),
      new AttributeDetails(GrosseurCheveuxRange.Moyen.id, GrosseurCheveuxRange.Moyen.name),
      new AttributeDetails(GrosseurCheveuxRange.Gros.id, GrosseurCheveuxRange.Gros.name),
      new AttributeDetails(GrosseurCheveuxRange.TresGros.id, GrosseurCheveuxRange.TresGros.name)
    ];

    this.durete = [
      new AttributeDetails(DureteCheveuxRange.Mou.id, DureteCheveuxRange.Mou.name),
      new AttributeDetails(DureteCheveuxRange.Moyen.id, DureteCheveuxRange.Moyen.name),
      new AttributeDetails(DureteCheveuxRange.Dur.id, DureteCheveuxRange.Dur.name)
    ];

    this.natureCuirChevelu = [
      new AttributeDetails(NatureCuirCheveluRange.Gras.id, NatureCuirCheveluRange.Gras.name),
      new AttributeDetails(NatureCuirCheveluRange.Normal.id, NatureCuirCheveluRange.Normal.name),
      new AttributeDetails(NatureCuirCheveluRange.Sec.id, NatureCuirCheveluRange.Sec.name)
    ];

    this.natureCheveux = [
      new AttributeDetails(NatureCheveuxRange.Gras.id, NatureCheveuxRange.Gras.name),
      new AttributeDetails(NatureCheveuxRange.Mixte.id, NatureCheveuxRange.Mixte.name),
      new AttributeDetails(NatureCheveuxRange.Normal.id, NatureCheveuxRange.Normal.name),
      new AttributeDetails(NatureCheveuxRange.Sec.id, NatureCheveuxRange.Sec.name)
    ];

    this.categorieNuance = [
      new AttributeDetails(CategorieNuanceRange.Dore.id, CategorieNuanceRange.Dore.name),
      new AttributeDetails(CategorieNuanceRange.Cuivre.id, CategorieNuanceRange.Cuivre.name),
      new AttributeDetails(CategorieNuanceRange.Violine.id, CategorieNuanceRange.Violine.name),
      new AttributeDetails(CategorieNuanceRange.Rouge.id, CategorieNuanceRange.Rouge.name),
      new AttributeDetails(CategorieNuanceRange.Acajou5.id, CategorieNuanceRange.Acajou5.name),
      new AttributeDetails(CategorieNuanceRange.Acajou15.id, CategorieNuanceRange.Acajou15.name),
      new AttributeDetails(CategorieNuanceRange.Acajou25.id, CategorieNuanceRange.Acajou25.name),
      new AttributeDetails(CategorieNuanceRange.Acajou35.id, CategorieNuanceRange.Acajou35.name),
      new AttributeDetails(CategorieNuanceRange.Fondamentales.id, CategorieNuanceRange.Fondamentales.name),
      new AttributeDetails(CategorieNuanceRange.Eclaircissant.id, CategorieNuanceRange.Eclaircissant.name),
      new AttributeDetails(CategorieNuanceRange.SuperEclaircissant.id, CategorieNuanceRange.SuperEclaircissant.name),
      new AttributeDetails(CategorieNuanceRange.RefletCendre.id, CategorieNuanceRange.RefletCendre.name),
      new AttributeDetails(CategorieNuanceRange.RefletMate.id, CategorieNuanceRange.RefletMate.name)
    ];

    this.casse = [
      new AttributeDetails(PelliculesRange.PAS.id, PelliculesRange.PAS.name),
      new AttributeDetails(PelliculesRange.PEU.id, PelliculesRange.PEU.name),
      new AttributeDetails(PelliculesRange.ASSEZ.id, PelliculesRange.ASSEZ.name),
      new AttributeDetails(PelliculesRange.BEAUCOUP.id, PelliculesRange.BEAUCOUP.name)
    ];
  }
}

export class TargetSkin extends Target {
  cheeksBlemish: AttributeDetails[];
  cheeksRedness: AttributeDetails[];
  dilatedPores: AttributeDetails[];
  eyelashesCurve: AttributeDetails[];
  eyelashesDensity: AttributeDetails[];
  eyelashesLength: AttributeDetails[];
  facialBrightness: AttributeDetails[];
  facialSkin: AttributeDetails[];
  lipsSize: AttributeDetails[];
  lipsThickness: AttributeDetails[];
  phototype: AttributeDetails[];
  foreheadTone: AttributeDetails[];
  skinType: AttributeDetails[];
  upperEyelidsRelaxation: AttributeDetails[];
  upperEyelidsSurfaceArea: AttributeDetails[];
  wrinklesUnderEye: AttributeDetails[];
  darkCircles: AttributeDetails[];

  constructor() {
    super();

    this.cheeksBlemish = [
      new AttributeDetails(0, 'NOT'),
      new AttributeDetails(1, 'SLIGHT'),
      new AttributeDetails(2, 'MEDIUM'),
      new AttributeDetails(3, 'A_LOT')
    ];

    this.cheeksRedness = [
      new AttributeDetails(0, 'NOT'),
      new AttributeDetails(1, 'SLIGHT'),
      new AttributeDetails(2, 'MEDIUM'),
      new AttributeDetails(3, 'A_LOT')
    ];

    this.dilatedPores = [
      new AttributeDetails(0, 'YES'),
      new AttributeDetails(1, 'NO')
    ];

    this.eyelashesCurve = [
      new AttributeDetails(0, 'STRAIGHT'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'CURVED')
    ];
    this.eyelashesDensity = [
      new AttributeDetails(0, 'WEAK'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'ABUNDANT')
    ];
    this.eyelashesLength = [
      new AttributeDetails(0, 'SHORT'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'LONG')
    ];
    this.facialBrightness = [
      new AttributeDetails(0, 'FAIR'),
      new AttributeDetails(1, 'INTERMEDIATE'),
      new AttributeDetails(2, 'DARK')
    ];
    this.facialSkin = [
      new AttributeDetails(0, 'SKIN_DRY'),
      new AttributeDetails(1, 'SKIN_COMBINATION_TO_DRY'),
      new AttributeDetails(2, 'SKIN_COMBINATION'),
      new AttributeDetails(3, 'SKIN_COMBINATION_TO_OILY'),
      new AttributeDetails(4, 'SKIN_OILY')
    ];
    this.lipsSize = [
      new AttributeDetails(0, 'SMALL'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'LARGE')
    ];
    this.lipsThickness = [
      new AttributeDetails(0, 'THIN'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'THICK')
    ];
    this.phototype = [];
    this.foreheadTone = [
      new AttributeDetails(0, 'L5'),
      new AttributeDetails(1, 'J6'),
      new AttributeDetails(2, 'K6'),
      new AttributeDetails(3, 'L6'),
      new AttributeDetails(4, 'M6'),
      new AttributeDetails(5, 'G7'),
      new AttributeDetails(6, 'H7'),
      new AttributeDetails(7, 'I7'),
      new AttributeDetails(8, 'J7'),
      new AttributeDetails(9, 'K7'),
      new AttributeDetails(10, 'L7'),
      new AttributeDetails(11, 'M7'),
      new AttributeDetails(12, 'G8'),
      new AttributeDetails(13, 'H8'),
      new AttributeDetails(14, 'I8'),
      new AttributeDetails(15, 'J8'),
      new AttributeDetails(16, 'K8'),
      new AttributeDetails(17, 'L8'),
      new AttributeDetails(18, 'M8'),
      new AttributeDetails(19, 'G9'),
      new AttributeDetails(20, 'H9'),
      new AttributeDetails(21, 'I9'),
      new AttributeDetails(22, 'J9'),
      new AttributeDetails(23, 'K9'),
      new AttributeDetails(24, 'L9'),
      new AttributeDetails(25, 'G10'),
      new AttributeDetails(26, 'H10'),
      new AttributeDetails(27, 'I10'),
      new AttributeDetails(28, 'J10'),
      new AttributeDetails(29, 'K10'),
      new AttributeDetails(30, 'L10'),
      new AttributeDetails(31, 'G11'),
      new AttributeDetails(32, 'H11'),
      new AttributeDetails(33, 'I11'),
      new AttributeDetails(34, 'J11'),
      new AttributeDetails(35, 'K11'),
      new AttributeDetails(36, 'G12'),
      new AttributeDetails(37, 'H12'),
      new AttributeDetails(38, 'I12'),
      new AttributeDetails(39, 'J12'),
      new AttributeDetails(40, 'K12'),
      new AttributeDetails(41, 'I13'),
      new AttributeDetails(42, 'J13'),
      new AttributeDetails(43, 'K13'),
      new AttributeDetails(44, 'H14'),
      new AttributeDetails(45, 'I14'),
      new AttributeDetails(46, 'J14'),
      new AttributeDetails(47, 'K14'),
      new AttributeDetails(48, 'H15'),
      new AttributeDetails(49, 'I15'),
      new AttributeDetails(50, 'J15'),
      new AttributeDetails(51, 'K15'),
      new AttributeDetails(52, 'H16'),
      new AttributeDetails(53, 'I16'),
      new AttributeDetails(54, 'J16'),
      new AttributeDetails(55, 'K16'),
      new AttributeDetails(56, 'H17'),
      new AttributeDetails(57, 'I17'),
      new AttributeDetails(58, 'J17'),
      new AttributeDetails(59, 'H18'),
      new AttributeDetails(60, 'I18'),
      new AttributeDetails(61, 'J18'),
      new AttributeDetails(62, 'G19')
    ];
    this.skinType = [
      new AttributeDetails(0, 'CAUCASIAN'),
      new AttributeDetails(1, 'AFRICAN'),
      new AttributeDetails(2, 'ASIAN'),
      new AttributeDetails(3, 'HISPANIC'),
      new AttributeDetails(4, 'INDIAN'),
      new AttributeDetails(5, 'NORTH_AFRICAN')
    ];
    this.upperEyelidsRelaxation = [
      new AttributeDetails(0, 'NOT'),
      new AttributeDetails(1, 'SLIGHT'),
      new AttributeDetails(2, 'MEDIUM'),
      new AttributeDetails(3, 'A_LOT')
    ];
    this.upperEyelidsSurfaceArea = [
      new AttributeDetails(0, 'SMALL'),
      new AttributeDetails(1, 'MEDIUM'),
      new AttributeDetails(2, 'LARGE')
    ];
    this.wrinklesUnderEye = [
      new AttributeDetails(0, 'YES'),
      new AttributeDetails(1, 'NO')
    ];
    this.darkCircles = [
      new AttributeDetails(0, 'NOT'),
      new AttributeDetails(1, 'SLIGHT'),
      new AttributeDetails(2, 'MEDIUM'),
      new AttributeDetails(3, 'A_LOT')
    ];

  }
}

export enum TargetKeys {
  length = 'LENGTH',
  degreFrisure = 'DEGRE_FRISURE',
  regulariteFrisure = 'REGULARITE_FRISURE',
  frisottis = 'FRISOTTIS',
  alopecieFemme = 'ALOPECIE_FEMME',
  alopecieHomme = 'ALOPECIE_HOMME',
  baseNaturelle = 'BASE_NATURELLE',
  cheveuxBlancs = 'PAS_CHEVEUX_BLANCS',
  cheveuxBlancsPourcentDevant = 'POURCENT_CHEVEUX_BLANCS_DEVANT',
  cheveuxBlancsPourcentNuque = 'POURCENT_CHEVEUX_BLANCS_NUQUE',
  abondanceCheveux = 'ABONDANCE_CHEVEUX',
  sensibilisation = 'SENSIBILISATION',
  grosseur = 'GROSSEUR_CHEVEUX',
  durete = 'DURETE',
  natureCuirChevelu = 'NATURE_CUIR_CHEVELU',
  natureCheveux = 'NATURE_CHEVEUX',
  cheveuxNaturels = 'CHEVEUX_NATURELS',
  hauteurTonColoration = 'HAUTEUR_TON_COLORATION',
  categorieNuance = 'ROUTINE_SHADES',
  cosmetiqueEquilibre = 'COSMETIC_EQUILIBRE',
  tinctorialEquilibre = 'TINCTORIAL_EQUILIBRE',
  casse = 'CASSE',
  hommePortBarbe = 'BARBE',
  cheeksBlemish = 'CHEEKS_BLEMISH',
  cheeksRedness = 'CHEEKS_REDNESS',
  dilatedPores = 'DILATED_PORES',
  eyelashesCurve = 'EYELASHES_CURVE',
  eyelashesDensity = 'EYELASHES_DENSITY',
  eyelashesLength = 'EYELASHES_LENGTH',
  facialBrightness = 'FACIAL_BRIGHTNESS',
  facialSkin = 'FACIAL_SKIN',
  lipsSize = 'LIPS_SIZE',
  lipsThickness = 'LIPS_THICKNESS',
  phototype = 'PHOTOTYPE',
  foreheadTone = 'FOREHEAD_TONE',
  skinType = 'SKIN_TYPE',
  upperEyelidsRelaxation = 'UPPEREYELIDS_RELAXATION',
  upperEyelidsSurfaceArea = 'UPPEREYELIDS_SURFACE_AREA',
  wrinklesUnderEye = 'WRINKLES_UNDER_EYE',
  darkCircles = 'DARK_CIRCLES_UNDER_EYES'
}

export class TypeCampaign {
  id: string;
  name: string;

  constructor(type) {
    this.id = type === 'CAMPAIGN' ? TYPE_CAMPAIGN.CONSUMERS.id : TYPE_CAMPAIGN.SENSORY_EXPERT.id;
    this.name = type === 'CAMPAIGN' ? TYPE_CAMPAIGN.CONSUMERS.name : TYPE_CAMPAIGN.SENSORY_EXPERT.name;
  }
}

export class UserCampaign {
  accountables: UserInCampaign[];
  isCollaborative: boolean;
  areUserSettingsAlreadySet: boolean;
  isVolunteer: boolean;
  isHorsArcsVolunteer?: boolean;
  volunteersNumber: number;
  volunteers: Volunteers[];

  constructor(user: UserPreferences = new UserPreferences()) {
    this.accountables = [];
    this.isCollaborative = user.collaborative.defaultValue;
    this.areUserSettingsAlreadySet = false;
    this.isVolunteer = user.volunteers.defaultValue;
    this.isHorsArcsVolunteer = false;
    this.volunteers = [];
    this.volunteersNumber = 0;
  }
}

export class UserInCampaign {
  name: string;
  formula?: EvaluationFormula[];
  key: string;
  pdata?: string;
  pzone: number;
  isActive: boolean;
  peopleUserKey?: string;

  constructor() {
    this.name = '';
    this.formula = [];
    this.isActive = true;
  }
}

export class Buttons {
  id: string;
  name: string;
  isActive: boolean;

  constructor(name: string = '', id: string = '', isActive: boolean = true) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
  }
}

/*************** COMPONENT ***************/

export class DNAComponent implements DNAObject {
  name: Translatable;
  args: Args;
  idInBlock?: string;
  index?: number;
  isEditable?: boolean;
  tags: Tag[];
  type: string;
  mandatory?: boolean;
  id?: string;

  constructor(name: Translatable = new Translatable(), type: string = '') {
    this.name = name;
    this.args = new Args();
    this.tags = [];
    this.type = type;
  }
}

/*************** DATAVIZ ***************/

export class DataVizualisation {
  link: string;
  object: PowerbiData[];
  type: OptionDataviz;
  selectedTiles?: Tile[];

  constructor() {
    this.type = OptionDataviz.optionPowerbi;
    this.object = [];
    this.link = '';
  }
}

export class PowerbiData {
  id: string;
  name: string;
  embedUrl: string;
  type: string;
  idGroup?: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.embedUrl = '';
    this.type = '';
  }
}

export class Tile {
  id: string;
  embedUrl: string;
  reportId: string;

  constructor() {
  }
}

export enum OptionDataviz {
  optionBO = 'BO',
  optionPowerbi = 'powerBI'
}

export type OptionsDataviz = OptionDataviz.optionBO | OptionDataviz.optionPowerbi;

export enum TypeDataviz {
  typeReport = 'report',
  typeTile = 'tile',
  typeDashboard = 'dashboard'
}

export type TypesDataviz = TypeDataviz.typeReport | TypeDataviz.typeTile | TypeDataviz.typeDashboard;

/*************** DNA ***************/

export interface DNACondition {
  otherCases: LogicJump;
  userJumps: LogicJump[];
}

export interface DNAKey {
  id: string;
}

export interface DNAObject {
  id?: string;
  name: Translatable;
  created_on?: number;
  updated_on?: number;
  description?: Translatable;
  state?: States;
  type?: string;
  workspaces?: string[];
  hub?: Hub;
}

export interface DNAValue {
  value: string | number;
}

export class ArcsUser {
  PanelistID: number;
  arcsSystem: HubById;
  arcsStudy: number;
}

export enum DNAClass {
  DNA_API_CLASS = 'DNA API',
  DNA_API_HAIR_CLASS = 'DNA API Hair',
  DNA_API_SKIN_CLASS = 'DNA API Skin',
  DNA_API_MAKEUP_CLASS = 'DNA API Make-up',
}

/*************** EVALUATION ***************/

export class Evaluation {
  id: string;
  bench?: string;
  lab?: string[];
  answers: Answer[];
  applicationVersion?: string;
  campaign?: Campaign;
  datalakeStatus: boolean;
  created_on: number;
  updated_on: number;
  dateStart?: number;
  formula: SimComp;
  studyId: string;
  side?: number;
  questionnaireId: string;
  progress?: number;
  routines?: Routine2[];
  state: string;
  users: {
    key: string,
    name?: string
  }[];
  volunteer: {
    key: string;
    name: string;
    isActive?: boolean
  };
  workflowName?: Translatable;
  visits: {
    id: string;
    startDate: number;
  }[];
}

export class EvaluationWrapper extends Evaluation {
  campaignName?: string;
  formulaName?: string;
  peopleKeys?: {
    key: string,
    name?: string
  }[];
}

export class Answer {
  comment?: string;
  idBlock: string;
  idComponent: string;
  idInBlock: string;
  idInQuestionnaire: string;
  idWorkflow: string;
  idEvaluation: string;
  idCampaign: string;
  formula: {
    id: string,
    name: string,
    bench?: GroupedFormula,
    lab?: GroupedFormula,
    isSimultaneous: boolean
  };
  volunteer?: {
    name: string;
  };
  user: {
    key: string;
    name?: string;
  };
  index?: number;
  label: Translatable;
  parameters?: {
    isContextual: boolean;
    isContextualPerFormula: boolean;
    isContextualPerEval: boolean;
  };
  peopleKey: string;
  records: Record[];
}

export class EvaluationFormula {
  id: string;
  name: string;
  bench?: string;
  lab?: string;
  formula: string[];
  isActive: boolean;
  isSimultaneous: boolean;
  sequential: number;
}

export class Record {
  data: RecordData[];
  language?: string;
  timestamp: number;
  type: string;
}

export class RecordData {
  benchOrLab?: string;
  fullPath?: string;
  isInReport?: boolean;
  key?: string;
  lastModifiedDate?: Date;
  name?: string;
  side?: string;
  size?: number;
  type?: string;
  value?: string;
  valueSensor?: any;
}

export class Routine2 {
  name: string;
  visits: Visit[];
  label: string;

  constructor(index, metierName?: TypeMetier) {
    this.name = 'R' + index;
    this.visits = [metierName ? new Visit(0, metierName) : new Visit()];
  }
}

export class Visit {
  id?: string;
  name: string;
  index: number;
  orders: Order[];

  constructor(index = 0, metierName?: TypeMetier) {
    this.name = 'D' + index;
    this.id = '';
    this.index = index;
    this.orders = [metierName ? new Order('0', metierName) : new Order()];
  }
}

export class Order {
  evaluation: string;
  name: string;
  shade: string;
  steps: RoutineStep[];

  constructor(index: string = '0', metierName?: TypeMetier) {
    this.evaluation = '';
    this.name = parseInt(index) < 9 ? '0' + (+index + 1) + '' : (+index + 1) + '';
    this.shade = '';
    this.steps = [metierName ? new RoutineStep('1', metierName) : new RoutineStep()];
  }
}

export class RoutineStep {
  class?: Category;
  formula: FormulaBridge;
  name: string;

  constructor(index: string = '1', metierName?: TypeMetier) {
    this.name = index;

    switch (metierName) {
      case Metiers.Hair:
        this.formula = new FormulaBridgeHair();
        break;
      case Metiers.Skin:
        this.formula = new FormulaBridgeSkin();
        break;
      default:
        this.formula = new FormulaBridge();
    }
  }
}

export class FormulaBridge {
  applicators: TypeApplicationMode[];
  comments?: string;
  formulaName: string;
  lot: string;
  quantity?: number;
  pauseTime?: NgbTimeStruct;
  productName: string;
  socle: string;
  timeDrying?: number;

  constructor() {
    this.applicators = [];
    this.formulaName = 'NO_FORMULA';
    this.lot = '';
    this.productName = '';
    this.socle = '';
    this.pauseTime = {
      hour: 0,
      minute: 0,
      second: 0
    };
  }
}

export class FormulaBridgeHair extends FormulaBridge {
  applyTo?: RoutineApplication;
  dryingTypes: TypeDryingType[];

  constructor() {
    super();
    this.dryingTypes = [];
  }
}

export class FormulaBridgeSkin extends FormulaBridge {
  // timeDrying?: number;

  constructor() {
    super();
  }
}

export class RoutineTabFromCampaign {
  visit: string;
  orderName: string;
  shades: string;
  evaluation: string;
  stepName: string;
  productName: string;
  categorie: Category;
  formulaName: string;
  lot: string;
  quantity: number;
  quantitiesByVolunteer: QuantitiesByVolunteer[];
  applicationMode: TypeApplicationMode[];
  pauseTime: PauseTimeByVolunteer[];
  applyTo?: RoutineApplication;
  dryingTypes?: TypeDryingType[];
  timeDrying?: number;
  isStarted: boolean;
}

export class PauseTimeByVolunteer {
  volunteerName: string;
  pauseTime: NgbTimeStruct;
  isStarted: boolean;
}

export class RoutineTabForReport {
  workflow: {
    id: string,
    name: Translatable
  };
  routinesTab: RoutinesTab[];
}

export class RoutinesTab {
  name: string;
  label?: string;
  routineDatas: RoutineTabFromCampaign[];
}

export class RoutineTabFromEvaluations {
  volunteerName: string;
  routineName: string;
  visit: string;
  orderName: string;
  formulaName: string;
  stepName: string;
  quantitiesByVolunteer: QuantitiesByVolunteer[];
}

export class QuantitiesByVolunteer {
  volunteerName: string;
  quantity: number;
  isStarted?: boolean;
}

/*************** APP TALK ***************/

export class Arcs {
  panelists: ArcsParticipation[];
  arcsSystem: HubById;
  arcsStudy: string;
}

export class ArcsParticipationList {
  list: ArcsParticipation[];
}

export class ArcsParticipation {
  ParticipationID: string;
  PanelistID: string;
  FirstName: string;
  LastName: string;
  StatusCode: number;
  StatusDescription: string;
  StudyURL: string;
}

export class ArcsPanelist {
  panelistNumber: string;
  firstName: string;
  lastName: string;
  classifications: Classifications[];
}

export class Panelist {
  id: string;
  class: string;
  subclass: string;
  panelistCode: string;
  panelistNumber: string;
  hub: HubById;
  firstName: string;
  lastName: string;
  created_on: number;
  timeline?: PanelistTimeline[];

  constructor(id, FirstName, LastName, arcsSystem) {
    this.id = id;
    this.panelistCode = arcsSystem.toString().concat('_').concat(id.toString());
    this.panelistNumber = id.toString();
    this.firstName = FirstName;
    this.lastName = LastName;
    this.hub = arcsSystem;
    this.created_on = new Date().getTime();
  }
}

export class PanelistTimeline {
  id: string;
  class: string;
  subclass: string;
  panelistCode: string;
  panelistNumber: string;
  hub: HubById;
  firstName: string;
  lastName: string;
  created_on: number;
  text?: string;
  requestNumber?: string;
  laboratory?: string;
  studyId?: string;
  orchestra?: string;
  studyName?: string;
  visitId?: string;
  visitName?: string;
  timestamp?: number;
  title?: string;
  decription?: string;
  itemPosition?: number;

  constructor(id, subclass, created_on, requestNumber, text) {
    this.subclass = subclass;
    this.created_on = created_on;
    this.requestNumber = requestNumber;
    this.text = text;
    this.id = id;
  }
}

export class FicheCaracterisation {
  AttributeData: AttributeData[];
  idPanelist: string;
  metier?: Metier;
  hub?: string;
  id?: string;
  created_on?: number;
  requestNumber?: string;
  subclass?: string;

  constructor(AttributeData, idPanelist) {
    this.idPanelist = idPanelist;
    this.AttributeData = AttributeData;
  }
}

export class FicheRemanence {
  id?: string;
  idVolunteer: string;
  created_on?: string;
  cuirCheveluSain: boolean;
  pellicules?: Type;
  requestNumber?: string;
  isCosmetiqueCheveuxHumide: boolean;
  isCosmetiqueCheveuxSec: boolean;
  isEvaluationCouleur: boolean;
  isDegreFrissure: boolean;
  degreFrisureG: Type;
  degreFrisureD: Type;
  comments?: string;
  cosmetiqueCheveuxHumide: {
    demelage?: Type;
    lisseToucher?: Type;
    souplesse?: Type;
    enrobage?: Type;
    typeEnrobageG?: Type;
    typeEnrobageD?: Type;
    typeEnrobageOtherG?: string;
    typeEnrobageOtherD?: string;
    densite?: Type;
    commentaires?: string;
    integriteG?: Type;
    integriteD?: Type;
    typeSechage?: Type;
    typeSechageOther?: string;
  };
  cosmetiqueCheveuxSec: {
    demelage?: Type;
    lisseToucher?: Type;
    souplesse?: Type;
    enrobage?: Type;
    typeEnrobageG?: Type;
    typeEnrobageD?: Type;
    typeEnrobageOtherG?: string;
    typeEnrobageOtherD?: string;
    densite?: Type;
    commentaires?: string;
    facilitePassageDoigt?: Type;
  };
  evaluationCouleur: {
    htRacinesG?: Type;
    htRacinesD?: Type;
    refletG?: string;
    refletD?: string;
    couvertureBlancG?: Type;
    couvertureBlancD?: Type;
    unissonG?: Type;
    unissonD?: Type;
  };
  subclass?: string;

  constructor() {
    this.isCosmetiqueCheveuxHumide = false;
    this.isCosmetiqueCheveuxSec = false;
    this.isEvaluationCouleur = false;
    this.isDegreFrissure = false;
    this.cuirCheveluSain = false;
    this.cosmetiqueCheveuxHumide = {};
    this.cosmetiqueCheveuxSec = {};
    this.evaluationCouleur = {};
  }
}

export class FicheRemanenceOptions {
  pellicules: Type[];
  echelleMiroir: Type[];
  typeEnrobage: Type[];
  integrite: Type[];
  typeSechage: Type[];
  htRacines: Type[];
  couvertureBlanc: Type[];
  degreFrisure: Type[];

  constructor() {

    this.htRacines = [];
    let i = 1;
    let index = 0;
    while (i <= 10) {
      this.htRacines.push({
        id: index.toString(),
        name: i.toString()
      });
      index++;
      i += 0.25;
    }


    this.couvertureBlanc = [];
    i = 0;
    index = 0;
    while (i <= 8) {
      this.couvertureBlanc.push({
        id: index.toString(),
        name: i.toString()
      });
      index++;
      i += 0.5;
    }

    this.degreFrisure = [];
    i = 1;
    index = 0;
    while (i <= 8) {
      this.degreFrisure.push({
        id: index.toString(),
        name: i.toString()
      });
      index++;
      i += 0.5;
    }

    this.typeSechage = [{
      id: '0',
      name: 'NATURAL_DRYING'
    }, {
      id: '1',
      name: 'BLOW_OUT_WITH_BRUSH_COMB'
    }, {
      id: '2',
      name: 'BLOW_OUT_WITH_FINGERS'
    }, {
      id: '3',
      name: 'CLIMAZON'
    }, {
      id: '4',
      name: 'DIFFUSER'
    }, {
      id: '8',
      name: 'HOOD_DRYER'
    }, {
      id: '9',
      name: 'ELECTRIC_FLAT_IRON'
    }, {
      id: '10',
      name: 'STEAMPOD'
    }, {
      id: '6',
      name: 'ELECTRIC_CURLING_IRON'
    }, {
      id: '11',
      name: 'OTHER_THERMAL_STYLING_TOOL'
    }, {
      id: '12',
      name: 'CURL_CREATION'
    }];

    this.integrite = [{
      id: '0',
      name: 'ACCEPTABLE'
    }, {
      id: '1',
      name: 'PASSABLE'
    }, {
      id: '2',
      name: 'INACCEPTABLE'
    }];

    this.typeEnrobage = [{
      id: '0',
      name: 'GLISSANT'
    }, {
      id: '1',
      name: 'CIRE'
    }, {
      id: '2',
      name: 'GRAS'
    }, {
      id: '3',
      name: 'GAINANT'
    }, {
      id: '4',
      name: 'OTHER'
    }];

    this.echelleMiroir = [{
      id: '4',
      name: '+++'
    },
    {
      id: '5',
      name: '++'
    },
    {
      id: '6',
      name: '+'
    },
    {
      id: '0',
      name: '='
    },
    {
      id: '1',
      name: '+'
    },
    {
      id: '2',
      name: '++'
    },
    {
      id: '3',
      name: '+++'
    }
    ];
    this.pellicules = [PelliculesRange.PAS, PelliculesRange.PEU, PelliculesRange.ASSEZ, PelliculesRange.BEAUCOUP];
  }
}

export class FicheEntretien {
  typeIntervention: Type;
  // idVolunteer: string;
  produits: FicheEntretienProduit[];
  initialesTech?: string;
  orchestraNumber?: string;
  haircut: boolean;
  dateApplication?: number;
  id?: string;
  // created_on?: string;
  comments?: string;
  requestNumber?: string;
  class?: string;
  subclass?: string;
  created_on?: number;
  panelistCode?: string;
  panelistNumber?: string;
  firstName?: string;
  lastName?: string;

  constructor() {
    this.produits = [];
  }
}

export class FicheEntretienProduit {
  isActive: boolean;
  id: string;
  name: string;
  batchNumber: string;
  formulaNumber: string;
  productName: string;
  shade: string;
  strength?: Type;

  constructor(produit: Type) {
    this.isActive = true;
    this.id = produit.id;
    this.name = produit.name;
    this.batchNumber = '';
    this.formulaNumber = '';
    this.productName = '';
    this.shade = '';
  }
}

export const TypesIntervention = {
  COLORATION_DIRECT: {
    id: '0',
    name: 'COLORATION_DIRECT'
  },
  COLORATION_TON_TON: {
    id: '1',
    name: 'COLORATION_TON_TON'
  },
  COLORATION_OXYDATION: {
    id: '2',
    name: 'COLORATION_OXYDATION'
  },
  ECLAIRCISSEMENT: {
    id: '3',
    name: 'ECLAIRCISSEMENT'
  },
  DECOLORATION: {
    id: '4',
    name: 'DECOLORATION'
  },
  MECHES: {
    id: '5',
    name: 'MECHES'
  },
  PERMANENTE: {
    id: '6',
    name: 'PERMANENTE'
  },
  LISSAGE: {
    id: '7',
    name: 'LISSAGE'
  },
  DEFRISAGE: {
    id: '8',
    name: 'DEFRISAGE'
  },
  COLORATION_NATURELLE: {
    id: '9',
    name: 'COLORATION_NATURELLE'
  }
};

export const Produits = {
  SHAMPOOING: {
    id: '0',
    name: 'SHAMPOOING'
  },
  PRE_TRAITEMENT: {
    id: '1',
    name: 'PRE_TRAITEMENT'
  },
  REDUCTEUR: {
    id: '2',
    name: 'REDUCTEUR'
  },
  FIXATEUR: {
    id: '3',
    name: 'FIXATEUR'
  },
  BASE: {
    id: '4',
    name: 'BASE'
  },
  ACTIVATEUR: {
    id: '5',
    name: 'ACTIVATEUR'
  },
  SHAMPOOING_NEUTRALISANT: {
    id: '6',
    name: 'SHAMPOOING_NEUTRALISANT'
  },
  SUPPORT: {
    id: '7',
    name: 'SUPPORT'
  },
  OXYDANT: {
    id: '8',
    name: 'OXYDANT'
  },
  ADDITIF: {
    id: '9',
    name: 'ADDITIF'
  },
  SHAMPOOING_TERMINAL: {
    id: '10',
    name: 'SHAMPOOING_TERMINAL'
  },
  SOIN_TERMINAL: {
    id: '11',
    name: 'SOIN_TERMINAL'
  },
  AUTRE: {
    id: '12',
    name: 'OTHER'
  }
};

export class FicheEntretienOptions {
  typesIntervention: Type[];
  produits: Type[];
  strengths: Type[];

  static isProductName(product: Type): boolean {
    switch (product.id) {
      case Produits.PRE_TRAITEMENT.id:
      case Produits.FIXATEUR.id:
      case Produits.ACTIVATEUR.id:
      case Produits.OXYDANT.id:
      case Produits.ADDITIF.id:
        return false;
    }
    return true;
  }

  static isShade(product: Type): boolean {
    switch (product.id) {
      case Produits.SUPPORT.id:
        return true;
    }
    return false;
  }

  static isStrength(product: Type): boolean {
    switch (product.id) {
      case Produits.REDUCTEUR.id:
      case Produits.BASE.id:

        return true;
    }
    return false;
  }

  static isColoration(typeIntervention: Type): boolean {
    return (typeIntervention.id === TypesIntervention.COLORATION_DIRECT.id) ||
      (typeIntervention.id === TypesIntervention.COLORATION_OXYDATION.id) ||
      (typeIntervention.id === TypesIntervention.COLORATION_TON_TON.id) ||
      (typeIntervention.id === TypesIntervention.DECOLORATION.id) ||
      (typeIntervention.id === TypesIntervention.ECLAIRCISSEMENT.id) ||
      (typeIntervention.id === TypesIntervention.MECHES.id);
  }

  static isDefrisage(typeIntervention: Type): boolean {
    return typeIntervention.id === TypesIntervention.DEFRISAGE.id ||
      typeIntervention.id === TypesIntervention.LISSAGE.id ||
      typeIntervention.id === TypesIntervention.PERMANENTE.id;
  }

  static getProducts(typesIntervention: Type): Type[] {
    switch (typesIntervention.id) {
      case TypesIntervention.COLORATION_DIRECT.id:
      case TypesIntervention.COLORATION_NATURELLE.id:
        return [Produits.SHAMPOOING, Produits.SUPPORT, Produits.ADDITIF, Produits.SHAMPOOING_TERMINAL, Produits.SOIN_TERMINAL, Produits.AUTRE];
      case TypesIntervention.COLORATION_OXYDATION.id:
      case TypesIntervention.COLORATION_TON_TON.id:
      case TypesIntervention.DECOLORATION.id:
      case TypesIntervention.ECLAIRCISSEMENT.id:
      case TypesIntervention.MECHES.id:
        return [Produits.OXYDANT, Produits.SUPPORT, Produits.ADDITIF, Produits.SHAMPOOING_TERMINAL, Produits.SOIN_TERMINAL, Produits.AUTRE];
      case TypesIntervention.PERMANENTE.id:
      case TypesIntervention.LISSAGE.id:
        return [Produits.SHAMPOOING, Produits.PRE_TRAITEMENT, Produits.REDUCTEUR, Produits.FIXATEUR, Produits.SOIN_TERMINAL, Produits.AUTRE];
      case TypesIntervention.DEFRISAGE.id:
        return [Produits.BASE, Produits.ACTIVATEUR, Produits.SHAMPOOING_NEUTRALISANT, Produits.SOIN_TERMINAL, Produits.AUTRE];
    }
  }

  constructor() {

    this.strengths = [{
      id: '0',
      name: 'FAIBLE'
    }, {
      id: '1',
      name: 'MOYEN'
    }, {
      id: '2',
      name: 'FORT'
    }];

    this.produits = [
      Produits.ACTIVATEUR,
      Produits.ADDITIF,
      Produits.AUTRE,
      Produits.BASE,
      Produits.FIXATEUR,
      Produits.OXYDANT,
      Produits.PRE_TRAITEMENT,
      Produits.REDUCTEUR,
      Produits.SHAMPOOING,
      Produits.SHAMPOOING_NEUTRALISANT,
      Produits.SHAMPOOING_TERMINAL,
      Produits.SOIN_TERMINAL,
      Produits.SUPPORT
    ];

    this.typesIntervention = [
      TypesIntervention.COLORATION_DIRECT,
      TypesIntervention.COLORATION_OXYDATION,
      TypesIntervention.COLORATION_TON_TON,
      TypesIntervention.DECOLORATION,
      TypesIntervention.DEFRISAGE,
      TypesIntervention.ECLAIRCISSEMENT,
      TypesIntervention.LISSAGE,
      TypesIntervention.MECHES,
      TypesIntervention.PERMANENTE,
      TypesIntervention.COLORATION_NATURELLE
    ];
  }
}

export class Option {
  id: string;
  name: string;

  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export enum TimelineTypes {
  STUDY = 'STUDY',
  VISIT = 'VISIT',
  FICHE_CARACTERISATION = 'FICHE_CARACTERISATION',
  FICHE_REMANENCE = 'FICHE_REMANENCE',
  FICHE_ENTRETIEN = 'FICHE_ENTRETIEN',
  ALL_FICHE = 'ALL_TIMELINE_TYPES'
}

export enum TimelineTypesDB {
  STUDY = 'study',
  VISIT = 'visit',
  FICHE_CARACTERISATION = 'characterisation',
  FICHE_REMANENCE = 'remanence',
  FICHE_ENTRETIEN = 'care'
}


export type TimelineType =
  TimelineTypes.ALL_FICHE
  | TimelineTypes.FICHE_CARACTERISATION
  | TimelineTypes.FICHE_ENTRETIEN
  | TimelineTypes.FICHE_REMANENCE
  | TimelineTypes.STUDY
  | TimelineTypes.VISIT;

export class Timeline {
  id: string;
  type: TimelineType;
  date: number;
  comment: string;
  orchestra?: string;
  bridge?: string;
  laboratory?: string;
  campaignState?: CampaignStates;
  visitName?: string;
  idVisit?: string;
  idCampaign: string;

  constructor(id, type, date, comment) {
    this.id = id;
    this.type = type;
    this.date = date;
    this.comment = comment;
    this.idCampaign = id;
  }
}

export enum IndexOperator {
  INDEX = 'index',
  ONE_BASELINE = 'ONE_BASELINE',
  DOUBLE_BASELINE = 'DOUBLE_BASELINE'
}

export enum OperatorType {
  INDEX_MOINS_SCALE_SCORE = 'INDEX_MOINS_SCALE_SCORE',
  INDEX_PLUS_SCALE_SCORE = 'INDEX_PLUS_SCALE_SCORE',
  SCALE_SCORE_MOINS_INDEX = 'SCALE_SCORE_MOINS_INDEX',
}

export class Classifications {
  Id: number;
  Name: string;
  AttributeData: AttributeData[];
}

export class AttributeData {
  AttributeID: number;
  AttributeType: number;
  AttributeName: string;
  HasValue?: boolean;
  Value?: string;
  AttributeDetails?: AttributeDetails[];
  isSelected?: boolean;
}

export class AttributeDataGraph {
  AttributeID: number;
  AttributeType: number;
  AttributeName: string;
  isSelected: boolean;
}

export class AttributeDataCategories {
  category: string;
  data: AttributeData[];
}

export class AttributeDetails {
  Id: number;
  Text: string;
  Code: string;
  IsValue: boolean;

  constructor(id, text: string, isValue?: boolean) {
    this.Id = id;
    this.Text = text;
    this.Code = '';
    this.IsValue = isValue === undefined ? false : isValue;
  }
}

export class Actiview {
  code: string;
  volunteersNumber: string;
  studyType: string;
  estimated_contribution: string;
  bridge: string;
  orchestra: string;
  study_research_axis_id?: string;
  methods: string[];
  activityNumber: string;
  input: any;
  output: any;
  x_study_link: string;
}

export class OrchestraDemand {
  name: string;
}

export class Orchestra {
  orchestraNumber: string;
  projectName: string;
}

export class ActiviewCampaign {
  focus: Type;
  code: string;
  fieldwork: { id: string, value: string };
  study_type: StudyType;
  methods: StudyType[];
  orchestra_sub_project_code: string;
  comment: string;
  synergy: string;
  estimated_contribution: number;
  number_volunteer: number;
  research_axes: StudyType;
  x_study_link: string;
}

export class RoutineSynergy {
  BatchReferenceField: string;
  BatchTestField: string;
  IDNumberField: string;
  NameReferenceField: string;
  NameTestField: string;
  NumberReferenceField: string;
  NumberTestField: string;
  QuantityReferenceField: string;
  QuantityTestField: string;
}

export class SynergyDemand {
  id: string;
  title: string;
  contributors: UserInCampaign[];
  referent?: UserInCampaign[];
  requester?: string;
  studyId?: string;
  listFormulas: {
    name: string
  }[];
  manager?: string;
  objectives: string;
  // orchestra: string;
  priority?: Priorities;
  routines?: RoutineSynergy[];
  state: {
    StateCode: string;
    NameFR: string;
    NameUS: string;
  };
  workspaces?: string[];
  oldWorkspaces?: string[];
  toxAgreement: boolean;
  // bridgeNumber?: string;
  origin?: string;
  requestNumber?: string;
  projectNumber?: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.contributors = [];
    this.listFormulas = [];
    this.objectives = '';
    this.priority = Priorities.Priority2;
    this.state = {
      StateCode: '',
      NameFR: '',
      NameUS: ''
    };
    this.workspaces = [];
  }
}

export enum Priorities {
  Priority1 = 'P1',
  Priority2 = 'P2',
  Priority3 = 'P3'
}

export type Priority = Priorities.Priority1 | Priorities.Priority2 | Priorities.Priority3;

export const TYPE_EVALUATION = {
  STANDARD: {
    id: '1',
    name: 'Standard'
  },
  ADHOC: {
    id: '2',
    name: 'Ad hoc'
  },
  PERFORMANCE: {
    id: '3',
    name: 'Performance'
  },
  PILOT: {
    id: '4',
    name: 'Pilot'
  },
};

export class TypeEvaluation {
  id: string;
  name: string;

  constructor() {
    this.id = TYPE_EVALUATION.STANDARD.id;
    this.name = TYPE_EVALUATION.STANDARD.name;
  }
}

/*************** FORMULA ***************/

export class Formula {
  name: string;
  routine?: Routine;

  constructor(name) {
    this.name = name;
  }
}

export class FormulasMulti {
  id: string;
  name: string;
  isRoutine: boolean;
  formula: FormulaCampaign;
}

export class ChosenBenchRoutines {
  [idCampaign: string]: {
    [routine: string]: string;
  };
}

export class ChosenFormulas {
  idCampaign: string;
  formulas: {
    id: string;
    isActive: boolean
  }[];
}

export class CampaignFormulasMulti {
  campaignMultiName: string;
  idMultiCampaign: string;
  analyseType: AnalyseTypes;
  chosenBench: ChosenBenchRoutines;
  chosenRoutines: ChosenBenchRoutines;
  chosenFormulas: ChosenFormulas[];
  campaignsFormulas: FormulasMulti[];
  nbRoutines: number;
  nbRoutinesMin: number;
}

export class Routine {
  applicationMoment: string;
  batch: string;
  instrument: string;
  mixture: string;
  quantity: string;
  pauseTime: string;
  visit: string;

  constructor() {
    this.applicationMoment = '';
    this.batch = '';
    this.instrument = '';
    this.mixture = '';
    this.quantity = '';
    this.pauseTime = '';
    this.visit = '';
  }
}

/*************** FEEDBACK ***************/

export class Feedback {
  id: string;
  title: string;
  comments: string;
  source: string;

  constructor() {
    this.title = '';
    this.comments = '';
    this.source = 'studio';
  }
}

/*************** HUB ***************/

export enum Hubs {
  Brazil = 'Brazil',
  China = 'China',
  ChinaISO = 'ChinaISO',
  France = 'France',
  India = 'India',
  Japan = 'Japan',
  US = 'US',
  SouthAfrica = 'SouthAfrica',
  Mexico = 'Mexico'
}

export type Hub = Hubs.Brazil | Hubs.China | Hubs.ChinaISO | Hubs.France | Hubs.India | Hubs.Japan | Hubs.US | Hubs.SouthAfrica | Hubs.Mexico;

export enum HubsById {
  Brazil = 'BR',
  China = 'CH',
  ChinaISO = 'CN',
  France = 'FR',
  India = 'IN',
  Japan = 'JP',
  US = 'US',
  SouthAfrica = 'ZA',
  Mexico = 'ME'
}

export type HubById =
  HubsById.Brazil
  | HubsById.China
  | HubsById.ChinaISO
  | HubsById.France
  | HubsById.India
  | HubsById.Japan
  | HubsById.US
  | HubsById.SouthAfrica
  | HubsById.Mexico;

/*************** LANGUAGE ***************/

export enum Languages {
  English = 'english',
  French = 'french',
  Japanese = 'japanese',
  Chinese = 'chinese',
  Portuguese = 'portuguese',
  Spanish = 'spanish'
}

export type Language =
  Languages.Chinese
  | Languages.French
  | Languages.English
  | Languages.Japanese
  | Languages.Portuguese
  | Languages.Spanish;

  export enum ActiviewLang {
    english = 'en',
    french = 'fr',
    japanese = 'jp',
    chinese = 'ch',
    portuguese = 'pr',
    spanish = 'es'
  }

/*************** LOGIC JUMP ***************/

export enum Conditions {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL'
}

export type Condition = Conditions.EQUAL | Conditions.NOT_EQUAL;

export class LogicJump {
  answer: any;
  condition: Condition;
  idInBlock: string;
  idInQuestionnaire: string;
  jumpTo: string;

  constructor() {
    this.answer = '';
    this.condition = Conditions.EQUAL;
    this.idInQuestionnaire = '';
    this.idInBlock = '';
    this.jumpTo = '';
  }
}

export class LogicJumpNext {
  idInQuestionnaire: string;

  constructor(idInQuestionnaire = '') {
    this.idInQuestionnaire = idInQuestionnaire;
  }
}

/*************** MENU ***************/

export enum MenuType {
  OnePager,
  NotSpecified
}

export class Menu {
  label: string;
  icon: string;
  link: string[];
  id: MenuType;

  constructor(link: string[], label: string, icon: string, id: MenuType = MenuType.NotSpecified) {
    this.label = label;
    this.icon = icon;
    this.link = link;
    this.id = id;
  }
}

/*************** NOTIFICATION ***************/

export type NotificationsMode = NotificationsModes.StartDate | NotificationsModes.OnUserOpen | NotificationsModes.OnUserSubmit;

export enum NotificationsModes {
  StartDate = 'START_DATE',
  OnUserOpen = 'ON_USER_OPEN',
  OnUserSubmit = 'ON_USER_SUBMIT'
}

export class OnePagerImagesComments {
  gridStackElements: GridStackElement[];
  orientationType: number;
}

export class GridStackElement {
  dataGsX: string;
  dataGsY: string;
  dataGsWidth: string;
  dataGsHeight: string;
  element: ImgCom;
}

export class SextiFormula {
  campaignName?: string;
  routineName: string;
  visitName: string;
  routineLabel: string;
  formulaName: string;
  stepName: string;
  lot: string;
  socle: string;
  productName: string;
}

export class ImgCom {
  id?: string;
  name?: string;
  data?: string;
  url?: string;
  comment?: string;
  type: number;

  constructor(type: number = undefined) {
    this.id = '';
    this.comment = '';
    this.type = type;
  }
}

/*************** ONEPAGER ***************/

export class OnePagerIdentification {
  campaignName: string;
  updated_on: number;
  actiview: string;
  bridge: string;
  expert: UserInCampaign[];
  hub: string;
  laboratory: string;
  manager: UserInCampaign[];
  fieldwork: string;
  orchestra: string;
  projectName: string;
  studyDate: string;
  estimatedContribution: number;

  constructor() {
    this.campaignName = '';
    this.actiview = '';
    this.bridge = '';
    this.expert = [];
    this.hub = '';
    this.laboratory = '';
    this.fieldwork = '';
    this.manager = [];
    this.orchestra = '';
    this.projectName = '';
    this.studyDate = '';
  }
}

export class WorkflowMulti {
  name: Translatable;
  id: string;
  idCampaign: string;
  campaignName?: string;
}

export class CampaignsMulti {
  id: string;
  descriptorsGroups: DescriptorGroup[];
  name: string;
  createdBy: any;
  created_on?: number;
  updated_on?: number;
  description?: string;
  graphs?: Graph[];
  studyType?: Type;
  analyseType: AnalyseType;
  hub: string;
  studies: {
    name: string,
    id: string
  }[];
  workspace: string;
  workflows: WorkflowMulti[];
  formulas: {
    name: string,
    idCampaign: string
  }[];
  arcs: {
    [idEtude: string]: string;
  }[];
  synergy: any[];
  orchestra: any[];
  actiview: any[];
  hubs: {
    [idEtude: string]: string;
  }[];
  chosenBench: {
    [idCampaign: string]: {
      bench: string
    };
  };
  chosenRoutines: {
    [idCampaign: string]: {
      [routine: string]: string
    };
  };

  constructor(campaignMultiName, analyseType, workspace, studies, createdBy) {
    this.name = campaignMultiName;
    this.studyType = TYPE_CAMPAIGN.SENSORY_EXPERT;
    this.analyseType = analyseType;
    this.workspace = workspace;
    this.studies = studies;
    this.createdBy = createdBy;
  }
}

export type CampaignsMultiInfoGen =
  Pick<CampaignsMulti, 'id' | 'hub' | 'name' | 'description' | 'studyType' | 'analyseType'>
  & {
    arcs: string;
    synergy: string;
    actiview: string;
    hubs: string;
  };

export interface CampaignsMultiUsers {
  name: string;
  studies: CampaignsUsers[];
}

export interface CampaignsMultiPutUsers {
  studies: Array<{ campaignId: string, users: CampaignMultiVolunteer[] }>;
}

export interface CampaignMultiVolunteer {
  name: string;
  isActive: boolean;
}

export interface CampaignsUsers {
  campaignName: string;
  campaignId: string;
  accountables: UserInCampaign[];
  volunteers: Volunteers[];
  isVolunteer: boolean;
  campaignWorkflowsNames?: Translatable[];
}

export interface CampaignMultiDescriptor {
  id: string;
  name: string;
  descriptorsGroups: DescriptorGroup[];
  graphs: Graph[];
  studies: {
    id: string,
    name: string,
    workflows: Pick<Workflow, 'blocks' | 'id' | 'name'>[];
    nbVisits: number;
  }[];
}

export interface CampaignMultiWorkflows {
  studies: CampaignsWorkflows[];
  name: string;
}

export interface CampaignsWorkflows {
  studies: {
    id: string;
    name: string;
  };
  workflows: WorkflowHead[];
}

export interface CampaignMultiReferenceTested {
  referenceTab: SextiFormula[] | string[];
  testedTab: SextiFormula[] | string[];
  couples: {
    reference: string,
    tested: string,
    campaignName: string
  }[];
}

export interface WorkflowHead {
  id: string;
  name: string;
  idCampaign: string;
}

export interface WorkflowConfiguration {
  workflowName: string;
  blocks?: Block[];
  parameters?: WorkflowParameter;
  notificationMode: NotificationsMode;
  startDate?: any;
  id?: string;
  name?: Translatable;
  isChangedActivecomponent?: boolean;
}

export interface CampaignMultiTarget {
  studies: Campaign[];
  name: string;
}

export interface CampaignMultiTargetProtocol {
  campaignsTarget: Campaign[];
  campaignsTargetProtocolInfo: Resource[];
  campaignsMulti: CampaignsMulti;
}

export interface Resource {
  resources: OnePager[];
}
export interface OnePagerTargetProtocol {
  id: string;
  protocol: string;
  formulas: {
    reference: SextiFormula[],
    tested: SextiFormula[]
  };
  targetAssignment: {
    target: string,
    volunteersNumber: number
  }[];
}

export interface OnePagerPreviewMulti {
  campaignsMulti: CampaignsMulti;
  campaigns: Campaign[];
  campaignsOnePagers: Resource[];
  title: string;
  orchestraNumber: string;
  volunteersStarted: { [idCampaign: string]: string };
  tabReferenceTested: CampaignMultiReferenceTested;
  ficheCaracterisation: FicheCaracterisation[];
  routinesTab: any;
  routines: SextiFormula[];
  visitsByVolunteerTab: {
    campaignName: string,
    visitsByVolunteer: VisitsByVolunteer[]
  }[];
  pdfReportUserByCol: boolean;
}

export interface OnePagerHeaderInfo {
  laboratory: string;
  experts: string;
  fieldwork: string;
  studyDates: string;
}

export class CampaignMultiHead {
  campaignsMulti: CampaignsMulti;
  campaignsHeadInfo: OnePagerIdentification[];
  campaignBinding: { id: string, idOnePager: string }[];
}

export class CapturesUrl {
  index: number;
  url: string;
  id: string;
  orientation: { id: number, type: string };
}

export class CapturesData {
  index: number;
  data: string;
  orientation: { id: number, type: string };
}

export class OnePager {
  id: string;
  conclusion: string;
  context: string;
  pages?: Page[];
  formulas: {
    reference: SextiFormula[],
    tested: SextiFormula[]
  };
  identification: OnePagerIdentification;
  images: Image[];
  gridstackElements: OnePagerImagesComments[];
  capturesUrls: CapturesUrl[];
  kpa: {
    attributes: string,
    categories: string,
    mustHaves: string
  };
  objectives: string;
  otherStrengths: string;
  protocol: string;
  recommendation: string;
  routines: Routine2[];
  targetAssignment: {
    target: string,
    volunteersNumber: number
  }[];
  vigilance: string;
  isBuild?: boolean;
  archiveId?: string;
  archiveProjectTitle?: string;
  archiveRecipients?: [];
  characterizations?: AttributeData[];
  volunteersNumber?: number;
  sendToMilor: boolean;
  substrate?: TypeSubstrate;

  constructor() {
    this.conclusion = '';
    this.context = '';
    this.formulas = {
      reference: [],
      tested: []
    };
    this.identification = new OnePagerIdentification();
    this.images = [];
    this.gridstackElements = [];
    this.kpa = {
      attributes: '',
      categories: '',
      mustHaves: ''
    };
    this.objectives = '';
    this.otherStrengths = '';
    this.protocol = '';
    this.recommendation = '';
    this.routines = [];
    this.targetAssignment = [{
      target: '',
      volunteersNumber: 0
    }];
    this.vigilance = '';
    this.characterizations = [];
    this.sendToMilor = false;
  }
}

export class Kpa {
  attributes: string;
  categories: string;
  mustHaves: string;

  constructor() {
    this.attributes = '';
    this.categories = '';
    this.mustHaves = '';
  }
}

export class TargetAndProtocol {
  campaignName: string;
  protocol: string;
  targetAssignment: {
    target: string,
    volunteersNumber: number
  }[];

  constructor() {
    this.campaignName = '';
    this.targetAssignment = [{
      target: '',
      volunteersNumber: 0
    }];
  }
}

export class Conclusions {
  campaignName: string;
  conclusion: string;
  otherStrengths: string;
  vigilance: string;
  recommendation: string;

  constructor() {
    this.campaignName = '';
    this.conclusion = '';
    this.otherStrengths = '';
    this.recommendation = '';
    this.vigilance = '';
  }
}

export class OnePagerContext {
  campaignName: string;
  context: string;
  objectives: string;
  kpa: Kpa;

  constructor() {
    this.campaignName = '';
    this.context = '';
    this.objectives = '';
    this.kpa = new Kpa();
  }
}

export class OnePagerRoutines {
  idCampaign: string;
  campaignName: string;
  routines: RoutineTabForReport[];
}

export class OnePagerVolunteers {
  campaignName: string;
  formsCaracterisation: FicheCaracterisation[];
}

export class OnePagerMulti {
  id: string;
  conclusions: Conclusions[];
  context: OnePagerContext[];
  formulas: {
    reference: SextiFormula[],
    tested: SextiFormula[]
  };
  identification: OnePagerIdentification[];
  images: Image[];
  gridstackElements: OnePagerImagesComments[];
  capturesUrls: CapturesUrl[];
  routines: OnePagerRoutines[];
  targetAndProtocol: TargetAndProtocol[];
  volunteers?: OnePagerVolunteers[];

  constructor() {
    this.conclusions = [];
    this.context = [];
    this.formulas = {
      reference: [],
      tested: []
    };
    this.identification = [];
    this.images = [];
    this.gridstackElements = [];
    this.capturesUrls = [];
    this.routines = [];
    this.targetAndProtocol = [];
  }
}

export enum OnePagerTabs {
  header = 'HEADER',
  context = 'CONTEXT',
  targetProtocol = 'TARGET_PROTOCOL',
  images = 'IMAGES',
  conclusions = 'CONCLUSIONS',
  preview = 'PREVIEW'
}

/*************** OTHERS ***************/

export interface CameraMode {
  name: string;
}

export interface DatabaseBooleanId {
  [s: string]: boolean;
}

export class Dropzone {
  title: string;
  model: any;
}

/*************** SIMCOMP ***************/

export class SimComp {
  id: string;
  name: string;
  bench: GroupedFormula;
  lab: GroupedFormula[];
  formula: GroupedFormula[];
  isActive: boolean;

  constructor(formula: GroupedFormula[], bench: GroupedFormula, isActive: boolean, id: string) {
    this.id = id;
    this.bench = bench;
    this.lab = formula.filter(f => f.name !== bench.name);
    this.formula = formula;
    this.isActive = isActive;
  }
}

/*************** STATE ***************/

export enum CampaignStates {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
  Finished = 'FINISHED',
  Abandoned = 'ABANDONED',
  Archived = 'ARCHIVED',
  Removed = 'REMOVED'
}

export enum EvaluationStates {
  New = 'NEW',
  InProgress = 'IN PROGRESS',
  Completed = 'COMPLETED'
}

export enum States {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED',
  Archived = 'ARCHIVED',
  Finished = 'FINISHED',
  Removed = 'REMOVED'
}

export type State = States.Draft | States.Published | States.Suspended | States.Archived | States.Removed;
export type StateCampaign =
  CampaignStates.Draft
  | CampaignStates.Suspended
  | CampaignStates.Published
  | CampaignStates.Abandoned
  | CampaignStates.Finished
  | CampaignStates.Archived
  | CampaignStates.Removed;
export type StateEvaluation = EvaluationStates.New | EvaluationStates.InProgress | EvaluationStates.Completed;

/*************** TABLE ***************/

export class TableData {
  id: string;
  row: TableRow[];
}

export class TableHeader {
  id: string;
  name: string;
  icon: string;
  sortable: boolean;

  constructor(name: string = '', sortable: boolean, icon: string = '', id: string = '') {
    this.id = id;
    this.icon = icon;
    this.name = name;
    this.sortable = sortable;
  }
}

export class TableRow {
  name: string;
}

/*************** TAG ***************/

export class Tag {
  name: string;

  constructor(name) {
    this.name = name;
  }
}

/*************** TRAINING ***************/

export class Training {
  id: string;
  name: string;
  assessments?: Assessment[];
  createdBy?: any;
  created_on: number;
  updated_on: number;
  description?: string;
  formula: FormulaCampaign;
  parameters: TrainingParameter;
  progress?: any;
  sessions: number;
  startDate?: any;
  state: CampaignStates;
  users: UserTraining;
  workflows?: Workflow[];
  workspaces: any[];

  constructor() {
    this.name = '';
    this.description = '';
    this.formula = new FormulaCampaign();
    this.parameters = new TrainingParameter();
    this.progress = 0;
    this.sessions = 1;
    this.state = CampaignStates.Draft;
    this.users = new UserTraining();
    this.workflows = [];
  }
}

export class Assessment {
  id: string;
  answers: Answer[];
  applicationVersion?: string;
  created_on: number;
  updated_on: number;
  dateStart?: number;
  formula: SimComp;
  idTraining: string;
  idWorkflow: string;
  participant: Participant;
  progress?: number;
  session: string;
  state: string;
  training?: Training;
  users: {
    key: string,
    name: string
  }[];
  workflow: string;
}

export class Participant {
  key?: string;
  name: string;
}

export interface TrainingMetadata {
  users: User[];
  workflows: Workflow[];
}

export class TrainingParameter {
  displayBlockName: boolean;
  previousAvailable: boolean;
  sequentialMode: boolean;
  viewGroup?: ViewGroup;
  viewType: ViewType;

  constructor() {
    this.displayBlockName = false;
    this.previousAvailable = true;
    this.sequentialMode = false;
    this.viewType = ViewTypes.ModeCard;
  }
}

export class UserTraining {
  accountables: UserInCampaign[];
  participants: Participant[];

  constructor() {
    this.accountables = [];
    this.participants = [];
  }
}

/*************** TRANSLATABLE ***************/

export class Translatable {
  chinese: string;
  english: string;
  french: string;
  japanese: string;
  portuguese: string;
  spanish: string;

  constructor(text: string = '') {
    this.chinese = '';
    this.english = text;
    this.french = '';
    this.japanese = '';
    this.portuguese = '';
    this.spanish = '';
  }
}

export class TranslatableArray {
  chinese: any[];
  english: any[];
  french: any[];
  japanese: any[];
  portuguese: any[];
  spanish: any[];

  constructor() {
    this.chinese = [];
    this.english = [];
    this.french = [];
    this.japanese = [];
    this.portuguese = [];
    this.spanish = [];
  }
}

export interface DNATranslation {
  id: string;
  translations: DNATranslationLanguage;
}

export interface DNATranslationLanguage {
  english: any;
  french: any;
}

/*************** TYPE ***************/

export interface Type {
  id: string;
  name: string;
}

export const CHART_TYPE = {
  BAR: {
    key: 'CHARTS_BAR',
    value: 'CHARTS.BAR'
  },
  COLORATION_TABLE: {
    key: 'COLORATION_TABLE',
    value: 'CHARTS.COLORATION_TABLE'
  },
  RAW_DATA_TABLE: {
    key: 'RAW_DATA_TABLE',
    value: 'CHARTS.RAW_DATA_TABLE'
  },
  RAW_DATA_COUNT_TABLE: {
    key: 'RAW_DATA_COUNT_TABLE',
    value: 'CHARTS.RAW_DATA_COUNT_TABLE'
  },
  RAW_DATA_MEDIAN_TABLE: {
    key: 'RAW_DATA_MEDIAN_TABLE',
    value: 'CHARTS.RAW_DATA_MEDIAN_TABLE'
  },
  TORNADO: {
    key: 'CHARTS_TORNADO',
    value: 'CHARTS.TORNADO'
  },
  BAR_TWO_SIDED: {
    key: 'CHARTS_BAR_TWO_SIDED',
    value: 'CHARTS.BAR_TWO_SIDED'
  },
  HISTOGRAM: {
    key: 'CHARTS_HISTOGRAM',
    value: 'CHARTS.HISTOGRAM'
  },
  HISTOGRAM_MULTI_DIMENSIONS: {
    key: 'CHARTS_HISTOGRAM_MULTI_DIMENSIONS',
    value: 'CHARTS.HISTOGRAM_MULTI_DIMENSIONS'
  },
  HISTOGRAM_MULTI_FORMULAS: {
    key: 'CHARTS_HISTOGRAM_MULTI_FORMULAS',
    value: 'CHARTS.HISTOGRAM_MULTI_FORMULAS'
  },
  HISTOGRAM_MULTI_USERS: {
    key: 'CHARTS_HISTOGRAM_MULTI_USERS',
    value: 'CHARTS.HISTOGRAM_MULTI_USERS'
  },
  PIE: {
    key: 'CHARTS_PIE',
    value: 'CHARTS.PIE'
  },
  RADAR: {
    key: 'CHARTS_RADAR',
    value: 'CHARTS.RADAR'
  },
  LINE: {
    key: 'CHARTS_LINE',
    value: 'CHARTS.LINE'
  },
  TABLE_COMMENTS: {
    key: 'CHARTS_TABLE_COMMENTS',
    value: 'CHARTS.TABLE_COMMENTS'
  },
  TABLE_CROSSED_USERS_VALUES: {
    key: 'CHARTS_TABLE_CROSSED_USERS_VALUES',
    value: 'CHARTS.TABLE_CROSSED_USERS_VALUES'
  },
  PELUCHES_CHARTS: {
    key: 'CHARTS_PELUCHES_CHARTS',
    value: 'CHARTS.PELUCHES_CHARTS'
  },
  PELUCHES_RISKS_CHARTS: {
    key: 'CHARTS_PELUCHES_RISKS_CHARTS',
    value: 'CHARTS.PELUCHES_RISKS_CHARTS'
  },
  CONFORT_HISTOGRAM: {
    key: 'CHARTS_CONFORT_HISTOGRAM',
    value: 'CHARTS.CONFORT_HISTOGRAM'
  },
  CONFORT_CINETIC: {
    key: 'CHARTS_CONFORT_CINETIC',
    value: 'CHARTS.CONFORT_CINETIC'
  }
};

export class ChartType {
  key: string;
  value: string;

  constructor() {
    this.key = CHART_TYPE.RAW_DATA_TABLE.key;
    this.value = CHART_TYPE.RAW_DATA_TABLE.value;
  }
}

export enum DNATypes {
  Block = 'block',
  Campaign = 'campaign',
  CampaignsMulti = 'multi',
  CampaignExport = 'campaignExport',
  Descriptors = 'descriptors',
  DescriptorsGroup = 'descriptorsGroup',
  DescriptorsGroupActions = 'descriptorsGroupActions',
  Evaluation = 'evaluation',
  Profile = 'profile',
  SynergyDemand = 'synergyDemand',
  Training = 'trainings',
  Volunteers = 'volunteers',
  Workflow = 'workflow',
  Workspace = 'workspace',
  Panelists = 'panelists',
  PanelistsArcs = 'panelistsArcs',
  GroupPanelistsHA = 'GroupPanelistsHA',
  Feedbacks = 'feedbacks',
  Template = 'template'
}

export type DNAType = DNATypes.Block | DNATypes.Campaign | DNATypes.CampaignExport | DNATypes.CampaignsMulti | DNATypes.Evaluation | DNATypes.Profile | DNATypes.Descriptors |
  DNATypes.DescriptorsGroup | DNATypes.DescriptorsGroupActions | DNATypes.SynergyDemand | DNATypes.Training | DNATypes.Volunteers | DNATypes.Workflow |
  DNATypes.Workspace | DNATypes.Panelists | DNATypes.GroupPanelistsHA | DNATypes.Feedbacks | DNATypes.PanelistsArcs | DNATypes.Template;

export declare interface KeyValue {
  key: string;
  value: string;
}

export declare interface IdValue {
  [id: string]: string;
}

export declare interface KeyBoolean {
  [key: string]: boolean;
}

export enum Metiers {
  Hair = 'METIER_HAIR',
  Skin = 'METIER_SKIN'
}

export type TypeMetier = Metiers.Hair | Metiers.Skin;

export enum ARCSMetiers {
  Hair = 'CAPI',
  Skin = '(?!CAPI).*'
}

export enum RoutineApplications {
  HairDry = 'HAIR_DRY',
  HairWet = 'HAIR_WET',
  Layering = 'LAYERING'
}

export type RoutineApplication = RoutineApplications.HairDry | RoutineApplications.HairWet | RoutineApplications.Layering;

export enum TypeApplicationAreas {
  ClippedLocks_Extensions = 'CLIPPEDLOCKS_EXTENSIONS',
  Eyebrows = 'EYEBROWS',
  EyeContour = 'EYE_CONTOUR',
  Eyelashes = 'EYELASHES',
  Eyelids = 'EYELIDS',
  Face = 'FACE',
  Hair = 'HAIR',
  Lips = 'LIPS',
  Scalp = 'SCALP',
  Moustache = 'MOUSTACHE',
  Beard = 'BARBE',
  HairRoots = 'HAIR_ROOTS',
  HairLength = 'HAIR_LENGTH',
  HairEnds = 'HAIR_ENDS',
  HairRLP = 'HAIR_RLP',
  MiniN = 'MINI_NUQUE',
  MiniT = 'MINI_TEMPE',
  MiniG = 'MINI_GOLF',
  Arms = 'ARMS'
}

export interface TypeApplicationArea {
  key: TypeApplicationAreas.ClippedLocks_Extensions | TypeApplicationAreas.Eyebrows | TypeApplicationAreas.EyeContour | TypeApplicationAreas.Eyelashes |
  TypeApplicationAreas.Eyelids | TypeApplicationAreas.Face | TypeApplicationAreas.Hair | TypeApplicationAreas.Lips | TypeApplicationAreas.Scalp;
  value: string;
}

export enum ApplicationModes {
  BowlBrush = 'BOWL_BRUSH',
  Brush = 'BRUSH',
  Cap_Hairnet = 'CAP_HAIRNET',
  Comb = 'COMB',
  Cotton = 'COTTON',
  Curlers = 'CURLERS',
  Finger = 'FINGER',
  Hands = 'HANDS',
  HeatingPads = 'HEATING_PADS',
  OpenAir_Planchette = 'OPEN_AIR_PLANCHETTE',
  Pack = 'PACK',
  PartingByParting = 'PARTING_BY_PARTING',
  Pipette = 'PIPETTE',
  RollOn = 'ROLL_ON',
  Sponge = 'SPONGE',
  Spotting = 'SPOTTING',
  ThreePartingOnVertex = 'THREE_PARTING_ON_VERTEX',
  TubeCanule = 'TUBE_CANULE',
  Vertex = 'VERTEX',
  Wrapped = 'WRAPPED',
  Wrapped_Alu = 'WRAPPED_ALU',
  Wrapped_Cellophane = 'WRAPPED_CELLOPHANE',
  Wrapped_Quick = 'WRAPPED_QUICK',
  Wrapped_Sweet = 'WRAPPED_SWEET'
}

export interface TypeApplicationMode {
  key: ApplicationModes.BowlBrush | ApplicationModes.Brush | ApplicationModes.Cap_Hairnet | ApplicationModes.Comb | ApplicationModes.Curlers |
  ApplicationModes.Finger | ApplicationModes.Hands | ApplicationModes.HeatingPads | ApplicationModes.OpenAir_Planchette |
  ApplicationModes.Pack | ApplicationModes.PartingByParting | ApplicationModes.Pipette | ApplicationModes.RollOn | ApplicationModes.Sponge |
  ApplicationModes.ThreePartingOnVertex | ApplicationModes.TubeCanule | ApplicationModes.Vertex | ApplicationModes.Wrapped |
  ApplicationModes.Wrapped_Alu | ApplicationModes.Wrapped_Cellophane | ApplicationModes.Wrapped_Quick | ApplicationModes.Wrapped_Sweet | ApplicationModes.Cotton;
  value: string;
}

export const TypeApplicationTypes = {
  EntireHead: {
    key: 'PROTOCOL_ENTIRE_HEAD',
    value: ''
  },
  HalfHead: {
    key: 'PROTOCOL_HALF_HEAD',
    value: ''
  },
  LocksNecks: {
    key: 'PROTOCOL_NECK_LOCKS',
    value: ''
  },
  ThreeRoutines: {
    key: 'PROTOCOL_THREE_ROUTINES',
    value: ''
  },
  TwoRoutinesNeck: {
    key: 'PROTOCOL_TWO_ROUTINES_NECK',
    value: ''
  },
  TwoRoutinesNeck_Strip: {
    key: 'PROTOCOL_TWO_ROUTINES_NECK_STRIP',
    value: ''
  },
  ThreeRoutinesNeck: {
    key: 'PROTOCOL_THREE_ROUTINES_NECK',
    value: ''
  },
  ThreeRoutinesLeftRight: {
    key: 'PROTOCOL_THREE_ROUTINES_LR',
    value: ''
  },
  ThreeRoutinesFrontBack: {
    key: 'PROTOCOL_THREE_ROUTINES_FB',
    value: ''
  },
  ThreeRoutinesColdSwatches: {
    key: 'PROTOCOL_THREE_ROUTINES_COLD_SWATCHES',
    value: ''
  },
  ThreeRoutinesHotSwatches: {
    key: 'PROTOCOL_THREE_ROUTINES_HOT_SWATCHES',
    value: ''
  },
  ThreeQuarters_OneQuarter: {
    key: 'PROTOCOL_THREE_QUARTERS',
    value: ''
  },
  HalfHead_ClippedLock: {
    key: 'PROTOCOL_HALF_HEADS_CLIPPED_LOCKS',
    value: ''
  },
  HalfHead_ClippedLockHot: {
    key: 'PROTOCOL_HALF_HEADS_CLIPPED_LOCKS_HOT',
    value: ''
  },
  ClippedLock: {
    key: 'PROTOCOL_CLIPPED_LOCKS',
    value: ''
  }
};

export const ApplicationOrders = {
  clockwise: {
    key: 'CLOCKWISE',
    value: ''
  },
  counterclockwise: {
    key: 'COUNTERCLOCKWISE',
    value: ''
  },
  none: {
    key: 'NONE',
    value: ''
  }
};

export enum DryingTypes {
  BlowOutWithBrush_Comb = 'BLOW_OUT_WITH_BRUSH_COMB',
  BlowOutWithFingers = 'BLOW_OUT_WITH_FINGERS',
  Climazon = 'CLIMAZON',
  CurlCreation = 'CURL_CREATION',
  Diffuser = 'DIFFUSER',
  ElectricCurlingIron = 'ELECTRIC_CURLING_IRON',
  ElectricFlatIron = 'ELECTRIC_FLAT_IRON',
  HoodDryer = 'HOOD_DRYER',
  NaturalDrying = 'NATURAL_DRYING',
  OtherThermalStylingTool = 'OTHER_THERMAL_STYLING_TOOL',
  Steampod = 'STEAMPOD'
}

export interface TypeDryingType {
  key: DryingTypes.BlowOutWithBrush_Comb | DryingTypes.BlowOutWithFingers | DryingTypes.Climazon | DryingTypes.CurlCreation | DryingTypes.Diffuser |
  DryingTypes.ElectricCurlingIron | DryingTypes.ElectricFlatIron | DryingTypes.HoodDryer | DryingTypes.NaturalDrying | DryingTypes.OtherThermalStylingTool |
  DryingTypes.Steampod;
  value: string;
}

export enum TypeMethods {
  Acceptability = 'ACCEPTABILITY',
  AntiDandruffEfficiency = 'ANTIDANDRUFFEFFICIENCY',
  AntiOilyEfficiency = 'ANTIOILYEFFICIENCY',
  Bonding = 'BONDING',
  Cleanliness = 'CLEANLINESS',
  Cosmetic = 'COSMETIC',
  CurlPerf = 'CURL_PERFORMANCES',
  Discomfort = 'DISCONFORT',
  Dosage = 'DOSAGE',
  Ergoanalysis = 'ERGO_ANALYSIS',
  Fixation = 'FIXATION',
  HairSampling = 'HAIR_SAMPLING',
  ImmediateEffect = 'IMMEDIATE_EFFECT',
  Integrity = 'INTEGRITY',
  LissagePerf = 'LISSAGE_PERFORMANCES',
  LongLasting = 'LONG_LASTING',
  Manageability = 'MANAGEABILITY',
  Measurements = 'MEASUREMENTS',
  Noodles = 'NOODLES',
  ShadeLift = 'SHADE_LIFT',
  Shine = 'SHINE',
  StylingPerformances = 'STYLING_PERFORMANCES',
  Texture = 'TEXTURE',
  UsageQualities = 'USAGE_QUALITIES',
  Volume = 'VOLUME'
}

export interface TypeMethod {
  key: TypeMethods.Acceptability | TypeMethods.AntiDandruffEfficiency | TypeMethods.AntiOilyEfficiency | TypeMethods.Bonding |
  TypeMethods.Cleanliness | TypeMethods.Cosmetic | TypeMethods.CurlPerf | TypeMethods.Dosage | TypeMethods.Ergoanalysis | TypeMethods.Fixation |
  TypeMethods.HairSampling | TypeMethods.ImmediateEffect | TypeMethods.Integrity | TypeMethods.LissagePerf | TypeMethods.LongLasting |
  TypeMethods.Manageability | TypeMethods.Measurements | TypeMethods.Noodles | TypeMethods.ShadeLift | TypeMethods.Shine |
  TypeMethods.StylingPerformances | TypeMethods.Texture | TypeMethods.UsageQualities | TypeMethods.Volume | TypeMethods.Discomfort;
  value: string;
}

export enum TypeScales {
  Mirror = 'MIRROR',
  MirrorQualitative = 'MIRROR_QUALITATIVE',
  OpenComments = 'OPEN_COMMENTS',
  Qualitative = 'QUALITATIVE',
  Ranking = 'RANKING',
  Linear = 'LINEAR'
}

export interface TypeScale {
  key: TypeScales.Mirror | TypeScales.MirrorQualitative | TypeScales.OpenComments | TypeScales.Qualitative | TypeScales.Ranking | TypeScales.Linear;
  value: string;
}

export enum ProductsTypes {
  BB = 'BB',
  Brow = 'BROW',
  Bundle = 'BUNDLE',
  CleansingFoamCleanser = 'ROUTINE_CLASS_CLEANSING_FOAM_CLEANSER',
  CleansingRinseOff = 'ROUTINE_CLASS_CLEANSING_RINSE_OFF',
  CleansingWipeOff = 'ROUTINE_CLASS_CLEANSING_WIPE_OFF',
  Concealer = 'CONCEALER',
  Cream = 'CREAM',
  Curls = 'CURLS',
  DirectColouring = 'COLORATION_DIRECT',
  Discolouration = 'DECOLORATION',
  DryShampoo = 'DRY_SHAMPOO',
  Eyebrow = 'EYEBROW_LINER',
  EyeContour = 'EYE_CONTOUR',
  EyeLiner = 'EYELINER',
  Facemask = 'FACEMASK',
  FacemaskSupport = 'FACEMASK_SUPPORT',
  FAP = 'FAP',
  FDT = 'FDT',
  FDTCompact = 'FDT_COMPACT',
  FDTLiquid = 'FDT_LIQUID',
  HairSmoothing = 'LISSAGE',
  TonOnTon = 'COLORATION_TON_TON',
  Hairspray = 'SPRAY',
  Hybrid = 'HYBRID',
  Gel = 'STYLING_GEL',
  ComplexRoutine = 'COMPLEX_ROUTINE',
  Device = 'ROUTINE_CLASS_DEVICE',
  DeviceProduct = 'DEVICE_PRODUCT',
  InstrumentalCosmetic = 'INSTRUMENTAL_COSMETIC',
  LipPencil = 'LIP_PENCIL',
  Lotion = 'LOTION',
  Manageability = 'MANAGEABILITY',
  Mascara = 'MASCARA',
  NaturalColouring = 'COLORATION_NATURELLE',
  NonRinsedCare = 'NONRINSEDCARE',
  Oil = 'OIL',
  OxydationDyeing = 'COLORATION_OXYDATION',
  Pack = 'PACK',
  Packaging = 'PACKAGING',
  PreTraitement = 'PRE_TRAITEMENT',
  Powder = 'POWDER',
  PreShampoo = 'PRESHAMPOO',
  RAL = 'RAL',
  RinsedCare = 'RINSEDCARE',
  Defrisage = 'DEFRISAGE',
  ScalpCare = 'SCALPCARE',
  Scrub = 'SCRUB',
  Serum = 'SERUM',
  Shampoo = 'SHAMPOOING',
  Spray = 'SPRAY',
  Stick = 'STICK',
  Trinôme = 'TRINOMIAL',
  Volume = 'VOLUME',
  Wax = 'WAX',
  PermanentMakeUp = 'PERMANENT_MAKEUP',
  HairMakeUp = 'HAIR_MAKEUP',
  Permanente = 'PERMANENTE',
  StylingGel = 'STYLING_GEL',
  StylingWax = 'STYLING_WAX',
  StylingPowder = 'STYLING_POWDER',
  StylingSpray = 'STYLING_SPRAY',
  StylingCream = 'STYLING_CREAM',
  StylingLacquer = 'STYLING_LACQUER',
  StylingFoam = 'STYLING_FOAM',
  StylingSerum = 'STYLING_SERUM'
}

export interface ProductsType {
  key: ProductsTypes.BB | ProductsTypes.Brow | ProductsTypes.Bundle | ProductsTypes.CleansingFoamCleanser | ProductsTypes.CleansingRinseOff |
  ProductsTypes.CleansingWipeOff | ProductsTypes.Concealer | ProductsTypes.Cream | ProductsTypes.Curls | ProductsTypes.DirectColouring |
  ProductsTypes.Discolouration | ProductsTypes.DryShampoo | ProductsTypes.Eyebrow | ProductsTypes.EyeContour | ProductsTypes.EyeLiner |
  ProductsTypes.Facemask | ProductsTypes.FacemaskSupport | ProductsTypes.FAP | ProductsTypes.FDT | ProductsTypes.FDTCompact | ProductsTypes.FDTLiquid | ProductsTypes.Gel |
  ProductsTypes.HairSmoothing | ProductsTypes.Hairspray | ProductsTypes.Hybrid | ProductsTypes.InstrumentalCosmetic | ProductsTypes.LipPencil |
  ProductsTypes.Lotion | ProductsTypes.Manageability | ProductsTypes.Mascara | ProductsTypes.NaturalColouring | ProductsTypes.NonRinsedCare |
  ProductsTypes.Oil | ProductsTypes.OxydationDyeing | ProductsTypes.Pack | ProductsTypes.Packaging | ProductsTypes.Powder | ProductsTypes.PreShampoo |
  ProductsTypes.RAL | ProductsTypes.RinsedCare | ProductsTypes.ScalpCare | ProductsTypes.Scrub | ProductsTypes.Serum | ProductsTypes.Shampoo |
  ProductsTypes.Spray | ProductsTypes.Trinôme | ProductsTypes.Volume | ProductsTypes.Wax | ProductsTypes.TonOnTon | ProductsTypes.ComplexRoutine | ProductsTypes.Device |
  ProductsTypes.DeviceProduct | ProductsTypes.PermanentMakeUp | ProductsTypes.HairMakeUp | ProductsTypes.Defrisage | ProductsTypes.Permanente |
  ProductsTypes.StylingGel | ProductsTypes.StylingWax | ProductsTypes.StylingPowder | ProductsTypes.StylingSpray | ProductsTypes.StylingCream |
  ProductsTypes.StylingLacquer | ProductsTypes.StylingFoam | ProductsTypes.StylingSerum | ProductsTypes.PreTraitement | ProductsTypes.Stick;
  value: string;
}

export enum StudyTypes {
  EH_SHORT_HOUSE = 'EH_SHORT_HOUSE',
  EH_SHORT_CONTRACTORS = 'EH_SHORT_CONTRACTORS',
  EH_MEDIUM_HOUSE = 'EH_MEDIUM_HOUSE',
  EH_MEDIUM_CONTRACTORS = 'EH_MEDIUM_CONTRACTORS',
  EH_LONG_HOUSE = 'EH_LONG_HOUSE',
  EH_LONG_CONTRACTORS = 'EH_LONG_CONTRACTORS',
  EC_FE = 'EC_FE',
  EC_FE_CUSTOM_LG_LASTING_EFFECTS = 'EC_FE_CUSTOM_LG_LASTING_EFFECTS',
  WORKSHOP = 'WORKSHOP',
  WORKSHOP_CONTRACTORS = 'WORKSHOP_CONTRACTORS',
  EXPLOITING_USE_OF_DATABASE = 'EXPLOITING_USE_OF_DATABASE',
  SCALP_DISCOMFORT = 'SCALP_DISCOMFORT',
  SCALP_DISCOMFORT_CONTRACTORS = 'SCALP_DISCOMFORT_CONTRACTORS',
  SKIN_DISCOMFORT = 'SKIN_DISCOMFORT',
  PROFILE_INTERNAL_RESOURCES = 'PROFILE_INTERNAL_RESOURCES',
  PROFILE_CONTRACTED_RESOURCES = 'PROFILE_CONTRACTED_RESOURCES',
  SENSORY_TEST_NOVEL_TECHNIQUES_OR_NAILSUS = 'SENSORY_TEST_NOVEL_TECHNIQUES_OR_NAILSUS',
  FLASH_IN_VITRO_EXPERTS = 'FLASH_IN_VITRO_EXPERTS',
  PACKAGING_STUDY = 'PACKAGING_STUDY',
  ROUTINE_REPORTING_DATA = 'ROUTINE_REPORTING_DATA'
}

export interface StudyType {
  code: string;
  name: any;
  is_active?: boolean;
}

export enum TypeSubstrates {
  Volunteers = 'VOLUNTEERS',
  HairLocks = 'HAIR_LOCKS',
  MalleableHead = 'MALLEABLE_HEADS',
  Alias = 'ALIAS',
  PonyTail = 'PONY_TAIL',
  Partials = 'PARTIALS'
}

export interface TypeSubstrate {
  key: TypeSubstrates.Volunteers | TypeSubstrates.HairLocks | TypeSubstrates.MalleableHead | TypeSubstrates.Alias |
  TypeSubstrates.PonyTail | TypeSubstrates.Partials;
  value: string;
}

export enum TypeTests {
  Absolute = 'ABSOLUTE',
  DevelopmentMethod = 'METHOD_DEVELOPMENT',
  Knowledge = 'KNOWLEDGE',
  MonoApplication = 'MONO_APPLICATION',
  MultiApplication = 'MULTI_APPLICATION',
  Remanence_Tenacity = 'AFTERGLOW_TENACITY',
  SimultaneousComparison = 'SIMULTANEOUS_COMPARISON',
  TrainingCourse = 'TRAINING',
  VersusBareSkin = 'VERSUS_BARE_SKIN',
  ValidationPerfScreen = 'VALIDATION_PERF_SCREEN'
}

export const ApplicationsZones: KeyValue[] = [
  {
    key: '1',
    value: '1_ZONE'
  }, {
    key: '2',
    value: '2_ZONES'
  }, {
    key: '3',
    value: '3_ZONES'
  }
];

export enum APPLICATION_ZONE {
  ONE_ZONE = '1',
  TWO_ZONES = '2',
  THREE_ZONES = '3'
}

export interface TypeTest {
  key: TypeTests.Absolute | TypeTests.DevelopmentMethod | TypeTests.Knowledge | TypeTests.MonoApplication | TypeTests.MultiApplication | TypeTests.Remanence_Tenacity | TypeTests.SimultaneousComparison | TypeTests.TrainingCourse | TypeTests.VersusBareSkin | TypeTests.ValidationPerfScreen;
  value: string;
}

/*************** USER ***************/

export interface Favorite {
  id: string;
  type: string;
}

export class UserWorkspace {
  id: string;
  isAdmin: boolean;
  isCurrent: false;
  name: string;
}

export class User {
  id?: string;
  name: string;
  confirm?: string;
  currentWorkspace?: Workspace;
  displayName?: string;
  email?: string;
  favorites: Favorite[];
  formulas: any;
  hub?: HubById;
  key?: string;
  language: Language;
  password?: string;
  pincode?: string;
  roles: string[];
  tests: Favorite[];
  theme?: string;
  token?: string;
  track?: string;
  user?: string;
  value?: string;
  workspaces: UserWorkspace[];

  constructor() {
    this.name = '';
    this.favorites = [];
    this.language = Languages.English;
    this.roles = [];
    this.tests = [];
    this.workspaces = [];
  }
}

/*************** VIEW ***************/

export type ViewGroup = ViewGroups.FormulaGroup | ViewGroups.QuestionGroup;

export enum ViewGroups {
  FormulaGroup = 'FORMULA',
  QuestionGroup = 'QUESTION'
}

export type ViewType = ViewTypes.ModeCard | ViewTypes.ModeList | ViewTypes.ModeDualCard | ViewTypes.ModeCustomizeCard;

export enum ViewTypes {
  ModeCard = 'CARD_MODE',
  ModeDualCard = 'DUAL_CARD_MODE',
  ModeList = 'LIST_MODE',
  ModeCustomizeCard = 'CUSTOMIZE_CARD_MODE'
}

/*********** ROUTINE CLASS ********/

export enum Categories {
  Additive = 'ROUTINE_CLASS_ADDITIVE',
  Aerosol = 'ROUTINE_CLASS_AEROSOL',
  BaseCoat = 'ROUTINE_CLASS_BASE_COAT',
  Beads = 'ROUTINE_CLASS_BEADS',
  CleansingFoamCleanser = 'ROUTINE_CLASS_CLEANSING_FOAM_CLEANSER',
  CleansingRinseOff = 'ROUTINE_CLASS_CLEANSING_RINSE_OFF',
  CleansingWipeOff = 'ROUTINE_CLASS_CLEANSING_WIPE_OFF',
  CompactFoundation = 'FDT_COMPACT',
  CompetitorProduct = 'ROUTINE_CLASS_COMPETITOR_PRODUCT',
  Concealer = 'CONCEALER',
  Conditionner = 'ROUTINE_CLASS_CONDITIONNER',
  Cream = 'CREAM',
  Device = 'ROUTINE_CLASS_DEVICE',
  Diluteur = 'ROUTINE_CLASS_DILUTEUR',
  Dough = 'ROUTINE_CLASS_PASTE',
  EyeContour = 'EYE_CONTOUR',
  EyebrowLiner = 'EYEBROW_LINER',
  EyeLiner = 'EYELINER',
  Fap = 'FAP',
  Fixative = 'ROUTINE_CLASS_NEUTRALIZER',
  Gel = 'GEL',
  Hybrid = 'HYBRID',
  LiquidFoundation = 'FDT_LIQUID',
  LipPencil = 'LIP_PENCIL',
  Lotion = 'ROUTINE_CLASS_MILK',
  Mask = 'ROUTINE_CLASS_MASK',
  Mascara = 'MASCARA',
  MilkyLotion = 'ROUTINE_CLASS_MILKY_LOTION',
  Mousse = 'ROUTINE_CLASS_FOAM',
  Oil = 'OIL',
  Oxidizing = 'ROUTINE_CLASS_DEVELOPER',
  Powder = 'POWDER',
  Primer = 'BB',
  Ral = 'RAL',
  Reducing = 'ROUTINE_CLASS_REDUCING_LOTION',
  Scrub = 'SCRUB',
  Serum = 'SERUM',
  Shampoo = 'ROUTINE_CLASS_SHAMPOO',
  SheetMask = 'FACEMASK_SUPPORT',
  Spray = 'SPRAY',
  Thickener = 'ROUTINE_CLASS_THICKENER',
  TopCoat = 'ROUTINE_CLASS_TOP_COAT',
  Wax = 'WAX'
}

export type Category =
  Categories.Additive |
  Categories.Aerosol |
  Categories.BaseCoat |
  Categories.Beads |
  Categories.CleansingFoamCleanser |
  Categories.CleansingRinseOff |
  Categories.CleansingWipeOff |
  Categories.CompactFoundation |
  Categories.CompetitorProduct |
  Categories.Concealer |
  Categories.Conditionner |
  Categories.Cream |
  Categories.Device |
  Categories.Diluteur |
  Categories.Dough |
  Categories.EyebrowLiner |
  Categories.EyeContour |
  Categories.EyeLiner |
  Categories.Fap |
  Categories.Fixative |
  Categories.Gel |
  Categories.Hybrid |
  Categories.LiquidFoundation |
  Categories.LipPencil |
  Categories.Lotion |
  Categories.Mascara |
  Categories.Mask |
  Categories.MilkyLotion |
  Categories.Mousse |
  Categories.Oil |
  Categories.Oxidizing |
  Categories.Powder |
  Categories.Primer |
  Categories.Ral |
  Categories.Reducing |
  Categories.Scrub |
  Categories.Serum |
  Categories.Shampoo |
  Categories.SheetMask |
  Categories.Spray |
  Categories.Thickener |
  Categories.TopCoat |
  Categories.Wax;

/*************** VOLUNTEER ***************/

export class Volunteers {
  id?: string;
  firstName?: string;
  lastName?: string;
  nameFirstName?: string;
  name: string;
  email?: string;
  formula?: EvaluationFormula[];
  key?: string;
  isActive?: boolean;
  progress?: number;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.nameFirstName = '';
    this.name = '';
    this.email = '';
    this.formula = [];
    this.isActive = true;
  }
}

/*************** WORKFLOW ***************/

export class Graph {
  id?: string;
  type: GraphType;
  descriptors: Descriptor[];
  sensation?: string;
  descriptorsGroupIds?: DescriptorGroup[];
  name: Translatable;
  settings: ChartSettings;
  characterizations: AttributeData[];
  toCapture?: boolean;
  noodlesGalenic?: string;
  evaluationType?: string;
  hub?: Hub;

  constructor() {
    this.type = new GraphType();
    this.descriptors = [];
  }
}

export class DescriptorGroup {
  name: Translatable;
  id: string;
  descriptors?: Descriptor[];
  descriptorGroup?: DescriptorGroup[];
  isGroup?: boolean;
  calculationMethod?: string;
  descriptorsGroupIds?: DescriptorGroup[];

  constructor(id: string) {
    this.id = id;
    this.name = new Translatable('');
    this.descriptors = [];
    this.descriptorGroup = [];
    this.descriptorsGroupIds = [];
  }
}

export class Descriptor {
  blockName: Translatable;
  blockSuffix?: string;
  idBlock: string;
  idInBlock: string;
  idInQuestionnaire: string;
  index?: number;
  isActive?: boolean;
  name: Translatable;
  workflowName?: Translatable;
  type?: string;
  workflowId?: string;
  campaignName?: string;
  campaignId?: string;
  isGroup?: boolean;
  scale?: string;
  scaleDetail?: string;
  pertinance?: number;
  calculationMethod?: string;
  listDescriptors?: Descriptor[];
  confortValues?: any;

  constructor(
    blockName: Translatable,
    idBlock: string,
    idInBlock: string,
    idInQuestionnaire: string,
    name: Translatable
  ) {
    this.blockName = blockName;
    this.idBlock = idBlock;
    this.idInBlock = idInBlock;
    this.idInQuestionnaire = idInQuestionnaire;
    this.isActive = true;
    this.name = name;
  }
}

export enum CalculationMethod {
  Mediane = 'MEDIAN',
  Somme = 'SOMME'
}

export class GraphType {
  id: string;
  name: string;
  index: number;
  translations: Translatable;

  constructor() {
    this.id = 'RawDataTable';
    this.name = 'CHARTS.RAW_DATA_TABLE';
    this.index = 0;
    this.translations = new Translatable();
  }
}

export interface WorkflowPBI {
  name: Translatable;
  id: string;
  idCampaign: string[];
  nameCampaign: string[];
  nameCampaignString?: string;
  dataVizualisation: DataVizualisation;
}

export class Workflow implements DNAObject {
  id?: string;
  name: Translatable;
  blocks?: Block[];
  createdBy?: {
    user: string;
  };
  datalakeStatus: boolean;
  dataVizualisation: DataVizualisation;
  created_on: number;
  updated_on: number;
  description?: Translatable;
  graphs: Graph[];
  hub?: Hub;
  mode?: string;
  notificationMode: NotificationsMode;
  parameters?: WorkflowParameter;
  project?: WorkflowProject;
  startDate?: any;
  endDate?: any;
  state: State;
  timeStart?: any;
  timeEnd?: any;
  totalDelay: any;
  workspaces?: string[];
  isChangedActivecomponent?: boolean;
  _etag?: string;

  constructor(
    name: Translatable = new Translatable(),
    description: Translatable = new Translatable(),
    hub: Hub = Hubs.France,
  ) {
    this.name = name;
    this.description = description;
    this.hub = hub;
    this.dataVizualisation = new DataVizualisation();
  }
}

export interface ActiviewWorkflow {
  method: string;
  study: string;
}

export class Pager {
  currentPage: number;
  previousPage?: number;
  pages: number[];
  pageSize: number;
  startIndex: number;
  endIndex: number;
  totalItems: number;
  totalPage: number;

  constructor() {
    this.currentPage = 1;
    this.pageSize = 10;
  }
}

export class Question {
  answer?: {
    keys: any[];
    values: any[];
  };
  idInBlock?: string;
  idInQuestionnaire: string;
  libelle: string;

  constructor(libelle: string = '', idInQuestionnaire: string = '', idInBlock?) {
    this.answer = {
      keys: [],
      values: []
    };

    if (idInBlock) {
      this.idInBlock = idInBlock;
    }
    this.idInQuestionnaire = idInQuestionnaire;
    this.libelle = libelle;
  }
}

export interface WorkflowItem extends Workflow {
  idCampaign: string;
  idWorkflowOrigin: string;
  mode: string;
  parametersWorkflow: {
    mode: string,
    startDate: number[],
    endDate: number[],
    totalDelay: number
  };
  project?: ActiviewWorkflow;
}

export interface WorkflowMetadata {
  blocks: Block[];
}

export class WorkflowParameter {
  pages?: number[];
}

export class WorkflowProject {
  method?: string;
  study: string;
}

/*************** WORKSPACE ***************/

export class Metier {
  id: string;
  name: TypeMetier;

  constructor(name: TypeMetier) {
    switch (name) {
      case Metiers.Hair:
        this.id = 'hair';
        this.name = name;
        break;
      case Metiers.Skin:
        this.id = 'skin';
        this.name = name;
        break;
    }
  }
}

export class WorkspaceList {
  list: Workspace[];
}
export class Workspace {
  id?: string;
  name: string;
  administrators: any[];
  preferences: Preference;
  created_on?: number;
  updated_on?: number;
  newcomers?: Newcomer[];
  pbiWorkspaces?: any[];
  metier?: Metier;
  users: any[];
  chartsSettings: ChartSettings[];
  chartsPreferences: ChartPreferences;

  constructor() {
    this.name = '';
    this.administrators = [];
    this.preferences = new Preference();
    this.users = [];
    this.chartsPreferences = new ChartPreferences();
  }
}

export class Newcomer {
  adminKey?: string;
  email: string;
}

export class GraphPreferences {
  BarTwoSided: boolean;
  BulletScale3: boolean;
  BulletScale5: boolean;
  ColorationTable: boolean;
  ColorationTableUS: boolean;
  Histogram: boolean;
  HistogramMultiAttributs: boolean;
  LineChart: boolean;
  LineChartNormalized: boolean;
  Matrix3: boolean;
  MatrixComment: boolean;
  MatrixCommentAdHoc: boolean;
  Radar: boolean;
  TableWithBar: boolean;
  Tornado: boolean;
  Sankey: boolean;
  RawDataTable: boolean;
  RawDataCountTable: boolean;
  RawDataMedianTable: boolean;

  constructor() {
    this.BarTwoSided = true;
    this.BulletScale3 = true;
    this.BulletScale5 = true;
    this.ColorationTable = true;
    this.ColorationTableUS = true;
    this.Histogram = true;
    this.HistogramMultiAttributs = true;
    this.LineChart = true;
    this.LineChartNormalized = true;
    this.Matrix3 = true;
    this.MatrixComment = true;
    this.MatrixCommentAdHoc = true;
    this.Radar = true;
    this.TableWithBar = true;
    this.Tornado = true;
    this.Sankey = true;
    this.RawDataTable = true;
    this.RawDataCountTable = true;
    this.RawDataMedianTable = true;
  }
}

export class ChartPreferences {
  show_default_language: GraphPreferenceData;
  show_attributes_blocks: GraphPreferenceData;
  show_data_label: GraphPreferenceData;
  show_column_zero: GraphPreferenceData;
  show_grey_zone: GraphPreferenceData;
  show_visibility_threshold: GraphPreferenceData;

  constructor() {
    this.show_default_language = new GraphPreferenceData('VIZUALIZATIONS.SHOW_DEFAULT_LANGUAGE', true);
    this.show_attributes_blocks = new GraphPreferenceData('VIZUALIZATIONS.SHOW_ATTRIBUTES_BLOCKS', true);
    this.show_data_label = new GraphPreferenceData('VIZUALIZATIONS.SHOW_DATA_LABELS', true);
    this.show_column_zero = new GraphPreferenceData('VIZUALIZATIONS.SHOW_COLUMN_ZERO', true);
    this.show_grey_zone = new GraphPreferenceData('VIZUALIZATIONS.SHOW_GREY_ZONE', true);
    this.show_visibility_threshold = new GraphPreferenceData('VIZUALIZATIONS.SHOW_VISIBILITY_THRESHOLD', true);
  }
}

export class ChartSettings {
  id: string;
  name: string;
  scale: MinMaxSettings;
  plotBands: MinMaxSettings;
  visibilityThreshold: number;
  axis: Axis;
  decisionRules: MinMaxValueSettings[];
  show_default_language: boolean;
  show_attributes_blocks: boolean;
  show_data_label: boolean;
  show_column_zero: boolean;
  show_grey_zone: boolean;
  show_visibility_threshold: boolean;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    this.scale = new MinMaxSettings(-6, 6);
    this.plotBands = new MinMaxSettings(-6, 6);
    this.visibilityThreshold = 1;
    this.axis = new Axis(-6, 6, -6, 6);
    this.decisionRules = [];
    this.show_default_language = true;
    this.show_attributes_blocks = true;
    this.show_data_label = true;
    this.show_column_zero = true;
    this.show_grey_zone = true;
    this.show_visibility_threshold = true;
  }
}

export class GraphPreferenceData {
  name: string;
  value: boolean;

  constructor(name: string, value: boolean) {
    this.name = name;
    this.value = value;
  }
}

export class MinMaxSettings {
  min: number;
  max: number;

  constructor(min: number, max: number) {
    this.min = min;
    this.max = max;
  }
}

export class MinMaxValueSettings {
  min: number;
  max: number;
  value: string;

  constructor(min: number, max: number, value: string) {
    this.min = min;
    this.max = max;
    this.value = value;
  }
}

export class Axis {
  x: MinMaxSettings;
  y: MinMaxSettings;

  constructor(minX: number, maxX: number, minY: number, maxY: number) {
    this.x = new MinMaxSettings(minX, maxX);
    this.y = new MinMaxSettings(minY, maxY);
  }
}

export class Preference {
  projects: ProjectPreferences;
  formulas: FormulaPreferences;
  users: UserPreferences;
  graphs: GraphPreferences;
  views: ViewPreferences;
  defaultView: ViewType;
  parameters: ParameterPreferences;
  campaignType: TypeCampaign;
  displayNonArcsVolunteers: boolean;
  filter: FilterPreferences;
  displayPanelConfort: boolean;
  characterizations: { [hub: string]: AttributeData[] };
  displayOnePagerCharac: boolean;

  constructor() {
    this.projects = new ProjectPreferences();
    this.formulas = new FormulaPreferences();
    this.users = new UserPreferences();
    this.graphs = new GraphPreferences();
    this.views = new ViewPreferences();
    this.defaultView = ViewTypes.ModeCard;
    this.parameters = new ParameterPreferences();
    this.campaignType = new TypeCampaign('CAMPAIGN');
    this.displayNonArcsVolunteers = false;
    this.filter = new FilterPreferences();
    this.displayPanelConfort = false;
    this.characterizations = {};
    this.displayOnePagerCharac = true;
  }
}

export class FilterPreferences {
  studyType: TypeCampaign;

  constructor() {
    this.studyType = new TypeCampaign('FILTER');
  }
}

export class PreferenceTrad {
  projects: ProjectPreferencesTrad;
  formulas: FormulaPreferencesTrad;
  users: UserPreferencesTrad;
  views: ViewPreferencesTrad;
  parameters: ParameterPreferencesTrad;

  constructor() {
    this.projects = new ProjectPreferencesTrad();
    this.formulas = new FormulaPreferencesTrad();
    this.users = new UserPreferencesTrad();
    this.views = new ViewPreferencesTrad();
    this.parameters = new ParameterPreferencesTrad();
  }
}


export class PreferenceName {
  name: string;
  viewType?: ViewType;

  constructor(name: string, viewType?: ViewType) {
    this.name = name;
    this.viewType = viewType;
  }
}


export class ProjectPreferencesTrad {
  actiview: PreferenceName;
  bridge: PreferenceName;
  arcs: PreferenceName;

  constructor() {
    this.actiview = new PreferenceName('PROJECTS.PROJECT_NUMBER');
    this.bridge = new PreferenceName('PROJECTS.PROJECT_BRIDGE');
    this.arcs = new PreferenceName('PROJECTS.PROJECT_ARCS_STUDY');
  }
}

export class ProjectPreferences {
  actiview: PreferenceData;
  bridge: PreferenceData;
  arcs: PreferenceData;

  constructor() {
    this.actiview = new PreferenceData(true);
    this.bridge = new PreferenceData(true);
    this.arcs = new PreferenceData(true);
  }
}

export class PreferenceData {
  isActive: boolean;
  defaultValue?: boolean;
  selectedValue?: KeyValue;

  constructor(isActive: boolean, defaultValue?: boolean, selectedValue?: KeyValue) {
    this.isActive = isActive;
    if (defaultValue !== undefined) {
      this.defaultValue = defaultValue;
    }
    this.selectedValue = selectedValue;
  }
}

export class FormulaPreferencesTrad {
  routine: PreferenceName;
  applicationZone: PreferenceName;
  simultaneousComparison: PreferenceName;

  constructor() {
    this.routine = new PreferenceName('ROUTINE');
    this.applicationZone = new PreferenceName('PROTOCOL_APPLICATION_AREAS');
    this.simultaneousComparison = new PreferenceName('SIMULTANEOUS_COMPARISON');
  }
}

export class FormulaPreferences {
  routine: PreferenceData;
  applicationZone: PreferenceData;

  constructor() {
    this.routine = new PreferenceData(true);
    this.applicationZone = new PreferenceData(true, undefined, ApplicationsZones[0]);
  }
}

export class UserPreferencesTrad {
  volunteers: PreferenceName;
  collaborative: PreferenceName;

  constructor() {
    this.volunteers = new PreferenceName('VOLUNTEERS');
    this.collaborative = new PreferenceName('COLLABORATIVE');
  }
}

export class UserPreferences {
  volunteers: PreferenceData;
  collaborative: PreferenceData;

  constructor() {
    this.volunteers = new PreferenceData(true, false);
    this.collaborative = new PreferenceData(true, false);
  }
}

export class ViewPreferencesTrad {
  cardMode: PreferenceName;
  dualMode: PreferenceName;
  listMode: PreferenceName;
  customizeMode: PreferenceName;

  constructor() {
    this.cardMode = new PreferenceName('CARD_MODE', ViewTypes.ModeCard);
    this.dualMode = new PreferenceName('DUAL_MODE', ViewTypes.ModeDualCard);
    this.listMode = new PreferenceName('LIST_MODE', ViewTypes.ModeList);
    this.customizeMode = new PreferenceName('CUSTOMIZE_MODE', ViewTypes.ModeCustomizeCard);
  }
}

export class ViewPreferences {
  cardMode: PreferenceData;
  dualMode: PreferenceData;
  listMode: PreferenceData;
  customizeMode: PreferenceData;

  constructor() {
    this.cardMode = new PreferenceData(true);
    this.dualMode = new PreferenceData(true);
    this.listMode = new PreferenceData(true);
    this.customizeMode = new PreferenceData(true);
  }
}

export class ParameterPreferencesTrad {
  attribution: PreferenceName;
  editSubmitted: PreferenceName;
  returnButton: PreferenceName;
  blocksName: PreferenceName;
  notificationPush: PreferenceName;
  notificationRecall: PreferenceName;
  feedback: PreferenceName;
  notificationDelay: PreferenceName;
  pdfReportUserByCol: PreferenceName;
  displayAssignmentAfterPublish: PreferenceName;

  constructor() {
    this.editSubmitted = new PreferenceName('EDIT_SUBMITTED');
    this.returnButton = new PreferenceName('RETURN_BUTTON');
    this.blocksName = new PreferenceName('DISPLAY_BLOCK_NAME');
    this.feedback = new PreferenceName('DISPLAY_FEEDBACK');
    this.notificationPush = new PreferenceName('PUSH_NOTIFICATION');
    this.notificationRecall = new PreferenceName('NOTIFICATION_RECALL');
    this.notificationDelay = new PreferenceName('DELAY_NOTIFICATIONS');
    this.pdfReportUserByCol = new PreferenceName('PDF_REPORT_USER_BY_COL');
    this.displayAssignmentAfterPublish = new PreferenceName('ALLOW_ASSIGN_AFTER_PUBLISH');
  }
}

export class ParameterPreferences {
  attribution: PreferenceData;
  editSubmitted: PreferenceData;
  returnButton: PreferenceData;
  blocksName: PreferenceData;
  notificationPush: PreferenceData;
  notificationRecall: PreferenceData;
  feedback: PreferenceData;
  pdfReportUserByCol: PreferenceData;
  notificationDelay: {
    value: number
  };

  constructor() {
    this.editSubmitted = new PreferenceData(true, true);
    this.returnButton = new PreferenceData(true, true);
    this.blocksName = new PreferenceData(true, false);
    this.notificationPush = new PreferenceData(true, false);
    this.notificationRecall = new PreferenceData(true, false);
    this.feedback = new PreferenceData(true, false);
    this.pdfReportUserByCol = new PreferenceData(true, false);
    this.notificationDelay = {
      value: 5
    };
  }
}

export enum AnalyseTypes {
  comparison = 'CAMPAIGNS_COMPARISON',
  comparisonBareSkin = 'CAMPAIGNS_COMPARISON_BARE_SKIN',
  concatenation = 'CAMPAIGNS_CONCATENATION'
}

export type AnalyseType = AnalyseTypes.comparison | AnalyseTypes.concatenation | AnalyseTypes.comparisonBareSkin;

export class CampaignsMultiProtocol {
  studies: ProtocolMulti[];
  name: string;
}

export class ProtocolMulti {
  id: string;
  name: string;
  protocol: Protocol;
}

export const ProtocolTraduction = {
  applicationAreas: {
    id: 'applicationAreas',
    name: 'PROTOCOL_APPLICATION_AREAS',
    type: 'array'
  },
  applicationOrder: {
    id: 'applicationOrder',
    name: 'PROTOCOL_APPLICATION_ORDER',
    type: 'string'
  },
  applicationType: {
    id: 'applicationType',
    name: 'PROTOCOL_APPLICATION_TYPES',
    type: 'string'
  },
  controlLock: {
    id: 'controlLock',
    name: 'PROTOCOL_CONTROL_LOCK',
    type: 'string'
  },
  hairSampling: {
    id: 'hairSampling',
    name: 'HAIR_SAMPLING',
    type: 'string'
  },
  methods: {
    id: 'methods',
    name: 'METHOD',
    type: 'array'
  },
  method: {
    id: 'method',
    name: 'METHOD',
    type: 'string'
  },
  protocol_methods: {
    id: 'protocol_methods',
    name: 'PROTOCOL_METHODS',
    type: 'array'
  },
  scales: {
    id: 'scales',
    name: 'PROTOCOL_SCALE_TYPES',
    type: 'array'
  },
  simultaneousApplication: {
    id: 'simultaneousApplication',
    name: 'PROTOCOL_SIMULTANEOUS_APPLICATION',
    type: 'string'
  },
  studies: {
    id: 'studies',
    name: 'PROTOCOL_PRODUCTS_TYPES',
    type: 'array'
  },
  studyType: {
    id: 'studyType',
    name: 'PROTOCOL_STUDY_TYPE',
    type: 'string'
  },
  substrate: {
    id: 'substrate',
    name: 'PROTOCOL_SUBSTRATE',
    type: 'string'
  },
  test: {
    id: 'test',
    name: 'PROTOCOL_TEST_TYPE',
    type: 'string'
  },
  timepoints: {
    id: 'timepoints',
    name: 'NUMBER_TIMEPOINTS',
    type: 'string'
  },
  timepointsInterval: {
    id: 'timepointsInterval',
    name: 'PROTOCOL_TIMEPOINT_INTERVAL',
    type: 'array'
  },
  applicators: {
    id: 'applicators',
    name: 'PROTOCOL_APPLICATION_TYPES',
    type: 'array'
  },
  description: {
    id: 'description',
    name: 'DESCRIPTION',
    type: 'string'
  },
  estimatedContribution: {
    id: 'estimatedContribution',
    name: 'ESTIMATED_CONTRIBUTION',
    type: 'string'
  },
  researchAxes: {
    id: 'researchAxes',
    name: 'RESEARCH_AXES',
    type: 'object'
  }
};

export class TargetMulti {
  id: string;
  name: string;
  target: Target;
  isRoutine?: boolean;
  listFormulas: GroupedFormula[];
  routines: Routine2[];
}

export class Filter {
  blocks: {
    states: string[],
    displayFavoritesOnly: boolean,
    displayTestsOnly: boolean,
    name: string,
    pageIndex: number,
    numberOfObjectsPerPage: number,
    orderBy: string,
    order: string
  };
  campaignMulti: FilterMultiCampaign;
  components: {
    displayFavoritesOnly: boolean,
    searchText: string,
  };
  dashboard: {
    areMyStudies: boolean,
    displayDemandsNotLinked: boolean,
    hubs: Hubs[],
    name: string,
    startDate: number,
    endDate: number,
    states: string[],
    studyType: TypeCampaign[],
    typeEvaluation: TypeEvaluation[],
    users: string[],
  };
  panelists: {
    page: number,
    limit: number,
    hub: Hubs[],
    fiche: string,
    startDate: number,
    endDate: number,
    orchestra: string,
    requestNumber: string,
    panelistNumber: string,
    orderBy: string;
    order: string;
  };
  schedules: FilterSchedule;
  trainings: {
    states: string[],
    displayFavoritesOnly: boolean,
    name: string,
    pageIndex: number,
    numberOfObjectsPerPage: number
  };
  feedbacks: {
    pageIndex: number,
    numberOfObjectsPerPage: number
  };
  translations: {
    pageIndex: number,
    numberOfObjectsPerPage: number,
  };
  workflows: {
    displayFavoritesOnly: boolean,
    displayTestsOnly: boolean,
    hubs: Hubs[],
    name: string,
    numberOfObjectsPerPage: number,
    pageIndex: number,
    states: string[],
    orderBy: string,
    order: string
  };
}

export class FilterMultiCampaign {
  formulas: any[];
  workflows: any[];
  users: any[];
  name: string;
  hubs: Hubs[];
  startDate: number;
  endDate: number;
  page: number;
  limit: number;
  orderBy: string;
  order: string;
}
export class FilterSchedule {
  byUser: boolean;
  track: string;
  formulas: any[];
  workflows: any[];
  users: any[];
  displayFavoritesOnly: boolean;
  displayTemplate: boolean;
  hubs: Hubs[];
  name: string;
  numberOfObjectsPerPage?: number;
  pageIndex?: number;
  startDate: Date;
  endDate: number;
  states: string[];
  studyType: TypeCampaign[];
  typeEvaluation: TypeEvaluation[];
  protocol: {
    applicationAreas: KeyValue[]
    applicationModes: KeyValue[]
    applicationTypes: KeyValue[]
    protocol_methods: KeyValue[]
    methods: KeyValue[]
    substrates: KeyValue[]
    scales: KeyValue[]
    studies: KeyValue[]
    studyTypes: KeyValue[]
    tests: KeyValue[]
    timepoints: string
    timepointsInterval: string
  };
  target: {
    abondanceCheveux: KeyValue[]
    alopecieFemme: KeyValue[]
    alopecieHomme: KeyValue[]
    baseNaturelle: KeyValue[]
    casse: KeyValue[]
    categorieNuance: KeyValue[]
    cheveuxBlancs: KeyValue[]
    cheveuxBlancsPourcentDevant: KeyValue[]
    cheveuxBlancsPourcentNuque: KeyValue[]
    cheveuxNaturels: KeyValue[]
    cosmetiqueEquilibre: KeyValue[]
    degreFrisure: KeyValue[]
    durete: KeyValue[]
    frisottis: KeyValue[]
    grosseur: KeyValue[]
    hauteurTonColoration: KeyValue[]
    hommePortBarbe: KeyValue[]
    length: KeyValue[]
    natureCheveux: KeyValue[]
    natureCuirChevelu: KeyValue[]
    regulariteFrisure: KeyValue[]
    sensibilisation: KeyValue[]
    tinctorialEquilibre: KeyValue[]
    cheeksBlemish: KeyValue[]
    cheeksRedness: KeyValue[]
    dilatedPores: KeyValue[]
    eyelashesCurve: KeyValue[]
    eyelashesDensity: KeyValue[]
    eyelashesLength: KeyValue[]
    facialBrightness: KeyValue[]
    facialSkin: KeyValue[]
    lipsSize: KeyValue[]
    lipsThickness: KeyValue[]
    phototype: KeyValue[]
    skinTone: KeyValue[]
    skinType: KeyValue[]
    upperEyelidsRelaxation: KeyValue[]
    upperEyelidsSurfaceArea: KeyValue[]
    wrinklesUnderEye: KeyValue[],
    darkCircles: KeyValue[]
  };
  orchestra: KeyValue[];
  bridge: KeyValue[];
  timepointsNb: number;
  timepointsName: KeyValue[];
  isExport: boolean;
  synergy: string;
  actiview: KeyValue[];
  orderBy: string;
  order: string;
  page: number;
  limit: number;
}

export class PDFTranslation {
  actiview: object;
  appendices: string;
  applicationModes: string;
  attributes: string;
  bridge: object;
  campaign: string;
  confortDem: string;
  confortPic: string;
  confortEch: string;
  confortDisc: string;
  confortCumul: string;
  confortLegendBold: string;
  confortLegendItalic: string;
  confortLegendP: string;
  confortLegendNP: string;
  confortTitle: string;
  confortEry: string;
  categories: string;
  conclusions: string;
  dryingTypes: string;
  evaluation: string;
  expert: string;
  formula: string;
  formulas: string;
  images: string;
  kpa: string;
  laboratory: string;
  manager: string;
  fieldwork: string;
  modifyOn: string;
  name: string;
  nameStudy: string;
  nameMulti: string;
  nameCombined: string;
  names: string;
  numberOf: string;
  objective: string;
  onePagerContext: string;
  onePagerDate: string;
  onePagerDateModif: string;
  onePagerRecommandation: string;
  onePagerReference: string;
  onePagerTested: string;
  onePagerVigilance: string;
  onePagerVolunteersTitle: string;
  onePagerVolunteers: string;
  orchestra: object;
  order: string;
  otherStrengths: string;
  hub: string;
  noFormula: string;
  projectName: string;
  protocol: string;
  pvalue: string;
  routine: string;
  routines: string;
  routineApplyTo: string;
  routineBatch: string;
  routineBatches: string;
  routinePauseTime: string;
  routineProductsNames: string;
  routineQuantities: string;
  routineQuantitiesByVolunteer: string;
  routineShades: string;
  routineVisit: string;
  studiedCouples: string;
  socle: string;
  steps: string;
  substrate: string;
  study: string;
  targets: string;
  timeDrying: string;
  volunteers: string;
  visit: string;
  volunteer: string;
  visitVolTitle: string;
  notStarted: string;
  workflow: string;
  confortBefAp: string;
  confortAftpT: string;
  confortAppli: string;
  confort2min: string;
  confort5min: string;
  confort7min: string;
  confort10min: string;
  confort15min: string;
  confort20min: string;
  confort30min: string;
  confort45min: string;
  confortRinc: string;
  confortShp: string;
  confortPostRel: string;
  confortDry: string;
  confortBefApEry: string;
  confortPostApEry: string;
  versusBareSkin: string;

  constructor() {
    this.actiview = { sentence: 'NUMBER_ELEMENT', type: 'Actiview' };
    this.appendices = 'APPENDICES';
    this.applicationModes = 'APPLICATION_MODES';
    this.attributes = 'ATTRIBUTES';
    this.bridge = { sentence: 'NUMBER_ELEMENT', type: 'Synergy' };
    this.campaign = 'CAMPAIGN';
    this.confortDem = 'CONFORT_NAME_DE';
    this.confortEch = 'CONFORT_NAME_ECH';
    this.confortPic = 'CONFORT_NAME_PIC';
    this.confortDisc = 'CONFORT_GLOBAL_DISCOMFORT';
    this.confortCumul = 'CONFORT_CUMUL';
    this.confortLegendBold = 'CONFORT_LEGEND_BOLD';
    this.confortLegendItalic = 'CONFORT_LEGEND_ITALIC';
    this.confortLegendP = 'CONFORT_LEGEND_P';
    this.confortLegendNP = 'CONFORT_LEGEND_NP';
    this.confortEry = 'CONFORT_ERY';
    this.confortTitle = 'CONFORT_ONEPAGER_TITLE';
    this.categories = 'CATEGORIES';
    this.conclusions = 'CONCLUSION';
    this.dryingTypes = 'DRYING_TYPES';
    this.evaluation = 'EVALUATION';
    this.expert = 'EXPERT';
    this.formula = 'FORMULA';
    this.fieldwork = 'FIELDWORK';
    this.formulas = 'FORMULAS';
    this.images = 'IMAGES';
    this.kpa = 'ONE_PAGER_KPA';
    this.laboratory = 'LABORATORY';
    this.manager = 'MANAGER';
    this.modifyOn = 'MODIFY_ON';
    this.name = 'NAME';
    this.nameStudy = 'NAME_STUDY';
    this.nameMulti = 'NAME_STUDY_MULTI';
    this.nameCombined = 'NAME_COMBINED';
    this.names = 'NAMES';
    this.numberOf = 'NUMBER_OF_ONEPAGER';
    this.objective = 'OBJECTIVES';
    this.onePagerContext = 'ONE_PAGER_CONTEXT';
    this.onePagerDate = 'ONE_PAGER_DATE';
    this.onePagerDateModif = 'DATE';
    this.onePagerRecommandation = 'ONE_PAGER_RECOMMENDATION';
    this.onePagerReference = 'ONE_PAGER_REFERENCE';
    this.onePagerTested = 'ONE_PAGER_TESTED';
    this.onePagerVigilance = 'ONE_PAGER_VIGILANCE';
    this.onePagerVolunteersTitle = 'ONE_PAGER_VOLUNTEERS_TITLE';
    this.onePagerVolunteers = 'ONE_PAGER_VOLUNTEERS';
    this.orchestra = { sentence: 'NUMBER_ELEMENT', type: 'Orchestra' };
    this.order = 'ORDER';
    this.otherStrengths = 'OTHER_STRENGTHS';
    this.hub = 'HUB';
    this.noFormula = 'NO_FORMULA';
    this.projectName = 'PROJECT_NAME';
    this.protocol = 'PROTOCOL';
    this.pvalue = 'CONFORT_PVALUE';
    this.routine = 'ROUTINE';
    this.routines = 'ROUTINES';
    this.routineApplyTo = 'ROUTINE_APPLY_TO';
    this.routineBatch = 'ROUTINE_BATCH';
    this.routineBatches = 'ROUTINE_BATCHES';
    this.routinePauseTime = 'ROUTINE_PAUSE_TIME';
    this.routineProductsNames = 'ROUTINE_PRODUCTS_NAMES';
    this.routineQuantities = 'ROUTINE_QUANTITIES';
    this.routineQuantitiesByVolunteer = 'APPLIED_QUANTITIES';
    this.routineShades = 'ROUTINE_SHADES';
    this.routineVisit = 'ROUTINE_VISIT';
    this.socle = 'SOCLE';
    this.steps = 'STEPS';
    this.study = 'STUDY';
    this.studiedCouples = 'STUDIED_COUPLES';
    this.substrate = 'VOLUNTEERS';
    this.targets = 'TARGETS';
    this.timeDrying = 'TIME_DRYING';
    this.volunteers = 'ONE_PAGER_VOLUNTEERS';
    this.visit = 'ROUTINE_VISIT';
    this.volunteer = 'VOLUNTEER';
    this.visitVolTitle = 'VISIT_OP_TITLE';
    this.notStarted = 'NOT_STARTED';
    this.workflow = 'WORKFLOW';
    this.confortBefAp = 'CONFORT_NAME_BEFAP';
    this.confortAftpT = 'CONFORT_NAME_AFTPT';
    this.confortAppli = 'CONFORT_NAME_APPLI';
    this.confort2min = 'CONFORT_NAME_DEUX_MIN';
    this.confort5min = 'CONFORT_NAME_CINQ_MIN';
    this.confort7min = 'CONFORT_NAME_SEPT_MIN';
    this.confort10min = 'CONFORT_NAME_DIX_MIN';
    this.confort15min = 'CONFORT_NAME_QUINZE_MIN';
    this.confort20min = 'CONFORT_NAME_VINGT_MIN';
    this.confort30min = 'CONFORT_NAME_TRENTE_MIN';
    this.confort45min = 'CONFORT_NAME_QUARANTECINQ_MIN';
    this.confortRinc = 'CONFORT_NAME_RINC';
    this.confortShp = 'CONFORT_NAME_AFTER_SHAMPOO';
    this.confortPostRel = 'CONFORT_NAME_POST_RELAX';
    this.confortDry = 'CONFORT_NAME_DRYING';
    this.confortBefApEry = 'CONFORT_NAME_BEFAPERY';
    this.confortPostApEry = 'CONFORT_NAME_POSTAPERY';
    this.versusBareSkin = 'VERSUS_BARE_SKIN';
  }
}

export const BlocDuplicateLocationList: KeyValue[] = [
  {
    key: '0',
    value: 'AFTER_DUPLICATED_BLOC'
  }, {
    key: '1',
    value: 'END_OF_WORKFLOW'
  }
];

export enum BLOCK_DUPLICATE {
  AFTER_DUPLICATED_BLOC = '0',
  END_OF_WORKFLOW = '1'
}

export class TranslationsFiles {
  chinese: object;
  english: object;
  french: object;
  german: object;
  japanese: object;
  portuguese: object;
  spanish: object;

  constructor() {
    this.chinese = {};
    this.english = {};
    this.french = {};
    this.german = {};
    this.japanese = {};
    this.portuguese = {};
    this.spanish = {};
  }
}

export interface Translation {
  id?: string;
  key: string;
  language: Language | string;
  value: string;
}

export class TabSide {
  idWorkflow: string;
  volunteerName: string;
  formulaName: string;
  side: number;
}

export class Preferences {
  favorite: Favorite[];
  theme: string;
  language: string;
  workspace: string;
}

export class OnePagerPreview {
  caracterisations: FicheCaracterisation[];
  routinesTab: any;
  visitsByVolunteer: VisitsByVolunteer[];
  numberVolunteers: number;
  error: string;
  confortTab: ConfortData[];
  pdfReportUserByCol: boolean;
}

export class ConfortData {
  name: string;
  test: string;
  pvalue: number | string;
}

export class OnePagerPreviewImages {
  data: string;
  id: string;
}

export const ConfortComponent = [
  {
    id: 'burnItchSting',
    name: 'CONFORT_ECHDEPIC',
    values: {
      Burn: {
        'code': 'CONFORT_CODE_ECH',
        'value': 'CONFORT_NAME_ECH'
      },
      Itch: {
        'code': 'CONFORT_CODE_DE',
        'value': 'CONFORT_NAME_DE'
      },
      Sting: {
        'code': 'CONFORT_CODE_PIC',
        'value': 'CONFORT_NAME_PIC'
      }
    }
  },
  {
    id: 'ery',
    name: 'CONFORT_ERY',
    values: {
      E: {
        'code': 'CONFORT_CODE_E',
        'value': 'CONFORT_NAME_E'
      }
    }
  }
];


export const ConfortCodes = [
  {
    id: 'BefAp',
    name: 'CONFORT_NAME_BEFAP',
    idComponent: 'burnItchSting'
  },
  {
    id: 'AftpT',
    name: 'CONFORT_NAME_AFTPT',
    idComponent: 'burnItchSting'
  },
  {
    id: 'Appli',
    name: 'CONFORT_NAME_APPLI',
    idComponent: 'burnItchSting'
  },
  {
    id: '2min',
    name: 'CONFORT_NAME_DEUX_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '5min',
    name: 'CONFORT_NAME_CINQ_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '7min',
    name: 'CONFORT_NAME_SEPT_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '10min',
    name: 'CONFORT_NAME_DIX_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '15min',
    name: 'CONFORT_NAME_QUINZE_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '20min',
    name: 'CONFORT_NAME_VINGT_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '30min',
    name: 'CONFORT_NAME_TRENTE_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: '45min',
    name: 'CONFORT_NAME_QUARANTECINQ_MIN',
    idComponent: 'burnItchSting'
  },
  {
    id: 'Rinc',
    name: 'CONFORT_NAME_RINC',
    idComponent: 'burnItchSting'
  },
  {
    id: 'Shp',
    name: 'CONFORT_NAME_AFTER_SHAMPOO',
    idComponent: 'burnItchSting'
  },
  {
    id: 'PostRel',
    name: 'CONFORT_NAME_POST_RELAX',
    idComponent: 'burnItchSting'
  },
  {
    id: 'Dry',
    name: 'CONFORT_NAME_DRYING',
    idComponent: 'burnItchSting'
  },
  {
    id: 'BefApEry',
    name: 'CONFORT_NAME_BEFAPERY',
    idComponent: 'ery'
  },
  {
    id: 'PostApEry',
    name: 'CONFORT_NAME_POSTAPERY',
    idComponent: 'ery'
  }
];

export interface __Links {
  edit: any;
  index: any;
  last: any;
  next: any;
  previous: any;
  first: any;
  self: any;
  maxPage?: number;
}

export interface Resp {
  currentPage: number;
  itemsPerPage: number;
  list: any;
  maxPage: number;
  totalItems: number;
  __links: __Links;
}

export interface JsonPatch {
  op: string;
  path: string;
  value: any;
}

export class ActiviewStudyAxe {
  code: string;
  name: {
    en: string;
    fr: string;
  };
}

export const EditorSettings = {
  height: 200,
  menubar: false,
  base_url: '/tinymce',
  suffix: '.min',
  plugins: 'lists link',
  toolbar: 'undo redo | fontselect fontsizeselect forecolor backcolor | bold italic underline  | numlist bullist | alignleft aligncenter alignright alignjustify alignnone',
  content_style: 'body {color: #676a6c; font-size: 8pt;}',
  branding: false,
  statusbar: false
};

export class Etags {
  etag?: string;
  etagQuestionnaire?: string;
  etagBlock?: string;
  elementsUpdated?: Block;
}

export class Result {
  list: any[];
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  maxPage: number;
}
export class GroupVolunteers {
  id: string;
  groupName: string;
  numberOfPanelists: number;
  panelists: VolunteerOS[];
  hub: HubById;
  studies: StudyGroupVolunteers[];
  areVolunteersAdded?: boolean;

  constructor(id) {
    this.id = id;
    this.groupName = '';
    this.numberOfPanelists = 1;
    this.panelists = [new VolunteerOS(1, '', '')];
    this.hub = HubsById.France;
    this.studies = [];
    this.areVolunteersAdded = false;
  }
}

export class VolunteerOS {
  panelistShortNumber: number;
  firstName?: string;
  lastName?: string;
  id?: string;

  constructor(panelistShortNumber, firstName, lastName) {
    this.panelistShortNumber = panelistShortNumber;
    this.firstName = firstName ? firstName.substring(0, 3) : '';
    this.lastName = lastName ? lastName.substring(0, 3) : '';
  }
}

export class ExportVolunteerOS {
  numberInc: number;
  firstName?: string;
  lastName?: string;

  constructor(panelistShortNumber, firstName, lastName) {
    this.numberInc = panelistShortNumber;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}

export class StudyGroupVolunteers {
  studyId?: string;
  studyName?: string;
  hasStartedEvals?: boolean;
}

// PHOTOS
export class Photo {
  photoData?: string;
  photoId: string;
  photoName?: string;
}

export class TimepointPhotos {
  timepointId?: string;
  timepointName?: string;
  referencesPhoto: string[];
}

export class ImagePhoto {
  imageData: ImageBlob;
  imageUUID: string;
}

export interface TimepointNameObject {
  visitName: string;
  blockName: any;
  componentName: any;
}
export interface TimepointName {
  visitName: string;
  blockName: string;
  componentName: string;
}
export interface Timepoint {
  id: string;
  name: TimepointNameObject;
}
export interface TimepointDisplay {
  id: string;
  name: string;
}
export interface TimepointPhoto {
  timepointId: string;
  timepointName: TimepointName;
  referencesPhoto: ReferencePhoto[];
}
export interface ReferencePhoto {
  photoId: string;
  photoName: string;
  isInOnePager: boolean;
}
export interface ValuesTimepoint {
  [timepointId: string]: TimepointPhoto | null;
}
export interface StudyTimepointVolunteer {
  study: StudyTimepoint;
  timepoints: Timepoint[];
  volunteers: string[];
  values: {
    [volunteerId: string]: ValuesTimepoint
  };
  randomisationVolunteers: { [volunteerId: string]: VolunteerRando };
}
export interface StudyTimepoint {
  id: string;
  name: string;
  idOnePager?: string;
  requestNumber: string;
  activityNumber: string;
}
export interface VolunteerRando {
  formulaLab: FormulaEvaluation;
  formulaBench: FormulaEvaluation;
}
export interface FormulaEvaluation {
  treatment: string;
  side: string;
  formulaName: string;
}
