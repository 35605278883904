
import {finalize, flatMap, catchError, tap, filter, map, switchMap} from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationStart,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {
  Observable, throwError, of, forkJoin,
  Subscription
} from 'rxjs';

import {
  CampaignStates,
  ViewTypes,
  Workspace,
  CampaignParameters
} from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  ModalAttributionComponent
} from './attribution/modal-attribution.component';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-campaign-parameters',
  templateUrl: './campaign-parameter.component.html',
  styleUrls: ['./campaign-parameter.component.less']
})

export class CampaignParameterComponent implements OnInit, AfterViewInit {

  _campaignOriginal: CampaignParameters;
  campaign: CampaignParameters;
  currentWorkspace: Workspace = new Workspace();
  allowAttributionAfterPublish: boolean;
  description: string;
  isDisabled: boolean;
  showSpinner: boolean;
  users: any[];
  viewTypes: typeof ViewTypes = ViewTypes;
  error: boolean = false;
  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };
  initTime = performance.now();
  isCampaignPublished : boolean;
  evaluationsId: string[] = [];
  usersFormulas : string[] = [];
  routerSubscription: Subscription;
  currentUrl: string;
  
  constructor(
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private utilService: UtilService
  ) {
      this.currentUrl = this.router.url;
      this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) { 
          if (event.url !== this.currentUrl) {
            this.modalService.dismissAll();
          }
          this.currentUrl = event.url;      
        }
      });
   }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Parameters', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.error = false;
    this.showSpinner = true;
    this.currentWorkspace = this.userService.getUser().currentWorkspace;
    this.allowAttributionAfterPublish = this.userService.isAssignmentAfterPublishPossible();
    this.route.parent.paramMap.pipe(
      flatMap((params) => this.campaignService.getCampaignParameters(params.get("idCampaign"))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      filter((campaign: CampaignParameters) => this.utilService.canActivate(campaign, this.router, this.errorManager)),
      tap((campaign: CampaignParameters) => this.campaign = campaign),
      tap(() => this.users = this.campaignService.users),
      tap((campaign: CampaignParameters) => this.utilService.addNamesToUsers(campaign.users.accountables, this.users)),
      tap((campaign: CampaignParameters) => this.isDisabled = this.utilService.isNotEditable(campaign.state)),
      tap((campaign: CampaignParameters) => this.campaignService.updateBreadCrumb(campaign.name)),
      tap(() => this._campaignOriginal = _.cloneDeep(this.campaign)),
      tap(() => this.showSpinner = false),
      tap(() => this.isCampaignPublished = this._campaignOriginal.state === CampaignStates.Published)
    ).subscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.campaign, this._campaignOriginal);
  }

  isChangedCampaign() {
    return this.utilService.isDifferent(this.campaign, this._campaignOriginal);
  }

  onCancel() {
    this.campaign = _.cloneDeep(this._campaignOriginal);
  }

  onChangeData(value: any, object: any, path: string) {
    object[path] = value;
  }

  openModalAttribution() {
    const modal = this.modalService.open(ModalAttributionComponent, this.modalOption);
    modal.componentInstance.campaign = this.campaign;
    modal.result.then((res: any) => {
      this.campaign = res.campaign;
      if(res.idObjects){
        this.evaluationsId = res.idObjects.evaluationsId;
        this.usersFormulas = res.idObjects.usersFormulas;
      }
    });
  }

  save() {
    this.showSpinner = true;
    if(this.campaign.state === CampaignStates.Published){
      if(Object.keys(this.usersFormulas).length === 0){
        this.campaignService.putCampaignParameters(this.campaign.id, this.campaign.parameters, this.campaign.users, this.evaluationsId).pipe(
          finalize(() => this.showSpinner = false))
          .subscribe(() => {
            this.dispatchEvents()
          }, err => this.errorManager.catchError(err));
      }else{
        this.campaignService.putCampaignParameters(this.campaign.id, this.campaign.parameters, this.campaign.users, this.evaluationsId ).pipe(
          switchMap(()=> this.campaignService.putCampaignEvaluationAfterPublish(this.campaign.id, this.usersFormulas )
            ),
            switchMap((evaluations:string[])=>{
              let evaluations$ = evaluations.map(evalId=>
                this.campaignService.createEvaluationAfterPublish(this.campaign.id, evalId)
              )
              return forkJoin(evaluations$)
            }),
            finalize(() => this.showSpinner = false)
          )
          .subscribe(() => {
            this.dispatchEvents()
          }, err => this.errorManager.catchError(err));
      }
    }else{
      this.campaignService.putCampaignParameters(this.campaign.id, this.campaign.parameters, this.campaign.users).pipe(
        finalize(() => this.showSpinner = false))
        .subscribe(() => {
          this.dispatchEvents()
        }, err => this.errorManager.catchError(err));
    }
  }
  dispatchEvents(){
    this.errorManager.displayMessage("ON_SUCCESS_UPDATE");
    this._campaignOriginal = _.cloneDeep(this.campaign);
    this.router.navigate(['campaigns', this.campaign.id, 'edit', 'publish']);
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
