import { environment } from './../../../../../../environments/environment';

import { mergeMap, tap, finalize, takeUntil, catchError, map } from 'rxjs/operators';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit,
  Input, AfterViewInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  Observable,
  of,
  Subject,
  throwError
} from 'rxjs';

import {
  ApplicationOrders,
  KeyValue,
  ProtocolHair,
  TypeApplicationArea,
  TypeApplicationTypes,
  TypeMethod,
  TypeScale,
  TypeSubstrate,
  ProductsType,
  TypeTest,
  TypeMetier,
  Method,
  StudyType,
  Protocol,
  CampaignProtocol,
  CampaignStates,
  ActiviewStudyAxe,
  CampaignInfosGen,
  ActiviewLang
} from '../../../../../types';
import {
  CampaignService
} from '../../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import {
  ReferenceTypeService
} from '../../../../../shared/services/reference-type.service';
import {
  UtilService
} from '../../../../../shared/services/util.service';
import { HttpRestService } from '@src/app/shared/services/httpRest.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-protocol-hair',
  templateUrl: './protocol-hair.component.html',
  styleUrls: ['./protocol-hair.component.less']
})
export class ProtocolHairComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() campaignProtocol: CampaignProtocol;
  @Input() publishedTemplate: boolean;

  protocol: Protocol;
  metierName: TypeMetier;
  applicationAreas: TypeApplicationArea[];
  applicationTypes: {
    [key: string]: KeyValue
  };
  applicationOrders: {
    [key: string]: KeyValue
  };
  protocol_methods: TypeMethod[];
  methods: Method[];
  protocolForm: FormGroup;
  originalForm: FormGroup;
  showSpinner: boolean = true;
  scales: TypeScale[];
  studies: ProductsType[];
  studyTypes: StudyType[];
  substrates: TypeSubstrate[];
  tests: TypeTest[];
  researchAxes: ActiviewStudyAxe[];
  isChangedProtocol: boolean = false;
  submitted: boolean = false;
  campaignStates: typeof CampaignStates = CampaignStates;
  isDisabled: boolean;
  initTime = performance.now();
  error: any;
  campaign: CampaignInfosGen;
  languageMap = {
    english: ActiviewLang.english,
    french: ActiviewLang.french,
    japanese: ActiviewLang.japanese,
    chinese: ActiviewLang.chinese,
    portuguese: ActiviewLang.portuguese,
    spanish: ActiviewLang.spanish
  };
  currentLanguage: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private formBuilder: FormBuilder,
    private aiService: ApplicationInsightsService,
    private referenceTypeService: ReferenceTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private httpRestService: HttpRestService
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    const idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.campaignService.getCampaignInfoGen(idCampaign).pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      map((campaignInfoGen: CampaignInfosGen) => {
        this.campaign = campaignInfoGen;
        this.applicationTypes = TypeApplicationTypes;
        this.applicationOrders = ApplicationOrders;

        if (_.isUndefined(this.campaignProtocol.protocol)) this.campaignProtocol.protocol = new ProtocolHair();

        this.isDisabled = this.utilService.isNotEditable(this.campaignProtocol.state);
        this.metierName = _.get(this.campaignProtocol, 'metier.name', undefined);
        this.campaignService.getActiviewRefs().subscribe();
        this.protocolForm = this.createProtocolForm(<ProtocolHair>this.campaignProtocol.protocol);
        this.originalForm = _.cloneDeep(this.protocolForm);

        this.getProtocolObjectsAndSetForm().pipe(
          takeUntil(this.destroy$))
          .subscribe(
            () => { },
            error => this.errorManagerService.catchError(error)
          );

        this.DNATranslate.onLangChange().pipe(
          tap(() => this.currentLanguage = this.DNATranslate.getLanguage()),
          mergeMap(() => this.getProtocolObjectsAndSetForm()),
          takeUntil(this.destroy$))
          .subscribe();

        this.onChanges();
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Protocol Hair', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  onChanges(): void {
    this.protocolForm.valueChanges.subscribe(() => {
      this.isChangedProtocol = this.utilService.isDifferent(this.protocolForm.value, this.originalForm.value) ||
        this.utilService.differentKeys(this.protocolForm.value, this.originalForm.value);
    })
  }

  compareKeyValue(c1: KeyValue, c2: KeyValue): boolean {
    return c2 ? c1.key === c2.key : false;
  }


  compareRadio(c1: KeyValue, fieldName: string) {
    let c2 = this.protocolForm.get(fieldName).value;
    return this.compareKeyValue(c1, c2);
  }

  compareCode(c1: any, c2: any): boolean {
    return c2 ? c1.code === c2.code : false;
  }

  createProtocolForm(protocol: ProtocolHair): FormGroup {
    return this.formBuilder.group({
      applicationAreas: [{ value: protocol.applicationAreas, disabled: this.publishedTemplate }],
      applicationOrder: [{ value: protocol.applicationOrder?.key, disabled: this.publishedTemplate }],
      applicationType: [{ value: protocol.applicationType?.key, disabled: this.publishedTemplate }],
      controlLock: [{ value: protocol.controlLock, disabled: this.publishedTemplate }],
      description: [{ value: protocol.description, disabled: this.publishedTemplate }],
      protocol_methods: [{ value: protocol.protocol_methods, disabled: this.publishedTemplate }],
      method: [{ value: protocol.method, disabled: this.publishedTemplate }],
      hairSampling: [{ value: protocol.hairSampling, disabled: this.publishedTemplate }],
      scales: [{ value: protocol.scales, disabled: this.publishedTemplate }],
      simultaneousApplication: [{ value: protocol.simultaneousApplication, disabled: this.publishedTemplate }],
      studies: [{ value: protocol.studies, disabled: this.publishedTemplate }],
      studyType: [
        { value: protocol.studyType ? protocol.studyType : {}, disabled: this.publishedTemplate },
        this.conditionalRequiredValidator(this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false)
      ],
      substrate: [{ value: protocol.substrate, disabled: this.publishedTemplate }],
      researchAxes: [{ value: protocol.researchAxes ? protocol.researchAxes : {}, disabled: this.publishedTemplate }],
      test: [{ value: protocol.test, disabled: this.publishedTemplate }],
      timepoints: [{ value: protocol.timepoints, disabled: this.publishedTemplate }],
      estimatedContribution: [{ value: protocol.estimatedContribution, disabled: this.isDisabled }, this.conditionalRequiredValidator(this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false)],
      timepointsInterval: this.formBuilder.array(this.initTimepointsIntervalArray(protocol.timepointsInterval))
    });
  }

  formatProtocol(protocol: any): ProtocolHair {
    protocol.timepointsInterval = protocol.timepointsInterval.map(timepoint => timepoint.value.replace(/\s{1,}/g, "").toUpperCase());
    return protocol;
  }

  private getProtocolObjectsAndSetForm() {
    return this.referenceTypeService.getProtocolMethods(this.metierName).pipe(
      tap(protocol_methods => this.protocol_methods = this.utilService.sortArrayOfKeyValueObjects(protocol_methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('protocol_methods'))),
      mergeMap(() => this.campaignService.getActiviewMethods()), // DO NOT REMOVE - PROCESSMAP
      mergeMap((methods) => this.referenceTypeService.dataTranslations(methods, 'key', 'value')),
      tap((methods: Method[]) => this.methods = this.utilService.sortArrayOfKeyValueObjects(methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('method'))),
      mergeMap(() => this.referenceTypeService.getStudies(this.metierName)),
      tap(studies => this.studies = this.utilService.sortArrayOfKeyValueObjects(studies)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('studies'))),
      mergeMap(() => this.referenceTypeService.getScales()),
      tap(scales => this.scales = this.utilService.sortArrayOfKeyValueObjects(scales)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('scales'))),
      mergeMap(() => this.referenceTypeService.getApplicationAreas(this.metierName)),
      tap(applicationAreas => this.applicationAreas = this.utilService.sortArrayOfKeyValueObjects(applicationAreas)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('applicationAreas'))),
      mergeMap(() => this.campaignService.getActiviewTypes()), // DO NOT REMOVE - PROCESSMAP,
      tap((studyTypes: StudyType[]) => this.studyTypes = studyTypes.filter((studyType) => studyType.is_active)),
      mergeMap(() => this.httpRestService.getResearchAxesData()),
      tap((researchAxes: any) => this.researchAxes = researchAxes.researchAxes),
      mergeMap(() => this.referenceTypeService.getSubstrates()),
      tap(substrates => this.substrates = this.utilService.sortArrayOfKeyValueObjects(substrates)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('substrate'))),
      mergeMap(() => this.referenceTypeService.getTests(this.metierName)),
      tap(tests => this.tests = this.utilService.sortArrayOfKeyValueObjects(tests)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('test'))),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('applicationOrder'))),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get('applicationType'))),
      tap(() => this.activiewSynchro(this.campaign)),
      tap(() => this.initActiviewData(this.campaignProtocol, this.researchAxes, this.studyTypes, this.protocolForm)),
    )
  }

  /**
   * Item 23596 - Initialiser les valeurs d'Actiview dans le protocole
   * @param campaignProtocol
   */
  initActiviewData(campaignProtocol: CampaignProtocol, researchAxes: ActiviewStudyAxe[], studyTypes: StudyType[], protocolForm: FormGroup) {
    this.initAxeResearch(campaignProtocol, researchAxes, protocolForm);
    this.initStudyTypes(campaignProtocol, studyTypes, protocolForm);
  }

  initAxeResearch(campaignProtocol: CampaignProtocol, researchAxes: ActiviewStudyAxe[] = [], protocolForm: FormGroup) {
    if (!campaignProtocol.protocol.researchAxes && _.get(campaignProtocol, 'actiview.study_research_axis_id', false)) {
      const axe = researchAxes.find(a => a.code == campaignProtocol.actiview.study_research_axis_id);
      if (axe) {
        protocolForm.controls['researchAxes'].setValue(axe);
        this.isChangedProtocol = true;
      }
    }
  }

  initStudyTypes(campaignProtocol: CampaignProtocol, studyTypes: StudyType[] = [], protocolForm: FormGroup) {
    if (!campaignProtocol.protocol.studyType && _.get(campaignProtocol, 'actiview.studyType', false)) {
      const studyType = studyTypes.find(a => a.code == campaignProtocol.actiview.studyType);
      if (studyType) {
        protocolForm.controls['studyType'].setValue(studyType);
        this.isChangedProtocol = true;
      }
    }
  }

  onCancel() {
    const originalTimepointsInterval = this.originalForm.get("timepointsInterval").value.map(v => v.value);
    this.protocolForm.controls["timepointsInterval"] = this.formBuilder.array(this.initTimepointsIntervalArray(originalTimepointsInterval));
    this.protocolForm.reset(this.originalForm.value);
    this.submitted = false;
  }

  onSubmit(form: FormGroup) {
    this.submitted = true;
    const studyTypeExists = this.studyTypes.some(studyType => studyType.code === form.get('studyType').value.code);
    const researchAxisExists = this.researchAxes.some(axe => axe.code == form.get('researchAxes').value.code);

    if (studyTypeExists) {
      const matchedStudyType = this.studyTypes.find(studyType => studyType.code === form.get('studyType').value.code);
      form.get('studyType').setValue(matchedStudyType);
    }

    if ((!studyTypeExists || (form.get('studyType').value && !form.get('studyType').value.is_active)) && this.campaign?.actiview?.activityNumber) {
      this.errorManagerService.displayMessage('ERROR_INVALID_OR_INACTIVE_STUDY_TYPE', 'danger');
      return;
    }

    if (!form.get('estimatedContribution').value && this.campaign?.actiview?.activityNumber) {
      this.errorManagerService.displayMessage('ERROR_INVALID_ESTIMATED_CONTRIBUTION', 'danger');
      return;
    }

    if (!researchAxisExists && form.get('researchAxes').value.code) {
      this.errorManagerService.displayMessage('ERROR_INVALID_RESEARCH_AXIS', 'danger');
      return;
    }


    if (!form.invalid) {
      this.save(form);
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_FORM", "danger");
    }
  }

  private save(form, sync?) {
    this.showSpinner = true;
    Object.assign(this.campaignProtocol.protocol, this.formatProtocol(_.cloneDeep(form.value)));
    (this.campaignProtocol.protocol as any).applicationOrder = Object.values(ApplicationOrders).find(order => order.key === this.protocolForm.get('applicationOrder').value?.toUpperCase()) || null;
    (this.campaignProtocol.protocol as any).applicationType = Object.values(TypeApplicationTypes).find(type => type.key === this.protocolForm.get('applicationType').value) || null

    this.campaignService.putCampaignProtocol(this.campaignProtocol.id, this.campaignProtocol.protocol).pipe(
      takeUntil(this.destroy$),
      finalize(() => {
        this.showSpinner = false;
        this.submitted = false;
      }))
      .subscribe(
        () => {
          if (!sync) {
            this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
            this.router.navigate(['campaigns', this.campaignProtocol.id, 'edit', 'workflows']);
          } else {
            this.originalForm = _.cloneDeep(this.protocolForm);
            this.showSpinner = false
            this.errorManagerService.displayMessage('ON_SYNCHRO_PROTOCOL_ACTIVIEW');
          }
        },
        error => this.errorManagerService.catchError(error)
      );
  }

  private translateAndPatchProtocolFormObject(protocolFormObject: AbstractControl): Observable<KeyValue[] | KeyValue> {
    let myArray = _.isArray(protocolFormObject.value) ? protocolFormObject.value : [protocolFormObject.value];
    return this.referenceTypeService.keyValueTranslations(myArray).pipe(
      tap(formObjectTranslatedArray => protocolFormObject.patchValue(_.isArray(protocolFormObject.value) ? formObjectTranslatedArray : formObjectTranslatedArray[0])))
  }

  /********** TIMEPOINTS INTERVAL **********/

  get timepointsIntervalArray(): FormArray {
    return this.protocolForm.get("timepointsInterval") as FormArray;
  }

  addItem(timepoints: number): void {
    if (timepoints < 10) {
      this.timepointsIntervalArray.push(this.getTimepointsIntervalFormGroup());
      this.protocolForm.patchValue({
        timepoints: timepoints + 1
      });
    }
  }

  getTimepointsIntervalFormGroup(value: string = ""): FormGroup {
    return this.formBuilder.group({
      value: [value]
    });
  }

  initTimepointsIntervalArray(timepointsInterval: string[] = []) {
    let array = [];
    if (!Array.isArray(timepointsInterval)) {
      timepointsInterval = [timepointsInterval]
    }
    timepointsInterval.forEach((timepoint: string) => {
      array.push(this.getTimepointsIntervalFormGroup(timepoint));
    });
    return array;
  }

  removeTimepointsInterval(index: number, timepoints: number) {
    this.timepointsIntervalArray.removeAt(index);
    this.protocolForm.patchValue({
      timepoints: timepoints - 1
    });
  }

  getStatus(key) {
    return this.protocolForm.controls[key].invalid && this.submitted;
  }

  conditionalRequiredValidator(condition: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (condition && (!control.value || _.isEqual(control.value, {}))) {
        return { required: true };
      }
      return null;
    };
  }

  activiewSynchro(campaign: any) {
    if (!this.shouldSyncActiview(campaign)) {
      this.showSpinner = false;
      return;
    }
  
    this.confirmSync().pipe(
      mergeMap(confirmed => confirmed ? this.fetchActiviewData(campaign.actiview.activityNumber) : of(null)),
      tap(actiview => {
        if (actiview) {
          this.updateProtocolForm(actiview);
        }
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }
  
  private shouldSyncActiview(campaign: any): boolean {
    return campaign.actiview?.activityNumber &&
      ![CampaignStates.Finished, CampaignStates.Published].includes(campaign.state);
  }
  
  private confirmSync(): Observable<boolean> {
    return this.utilService.translateMessageModal('ACTIVIEW_SYNCHRO_CONFIRMATION', '', '').pipe(
      mergeMap(modalContent => this.utilService.openActiviewSyncModal(modalContent))
    );
  }
  
  private fetchActiviewData(activityNumber: string): Observable<any> {
    return this.httpRestService.getActiview(activityNumber, true).pipe(
      catchError(error => {
        console.error('Error fetching actiview:', error);
        return of(null);
      })
    );
  }
  
  private updateProtocolForm(actiview: any): void {
    if (actiview.input?.studyType) {
      const studyType = this.studyTypes.find(el =>
        el.name.en === actiview.input.studyType || el.name.fr === actiview.input.studyType
      );
      if (studyType) {
        this.protocolForm.patchValue({ studyType });
      }
    }
  
    if (actiview.output) {
      const { estimated_contribution, methods_id } = actiview.output;
      if (estimated_contribution) {
        this.protocolForm.patchValue({ estimatedContribution: estimated_contribution });
      }
      if (methods_id) {
        this.protocolForm.patchValue({ method: methods_id });
      }
    }
  
    this.save(this.protocolForm, true);
  }
}
