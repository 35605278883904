
import {
  of as observableOf,
  Observable,
  Subject,
  throwError,
  of,
  Subscription,
} from 'rxjs';

import { filter, map, tap, takeUntil, finalize, flatMap, catchError } from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationStart,
  ParamMap,
  Router,
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
//import { TagModel } from 'ngx-chips/core/accessor';
import { TranslateService } from '@ngx-translate/core';

import {
  Campaign,
  CampaignStates,
  Formula,
  FormulaCampaign,
  GroupedFormula,
  Order,
  Routine2,
  RoutineStep,
  SimComp,
  Tag,
  Visit,
  Workspace,
  ApplicationsZones,
  KeyValue,
  APPLICATION_ZONE,
  CampaignFormulas,
  CampaignVisit,
  FormulaBridge
} from '../../../../types';
import { CampaignService } from '../../../campaigns.service';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { FormulasInsertionModalComponent } from './formulas-insertion/formulas-insertion-modal.component';
import { RoutinePreviewModalComponent } from '../../../../shared/routine-preview-modal/routine-preview-modal.component';
import { RoutinesComponent } from './routines/routines.component';
import { UserService } from '../../../../shared/services/user.service';
import { UtilService } from '../../../../shared/services/util.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { v4 } from 'uuid';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-campaign-formulas',
  templateUrl: './campaign-formula.component.html',
  styleUrls: ['./campaign-formula.component.less']
})
export class CampaignFormulaComponent implements OnInit, OnDestroy, AfterViewInit {

  private _campaignUntouched: CampaignFormulas;

  _listFormulaUntouched: Formula[];
  campaign: CampaignFormulas;
  campaignStates: typeof CampaignStates = CampaignStates;
  applicationZones: typeof ApplicationsZones = ApplicationsZones;
  currentWorkspace: Workspace = new Workspace();
  goPageRoutine: boolean = false;
  isDisabled: boolean = false;
  moreThanOneZone: boolean = false;
  numberRoutine: number = 1;
  showSpinner: boolean = false;
  simpCompActive: boolean = true;
  submitted: boolean = false;
  chosenBench: GroupedFormula;
  chosenApplicationZone: KeyValue;
  error: boolean = false;
  visitsList: {
    idVisit: string;
    visitName: string;
    visitIndex: number;
  }[];
  initTime = performance.now();
  routerSubscription: Subscription;
  currentUrl: string;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    windowClass: 'routines-modal',
  };

  modalOptionRoutinePreview: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
    keyboard: true,
    size: 'lg'
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private DNATranslate: DNATranslateService,
    private campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private utilService: UtilService,
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.currentWorkspace = this.userService.getUser().currentWorkspace;
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Formula', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.error = false;
    this.showSpinner = true;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.campaignService.getCampaignFormulas(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign) => this.isDisabled = this.utilService.isNotEditable(campaign.state)),
      map((campaign) => this.initRoutine(campaign)),
      map((campaign) => this.initFormula(campaign)),
      tap((campaign) => this.visitsList = this.buildVisitsList(campaign.visits)),
      tap((campaign) => this._listFormulaUntouched = _.cloneDeep(campaign.formula.formulas)),
      tap((campaign) => this._campaignUntouched = _.cloneDeep(campaign)),
      tap((campaign) => this.numberRoutine = campaign.formula.routines.length),
      tap((campaign) => this.campaign = _.cloneDeep(campaign)),
      tap((campaign) => this.campaignService.updateBreadCrumb(campaign.name)),
      tap(() => this.chosenApplicationZone = this.getApplicationZone(this.campaign, this.currentWorkspace)),
      tap(() => this.chosenBench = this.setChosenBench()),
      tap(() => this.showSpinner = false),
      takeUntil(this.destroy$)
    )
      .subscribe();

  }

  buildVisitsList(visits: CampaignVisit[]) {
    if(visits) {
      return visits.map(visit => {
        return {
          idVisit: visit.id,
          visitName: visit.name,
          visitIndex: visit.index
        }
      })
    } else return [];
  }

  getApplicationZone(campaign: CampaignFormulas, workspace: Workspace): KeyValue {
    if (_.get(campaign, 'formula.applicationZone')) {
      return _.get(campaign, 'formula.applicationZone');
    }
    else {
      let chosenApplicationZone = _.get(workspace, 'preferences.formulas.applicationZone.selectedValue', ApplicationsZones[0])
      this.onApplicationZoneChange(chosenApplicationZone);
      return chosenApplicationZone;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.goPageRoutine) {
      this.goPageRoutine = false
      return true;
    }
    return this.utilService.canDeactivate(this.campaign, this._campaignUntouched);
  }

  checkIsActive(couples: SimComp[], isActive: boolean): boolean {
    if (!isActive || !couples || couples.length === 0) return true;
    for (let s of couples) {
      if (s.isActive) return true;
    }
    return false;
  }

  /**
   * Create new routine based on model routine
   * @param campaign
   * @param index
   */
  createRoutine(campaign: CampaignFormulas, index: number): Routine2 {
    let newRoutine = _.get(campaign, 'metier.id', false) ? new Routine2(index, campaign.metier.name) : new Routine2(index);

    // Si une routine de base existe, cloner ses visites avec des champs vides
    let baseRoutine = campaign.formula.routines.length > 0 ? campaign.formula.routines[campaign.formula.routines.length - 1] : null;
    if (baseRoutine) {
        newRoutine.visits = _.cloneDeep(baseRoutine.visits);
    } else {
        // Si aucune routine de base n'existe, utiliser les visites de la campagne
        newRoutine.visits = _.cloneDeep(campaign.visits);
    }

    for (let indexVisit = 0; indexVisit < newRoutine.visits.length; indexVisit++) {
      let visit = newRoutine.visits[indexVisit];
      visit.orders = visit.orders || [];

      for (let indexOrder = 0; indexOrder < visit.orders.length; indexOrder++) {
          let order = new Order();
          order.evaluation = visit.orders[indexOrder].evaluation || '';
          order.name = visit.orders[indexOrder].name || '';
          order.shade = visit.orders[indexOrder].shade || '';

          // on map les steps de la routine dans le cas où il y a plusieurs 'Formule ou mélange' dans une step
          order.steps = visit.orders[indexOrder].steps.map(step => {
              let routineStep = new RoutineStep(step.name, campaign.metier.name);
              routineStep.formula = new FormulaBridge();

              // Réinitialiser les champs de la formule à des valeurs vides
              routineStep.formula.formulaName = routineStep.formula.formulaName || '';
              routineStep.formula.lot = routineStep.formula.lot || '';
              routineStep.formula.productName = routineStep.formula.productName || '';
              routineStep.formula.quantity = routineStep.formula.quantity || 0;

              return routineStep;
          });

          newRoutine.visits[indexVisit].orders[indexOrder] = order;
      }
    }
    return newRoutine;
  }


  formulasNotInCampaign(campaign: Campaign, formulas: Formula[]): Formula[] {
    const formulasNotInCampaign = formulas.reduce(
      (result: Formula[], formula: Formula) => {
        if (!campaign.formula.formulas.find((formulaInCampaign: Formula) => formulaInCampaign.name === formula.name)) {
          result.push(formula);
        }
        return result;
      },
      []
    );
    return _.uniqBy(formulasNotInCampaign, 'name');
  }

  private setChosenBench() {
    return !_.isUndefined(_.get(this.campaign, 'formula.chosenBench'))
      ? this.campaign.formula.listFormulas.find(f => f.name === this.campaign.formula.chosenBench.name)
      : _.get(this.campaign, 'formula.listFormulas[0]')
  }

  goToRoutines(campaign: CampaignFormulas) {
    if (_.isEmpty(_.get(campaign, 'formula.formulas', []))) {
      this.translateService.get('ADD_FORMULA_FOR_ROUTINE').pipe(
        map(message => this.errorManager.displayMessage(message as string, 'danger')),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.openRoutineModal(campaign);
    }
  }

  initChosenBench(): GroupedFormula {
    this.chosenBench = this.setChosenBench();
    return this.chosenBench;
  }

  initFormula(campaign: CampaignFormulas): CampaignFormulas {
    if (!campaign.formula.formulas) {
      campaign.formula.formulas = [];
      campaign.formula.formulas = _.uniqBy(
        _.flatten(campaign.formula.listFormulas.map((f: GroupedFormula) => f.formula)), 'id'
      );
    }

    return campaign;
  }

  initRoutine(campaign: CampaignFormulas): CampaignFormulas {
    if(_.isEmpty(_.get(campaign, 'formula.routines', []))) {
        let newRoutine = _.has(campaign, 'metier.id') ? new Routine2(1, campaign.metier.name) : new Routine2(1);
        newRoutine.visits = _.cloneDeep(campaign.visits); // Copiez toutes les visites de la campagne
        _.get(campaign, 'formula.routines', []).push(newRoutine);
    }
    this.numberRoutine = campaign.formula.routines.length;

    return campaign;
}


  isChangedCampaign(campaign: CampaignFormulas) {
    return this.utilService.isDifferent(campaign, this._campaignUntouched);
  }

  onCancel() {
    this.campaign = _.cloneDeep(this._campaignUntouched);
    this.campaign.formula.formulas = _.cloneDeep(this._listFormulaUntouched);
    this.chosenApplicationZone = _.cloneDeep(this.campaign.formula.applicationZone);
    this.chosenBench = _.cloneDeep(this.campaign.formula.chosenBench);
    this.numberRoutine = this.campaign.formula.routines.length;
  }

  onChangeAnonymous(formula: FormulaCampaign, isAnonymous: boolean) {
    formula.anonymousFormula.isAnonymous = isAnonymous;
    if (isAnonymous) {
      const listFormula = formula.listFormulas;
      formula.anonymousFormula.anonymousCode = this.updateAnonymousFormula(listFormula, formula.anonymousFormula.anonymousCode)

    }
  }

  /**
   * Enlève dans un premier temps les formules/routines non utilisées dans le tableau des codes anonymes
   * Insère les nouvelles formules/routines dans un second temps avec un nouveau code
   * @param listFormula
   * @param anonymousCode
   */
  updateAnonymousFormula(listFormula: GroupedFormula[], anonymousCode: object): object {
    Object.keys(anonymousCode).forEach(key => {
      if (!listFormula.find(form => form.name == key)) {
        _.unset(anonymousCode, key);
      }
    });
    listFormula.forEach(form => {
      if (!anonymousCode[form.name]) {
        anonymousCode[form.name] = this.generateAnonymousCode().toString();
      }
    });
    return anonymousCode;
  }

  generateAnonymousCode(): number {
    return Math.floor(Math.random() * (999 - 100 + 1)) + 100;
  }

  onChangeNumberRoutine(campaign: CampaignFormulas): CampaignFormulas {
    if (!_.isNull(this.numberRoutine) && this.numberRoutine > 0) {
      let newRoutines = [], routine;
      for (let i = 0; i < this.numberRoutine; i++) {
        routine = _.cloneDeep(campaign.formula.routines[i]);
        newRoutines.push(routine || this.createRoutine(campaign, i + 1));
      }
      campaign.formula.routines = newRoutines;
      campaign = this.updateListFormulaForRoutine(_.cloneDeep(campaign));
      if (this.numberRoutine > 1) this.chosenBench = _.get(campaign, 'formula.listFormulas').find(elt => elt.name === "R2");
      return this.updateCouplesTable(campaign, _.cloneDeep(campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench);
    }
    return campaign;
  }

  onChangeSimultaneous(campaign: CampaignFormulas, chosenApplicationZone: KeyValue, chosenBench: GroupedFormula): CampaignFormulas {
    campaign.formula.simultaneousComparison.isActive = chosenApplicationZone.key !== APPLICATION_ZONE.ONE_ZONE;
    return chosenApplicationZone.key !== APPLICATION_ZONE.ONE_ZONE ? this.updateCouplesTable(campaign, _.cloneDeep(campaign.formula.simultaneousComparison.couples), chosenApplicationZone, chosenBench) : campaign;
  }

  onApplicationZoneChange(chosenApplicationZone) {
    this.campaign.formula.applicationZone = chosenApplicationZone;
    this.onChangeSimultaneous(this.campaign, chosenApplicationZone, this.chosenBench);
    if (chosenApplicationZone.key === APPLICATION_ZONE.THREE_ZONES) {
      this.numberRoutine = 3;
      this.campaign = this.onChangeNumberRoutine(this.campaign);
    }
  }

  onChosenBenchChange(event) {
    this.chosenBench = this.campaign.formula.listFormulas.find(f => f.name === event.name);
    this.updateCouplesTable(this.campaign, _.cloneDeep(this.campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench);
  }

  compare(a: KeyValue, b: KeyValue): boolean {
    if (!b) return false;
    return a.key === b.key;
  }

  compareBench(a: GroupedFormula, b: GroupedFormula): boolean {
    if (!b) return false;
    return a.name === b.name;
  }

  onRoutineChange(campaign: CampaignFormulas, isRoutine: boolean): CampaignFormulas {
    campaign.parameters.isRoutine = isRoutine;
    campaign = this.updateListFormulaForRoutine(_.cloneDeep(campaign));
    if(isRoutine && _.isEmpty(_.get(campaign, 'visits', []))) {
      this.addVisitInCampaignAndRoutines(campaign);
    }
    if (_.get(campaign, 'formula.simultaneousComparison.isActive', false)) {
      this.chosenBench = _.get(campaign, 'formula.listFormulas[0]');
      if (isRoutine) {
        return this.numberRoutine > 1
          ? this.updateCouplesTable(campaign, _.cloneDeep(campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench)
          : campaign;
      } else {
        return this.updateCouplesTable(campaign, _.cloneDeep(campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench);
      }
    } else {
      return campaign;
    }
  }

  onSimCompChange(campaign: CampaignFormulas, simComp: SimComp[]): CampaignFormulas {
    campaign.formula.simultaneousComparison.couples = _.cloneDeep(simComp);
    return campaign;
  }

  openModal(campaign: Campaign) {
    const modal = this.modalService.open(FormulasInsertionModalComponent, this.modalOption);
    modal.result.then(
      (formulasArray: Formula[]) => {
        let formulasNotInCampaign = this.formulasNotInCampaign(campaign, formulasArray);
        campaign.formula.formulas = campaign.formula.formulas.concat(formulasNotInCampaign);
        this.campaign = formulasNotInCampaign.reduce(
          (acc: Campaign, curr: Formula) => this.addFormulaToList(acc, curr), _.cloneDeep(campaign)
        );
      },
      (reason) => { }
    );
  }

  openModalPreviewRoutines(campaign: Campaign) {
    if (_.isEmpty(_.get(campaign, 'formula.formulas'))) {
      this.translateService.get('NO_ROUTINES_TO_DISPLAY').pipe(
        map(message => this.errorManager.displayMessage(message as string, 'danger')),
        takeUntil(this.destroy$))
        .subscribe();
    } else {
      const modalRoutinePreview = this.modalService.open(RoutinePreviewModalComponent, this.modalOptionRoutinePreview);
      modalRoutinePreview.componentInstance.campaign = campaign;
    }
  }

  openRoutineModal(campaign: CampaignFormulas) {
    const modal = this.modalService.open(RoutinesComponent, this.modalOption);
    modal.componentInstance.visitsList = this.visitsList;
    modal.componentInstance.campaign = _.cloneDeep(campaign);
    modal.result.then(
      (campaign: CampaignFormulas) => this.campaign = campaign,
      (reason) => { }
    );
  }

  reset() {
    this.campaign.formula.formulas = [];
    this.campaign.formula.listFormulas = [];
  }

  updateListofIds(campaign: CampaignFormulas): CampaignFormulas {
    this.updateListFormulaForRoutine(campaign);
    if(_.get(campaign,'formula.simultaneousComparison.isActive', false)) {
      if (this.chosenBench && this.chosenBench.name) {
        this.chosenBench = campaign.formula.listFormulas.find(f => f.name === this.chosenBench.name);
        campaign.formula.chosenBench = this.chosenBench;
      }
      _.get(campaign, 'formula.simultaneousComparison.couples', []).map(couple => {
        couple.bench = campaign.formula.listFormulas.find(f => f.name === couple.bench.name);
        couple.lab = couple.lab.map(l => campaign.formula.listFormulas.find(f => f.name === l.name));
        couple.formula = couple.formula.map(l => campaign.formula.listFormulas.find(f => f.name === l.name));
      })
    }
    return campaign;
  }

  save(form: NgForm) {
    this.submitted = true;
    const isValidZonesFormulaRoutines = this.campaignService.isValidCampaignFormula(this.campaign.formula);
    const isValidForm = isValidZonesFormulaRoutines && form.valid;
    this.simpCompActive = this.checkIsActive(this.campaign.formula.simultaneousComparison.couples, this.campaign.formula.simultaneousComparison.isActive);

    if (isValidForm && this.simpCompActive) {
      this.showSpinner = true;
      this.campaign.formula.applicationZone = this.chosenApplicationZone;
      this.campaign = this.updateListofIds(_.cloneDeep(this.campaign));
      this.campaignService.putCampaignFormulas(this.campaign.id, this.campaign.parameters, this.campaign.formula, _.get(this.campaign, 'visits', [])).pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showSpinner = false))
        .subscribe(
          (result) => {
            this.campaign.formula = _.cloneDeep(result.formula);
            this._campaignUntouched = _.cloneDeep(this.campaign);
            if (!_.isEmpty(_.get(result, 'errorCouple', []))) {
              const couples = result.errorCouple.map(couple => `${couple}`).join(', ');
              this.DNATranslate.translateMessage("ON_SUCCESS_UPDATE_WITH_ERRORS_COUPLE", { couples: couples }).subscribe((translated) => {
                this.errorManager.displayMessage(translated, 'warning', { timeOut: 10000 }, false);
              });
            }
            else if (!_.isEmpty(_.get(result, 'errorBench', []))) {
              const couples = result.errorBench.map(couple => `${couple}`).join(', ');
              this.DNATranslate.translateMessage("ON_SUCCESS_UPDATE_WITH_ERRORS_BENCH", { couples: couples }).subscribe((translated) => {
                this.errorManager.displayMessage(translated, 'warning', { timeOut: 10000 }, false);
              });
            } else {
              this.errorManager.displayMessage("ON_SUCCESS_UPDATE");
              this.router.navigate(['campaigns', this.campaign.id, 'edit', 'parameters']);
            }

          },
          error => {
            const errMessage = _.get(error, 'error.err', '');
            if(errMessage === "ROUTINES") {
              return this.errorManager.displayMessage('WARNING_ROUTINES_NOT_COMPLETED', 'warning');
            } else if (errMessage === "ON_ERROR_ZONES_FORMULA_ROUTINES") {
              return this.errorManager.displayMessage('ON_ERROR_ZONES_FORMULA_ROUTINES', 'danger');
            } else return this.errorManager.catchError(error);
          }
        );
    } else {
      if(!isValidZonesFormulaRoutines) {
        this.errorManager.displayMessage('ON_ERROR_ZONES_FORMULA_ROUTINES', 'danger');
      } else {
        this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
      }
    }
  }

  updateCouplesTable(campaign: CampaignFormulas, couples: SimComp[], chosenApplicationZone: KeyValue, chosenBench: GroupedFormula): CampaignFormulas {
    if (!chosenBench) {
      chosenBench = this.initChosenBench();
    }
    couples = this.utilService.calculateCouple(campaign.state, campaign.formula.listFormulas, couples, chosenApplicationZone, chosenBench);
    return this.onSimCompChange(campaign, couples);
  }

  updateListFormulaForRoutine(campaign: CampaignFormulas): CampaignFormulas {
    // Permet de mettre à jour listFormula quand on active/desactive le mode routine. Les ids des formules sont mis à jour durant l'opération.
    // Nous ne faisons cette mise à jour que si la campagne n'est pas publiée. Sinon, la mise à jour des ids reset les evaluations de la campagne.
    if (campaign.state === CampaignStates.Suspended || campaign.state === CampaignStates.Draft) {
      if (campaign.parameters.isRoutine) {
        campaign.formula.listFormulas = this.utilService.getListFormulasFromRoutine(campaign);
      } else {
        campaign.formula.listFormulas = campaign.formula.formulas.map(
          (f: Formula) => new GroupedFormula(this.utilService.generateRandomID(), f.name, [new Formula(f.name)])
        );
      }
      if (campaign.formula.anonymousFormula.isAnonymous) {
        campaign.formula.anonymousFormula.anonymousCode = this.updateAnonymousFormula(campaign.formula.listFormulas, campaign.formula.anonymousFormula.anonymousCode);
      }
    }

    // On met également à jour la liste des formules des routines
    if (campaign.parameters.isRoutine) {
      campaign.formula.listFormulas = campaign.formula.listFormulas.map(
          (listF: GroupedFormula) => {
              let routine = campaign.formula.routines.find((r: Routine2) => r.name === listF.name);
              let formulasRoutines = [];

              if (routine) {
                  for (let visit of routine.visits) {
                      if (visit.orders) { // on vérifie si visit.orders n'est pas undefined sinon la boucle for ne s'exécute pas
                          for (let order of visit.orders) {
                              for (let step of order.steps) {
                                  if (!formulasRoutines.find(f => f.name === step.formula.formulaName)) {
                                      formulasRoutines.push({ name: step.formula.formulaName });
                                  }
                              }
                          }
                      }
                  }
                  listF.formula = formulasRoutines;
              }
              return listF;
          }
      );
  }
  return campaign;
  }



  /**
   * Management of the Tag Input component
   */

  onAdding(tag) {
    return observableOf(tag.toString().toUpperCase());
  }

  onRemoving(listFormula: Formula[]) {
    return ((tag: Tag) => observableOf(tag).pipe(
      filter((tag: Tag) => {
        if (this.isDisabled && listFormula.find((f: Formula) => f.name === tag.name.toString().toUpperCase())) {
          return false;
        }
        return true;
      }))
    );
  }

  /**
   * Management of the grouped formulas list
   */

  addFormulaToList(campaign: Campaign, formula: Formula): Campaign {
    if (!campaign.parameters.isRoutine) {
      campaign.formula.listFormulas.push(
        new GroupedFormula(
          this.utilService.generateRandomID(),
          formula.name,
          [new Formula(formula.name)]
        )
      );
      if (campaign.formula.anonymousFormula.isAnonymous) {
        campaign.formula.anonymousFormula.anonymousCode = this.updateAnonymousFormula(campaign.formula.listFormulas, campaign.formula.anonymousFormula.anonymousCode)
      }
      return campaign.formula.simultaneousComparison.isActive ? this.updateCouplesTable(_.cloneDeep(campaign), _.cloneDeep(campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench) :
        _.cloneDeep(campaign);
    }
    else {
      return campaign;
    }
  }

  removeFormulaToList(campaign: Campaign, formula: Formula): Campaign {
    if (!campaign.parameters.isRoutine) {
      campaign.formula.listFormulas = campaign.formula.listFormulas.filter(
        (groupedFormula: GroupedFormula) => groupedFormula.name !== formula.name
      );
      if (campaign.formula.anonymousFormula.isAnonymous) {
        campaign.formula.anonymousFormula.anonymousCode = this.updateAnonymousFormula(campaign.formula.listFormulas, campaign.formula.anonymousFormula.anonymousCode)
      }
      return campaign.formula.simultaneousComparison.isActive ? this.updateCouplesTable(_.cloneDeep(campaign), _.cloneDeep(campaign.formula.simultaneousComparison.couples), this.chosenApplicationZone, this.chosenBench) :
        _.cloneDeep(campaign);
    }
    else {
      return campaign;
    }
  }

  addVisitInCampaignAndRoutines(campaign: CampaignFormulas) {
    let newVisit = {
        id: v4(),
        name: "0",
        value: 0,
        blocks: [],
        index: 0,
        unit: {
            name: "NONE",
            symbol: ""
        }
    }
    _.set(campaign, 'visits', [newVisit]);
    campaign.formula.routines = campaign.formula.routines.map(routine => {
      let newRoutineVisit = new Visit();
      _.set(newRoutineVisit, 'id', newVisit.id);
      _.set(newRoutineVisit, 'name', newVisit.name);
      routine.visits = [newRoutineVisit];
      return routine;
    });
    this.visitsList = this.buildVisitsList(campaign.visits);
}

}
