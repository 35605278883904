import { CampaignOnePager, Translatable, Workflow } from './../../../../../types';


import { of as observableOf, empty as observableEmpty, Subject, throwError, of, Observable, zip } from 'rxjs';

import { takeUntil, map, finalize, mergeMap, take, tap, catchError, filter } from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  Location
} from '@angular/common';

import * as _ from 'lodash';

import {
  Image,
  WorkflowPBI,
  OnePager,
} from '../../../../../types';
import {
  CampaignService
} from '../../../../campaigns.service';
import { v4 as uuid } from 'uuid';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { HttpRestService } from '../../../../../shared/services/httpRest.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { OnePagerService } from '../../../../../shared/services/one-pager.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { ChartService } from './chart-module/services';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

declare function stopCapture(): any; // Declare JS function from inline script

@Component({
  selector: 'dna-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less'],
  providers: [DNATranslateService]
})
export class ReportsComponent implements OnInit, AfterViewInit {
  showSpinner: boolean = true;
  workflow: WorkflowPBI;
  campaign: CampaignOnePager;
  onePager: OnePager;
  images: Image[] = [];
  isNewReport: boolean = true;
  error: boolean = false;
  initTime = performance.now();

  takeAllCaptures: Subject<void> = new Subject<void>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  public currentLanguage: any;

  constructor(
    private errorManagerService: ErrorManagerService,
    private httpRestService: HttpRestService,
    private campaignService: CampaignService,
    private onePagerService: OnePagerService,
    private aiService: ApplicationInsightsService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private translateService: DNATranslateService,
    private utilService: UtilService
  ) {
    this.currentLanguage = this.translateService.getLanguage();
    this.translateService.onLangChange().subscribe(({ lang }: any) => {
      this.currentLanguage = lang;
    });
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get("isNewReport")),
      tap((isNewReport: string) => this.isNewReport = _.isEmpty(isNewReport)),
      mergeMap(() => this.route.paramMap.pipe(map((params: ParamMap) => params.get("idCampaign")))),
      mergeMap((id: string) => this.campaignService.getCampaignOnePager(id)),
      tap(campaign => this.campaign = campaign),
      mergeMap(() => this.onePagerService.getAndBuildOnePager(this.campaign)),
      tap((onePager: OnePager) => this.onePager = onePager),
      tap(() => this.showSpinner = false),
      mergeMap((onePager: OnePager) => !this.isNewReport ? this.campaignService.fillImagesTab(onePager) : observableOf([])),
      tap(() => this.workflow = this.campaignService.getReportMetadata()),
      tap(() => {
        if (!this.workflow) {
          this.router.navigate(['campaigns', this.campaign.id, 'edit', 'analyse']);
        }
      }),
      catchError(err => {
        console.info(err)
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      take(1),
      finalize(() => this.showSpinner = false)
    ).subscribe((images: Image[]) => {
      this.images = _.compact(images);
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Report', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    stopCapture(); // Call JS function from inline script
  }

  catchError = (error) => {
    this.showSpinner = false;
    this.errorManagerService.catchError(error);
    return observableEmpty();
  };

  goToPowerBIDescription(workflow: Workflow) {
    this.router.navigate(['campaigns', this.campaign.id, 'edit', 'analyse', workflow.id, 'powerBIDescription']);
  }

  onCapture(array) {
    this.showSpinner = true;
    const newUuid = uuid();
    this.httpRestService.uploadPowerBICapture(this.campaign.id, { media: array[1], type: array[0] }, newUuid).pipe(
      map((res: {
        id: string,
        dataUUID: string
      }) => this.utilService.setImage(this.httpRestService.getPowerBICaptureURL(res.id, res.dataUUID), undefined, undefined, "", 0)),
      map((image: Image) => this.onePagerService.addCaptureInOnePagerImages(this.onePager, image)),
      mergeMap((onePager: OnePager) => this.onePagerService.updateOnePager(onePager.id, _.pick(onePager, ['images']))),
      finalize(() => this.showSpinner = false),
      takeUntil(this.destroy$))
      .subscribe(
        () => this.errorManagerService.displayMessage("SUCCESS_SAVE_CAPTURE"),
        error => this.errorManagerService.catchError(error)
      );
  }

  onImageLoaded(tab, name: Translatable, id: string, identifier: number, isClassification: boolean = false) {
    let file = tab[0];
    let array = tab[1];
    this.showSpinner = true;
    this.utilService.uploadMediaFromComputer(file, array[1], array[0], isClassification).pipe(
      catchError(this.catchError),
      map((url: string) => this.utilService.setImage(url, name, `${array[0]},${array[1]}`, id, identifier)),
      tap((image: Image) => this.images.push(image)),
      tap((image: Image) => this.onePagerService.addImage(this.onePager.id, image)),
      map((image: Image) => this.onePagerService.addCaptureInOnePagerImages(this.onePager, image)),
      mergeMap((onePager: OnePager) => this.onePagerService.updateOnePager(onePager.id, _.pick(onePager, ['images']))),
      finalize(() => {
        this.showSpinner = false;
      }),
      takeUntil(this.destroy$))
      .subscribe(
        () => { this.errorManagerService.displayMessage('VIZUALIZATIONS.BOARD.IMAGE_SUCCESSFULLY_UPLOADED') },
        err => this.errorManagerService.catchError(err)
      )
  }

  updateOnePager(images: Image[]) {
    this.onePagerService.updateImagesAndPutOnePager(images, this.onePager).pipe(
      tap((onePager: OnePager) => this.onePagerService.setOnePager(onePager)),
      takeUntil(this.destroy$),
      finalize(() => this.errorManagerService.displayMessage("ON_SUCCESS_UPDATE")))
      .subscribe();
  };

  uploadImage = (file, name, id, identifier, isClassification) => {
    this.showSpinner = true;
    this.utilService.uploadFile(file).subscribe({
      next: (data) => {
        this.onImageLoaded(data, name, id, identifier, isClassification);
      }
    });
  };

  takeCaptures() {
    setTimeout(() => this.showSpinner = true, 100);
    ChartService.onTakeCaptures();
  }

  /**
   * 20765 Take capture and save it in onePager images for all graphs selected
   * @param multiplesCapturesElements
   */
  onMultipleCaptures(multiplesCapturesElements: [Observable<any>, any]) {
    if (multiplesCapturesElements === null) {
      this.errorManagerService.displayMessage("ERROR_NO_GRAPHS_SELECTED", 'danger');
      setTimeout(() => this.showSpinner = false, 100);
    }
    else {
      let obs = [];
      let tabNames = multiplesCapturesElements.map(mce => {
        return {
          name: _.get(mce, '[1].name', _.get(mce, '[1].type.translations', '')),
          identifier: _.get(mce, '[1].identifier', ''),
          id: _.get(mce, '[1].id', '')
        }
      });
      let tabCaptures = multiplesCapturesElements.map(mce => _.get(mce, 0, of('')));
      tabCaptures.forEach((capture, index) => {
        let array = [];
        let cpt = capture.pipe(
          mergeMap(capture => {
            return this.utilService.uploadFile(capture);
          }),
          mergeMap(data => {
            let file = data[0];
            console.log(multiplesCapturesElements[index][1]);
            array = data[1];
            return this.utilService.uploadMediaFromComputer(file, array[1], array[0], multiplesCapturesElements[index][1].type.id == 'PeluchesRisks' && !multiplesCapturesElements[index][1].chart.isPSR);
          }),
          catchError(this.catchError),
          map((url: string) => this.utilService.setImage(url, tabNames[index].name, `${array[0]},${array[1]}`, tabNames[index].id, tabNames[index].identifier)),
        );
        obs.push(cpt);
      });
      zip(...obs).pipe(
        map((images: Image[]) => this.addIndexes(images)),
        mergeMap((images: Image[]) => this.addAllImagesInOnePager(images)),
      ).subscribe(
        (res) => {
          this.showSpinner = false;
          this.errorManagerService.displayMessage('VIZUALIZATIONS.BOARD.IMAGES_SUCCESSFULLY_UPLOADED')
        },
        err => this.errorManagerService.catchError(err)
      );
    }
  }


  /**
   * 20765 Add each image of graph captured in onePager images and save it
   * @param images
   * @returns
   */
  addAllImagesInOnePager(images: Image[]): Observable<OnePager> {
    _.sortBy(images, 'indexImage').forEach(image => {
      this.images.push(image);
      this.onePagerService.addImage(this.onePager.id, image);
      this.onePager = this.onePagerService.addCaptureInOnePagerImages(this.onePager, image);
    });
    return this.onePagerService.updateOnePager(this.onePager.id,_.pick(this.onePager, ['images']));
  }

  /**
   * 21023 Add index to capture's name if multiple graphs with same name(type)
   * @param images
   * @returns
   */
  addIndexes(images: Image[] = []) {
    let indexedImages = [];
    const originalImages = _.groupBy(_.cloneDeep(images), 'id');
    _.forOwn(originalImages, (values, key) => {
      _.sortBy(values, 'indexImage').forEach((v, index) => {
        if(values.length > 1) {
          v.name = v.name.concat(` ${index+1}`)
        }
        indexedImages.push(v);
      })
    });
    return indexedImages;
  }

};
