import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Scalebar, Table } from '../../models';
import { ChartComponent } from '../chart/chart.component';
import { ChartTableComponent } from '../chart-table/chart-table.component';
import { ChartScalebarComponent } from '../chart-scalebar/chart-scalebar.component';
import { GraphOptionsComponent } from '../graph-options/graph-options.component';
import { TableRawDataComponent } from '../table-raw-data/table-raw-data.component';
import { Languages } from '../../enums';
import { from, Subject } from 'rxjs';
import { ChartService } from '../../services';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: '[dna-chart-tile]',
  templateUrl: './chart-tile.component.html',
  styleUrls: ['./chart-tile.component.scss'],
  providers: [ChartService]
})
export class ChartTileComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(GraphOptionsComponent) optionsComponent: GraphOptionsComponent;
  @ViewChild('rawData', { read : TableRawDataComponent } ) tableRawData: TableRawDataComponent;
  @ViewChild('tile', { read: ElementRef }) tile: ElementRef;
  @ViewChild('templateToExport', { read : ElementRef }) templateToExport: ElementRef;
  @ViewChild('tableGraph', { read : ViewContainerRef }) tableGraph: ViewContainerRef;

  @Input() element: any;
  @Input() onImageLoad: any;
  @Input() data: any;
  @Input() lang: string;

  @Output() _onExporting = new EventEmitter();
  @Output() _onMultipleCaptures = new EventEmitter<any>();
  @Output() _onExcelDownload = new EventEmitter();

  public options: any;
  public maxWidth = 0;
  public rawDataVisible = false;
  public isTable = false;
  public comment = '';
  public commentFontSize = 11;
  public commentFontBold = false;
  private componentRef: any;
  private unsubsriber$ = new Subject<void>();
  private _routines: any[] = [];
  public psrAlgo: string;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if(this.element.type.id == 'PeluchesRisks' && this.element.evaluationType == 'Single + Classical routine' && this.element.noodlesGalenic != 'Noodles other'){
      let psrAlgorithmDetails = this.element.data.find(el => el.attribute.label == 'RISK').values[0];
      this.psrAlgo = (psrAlgorithmDetails?.version && psrAlgorithmDetails?.version !== 'no version') ? `PSR algorithm version : ${psrAlgorithmDetails.version} created the ${psrAlgorithmDetails.date}` : 'no PSR algorithm info available';
    }

    if (!this.element.chart.custom) {
      this.element.chart.custom = {};
    }
    this.element.chart.custom.updateRoutines = this.updateRoutines.bind(this);

    ChartService.takeCaptures.subscribe(() => {
      if (this.element.toCapture) {
        this._onMultipleCaptures.emit([from(this.element.chart.exportGraphToPng(this.templateToExport.nativeElement)), this.element]);
      }
    });
    this.isTable = (this.element.chart instanceof Table);

    if (this.element.graphParameters) {
      if (this.element.graphParameters.series) {
        this.element.graphParameters.series.options.forEach((serie, index) => {
          this.element.chart.parameters.series[index].color = serie.value;
        });
      }
      if (this.element.graphParameters.xAxis) {
        this.element.graphParameters.xAxis.options.forEach((serie) => {
          this.element.chart.parameters.xAxis['title'] = {text: serie.value};
        });
      }
      if (this.element.graphParameters.yAxis) {
        this.element.graphParameters.yAxis.options.forEach((serie) => {
          this.element.chart.parameters.yAxis['title'] = {text: serie.value};
        });
      }
    }

    this.element.chart.buildGraphOptions(this.element.chart.parameters);
    if (this.element.graphParameters) {
      this.element.chart.options.options = this.element.graphParameters;
    }
    this.options = this.element.chart.options;

    this.loadComponent();
    try {
      this.element.chart['onDrillSubject']
      .pipe(takeUntil(this.unsubsriber$))
      .subscribe({
        next: (state) => {
          switch (state) {
            case 'drill':
              this.element.chart.buildGraphOptions(this.element.chart.parameters);
              this.options = this.element.chart.options;
              this.optionsComponent.updateForm(this.element.graphParameters || this.element.chart.options.options);
          }
        }
      });
    } catch (e) { }
    try {
      this.element.chart['onOptionChangeAfterLoadSubject']
      .pipe(takeUntil(this.unsubsriber$))
      .subscribe({
        next: () => {
          if (this.element.graphParameters) {
            this.element.chart.options.options = this.element.graphParameters;
          }
          this.options = this.element.chart.options;
        }
      });
    } catch (e) { }
    if (this.element.routines && this.element.routines.routines) {
      this.routines = this.element.chart.routines.routines;
    }
    this.comment = _.get(this.element, 'comment', '');
    this.cdr.detectChanges();
    this.maxWidth = this.tile.nativeElement.clientWidth - 26;
  }

  updateRoutines(routines: any[]) {
    this.routines = routines;
  }

  public get routines() {
    return this._routines;
  }

  public set routines(routines: any[]) {
    if (this.element.graphParameters && this.element.graphParameters.series && this.element.graphParameters.series.options) {
      this.element.graphParameters.series.options.forEach((serie, index) => {
        routines.forEach((routine) => {
          if (routine.name === serie.label) {
            routine.color = serie.value;
          }
        });
        this.element.chart.parameters.series[index].color = serie.value;
      });
    }
    this._routines = routines;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsubsriber$.next();
    this.unsubsriber$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.lang && changes.lang.currentValue && !changes.lang.firstChange) {
      this.element.chart.lang = this.lang;
    }
  }

  loadComponent() {
    delete this.componentRef;
    let dynamicComponent = null;
    let inputKey = null;

    // Check component and data binding
    if (this.element.chart instanceof Table) {
      dynamicComponent = ChartTableComponent;
      inputKey = 'table';
    } else if (this.element.chart instanceof Scalebar) {
      dynamicComponent = ChartScalebarComponent;
      inputKey = 'chart';
    } else {
      dynamicComponent = ChartComponent;
      inputKey = 'chart';
    }
    this.tableGraph.clear();

    // Create component and bind data
    const componentRef = this.tableGraph.createComponent(dynamicComponent);
    (<any>componentRef.instance)[inputKey] = this.element.chart;
    // Register component into state
    this.componentRef = componentRef.instance;
    this.changeDetector.detectChanges();
  }

  public handleRawDataVisibility = () => this.rawDataVisible = !this.rawDataVisible;
  public export = () => {
    this._onExporting.emit();
    this.element.chart.exportGraphToPng(this.templateToExport.nativeElement)
      .then(file => {
        const name = _.isUndefined(this.element.name) ? _.get(this.element, 'type.translations', '') : this.element.name;
        this.onImageLoad(file, name, _.get(this.element, 'id', ''), _.get(this.element, 'identifier', ''), this.element.type.id == 'PeluchesRisks' && (this.element.evaluationType != 'Single + Classical routine' || this.element.noodlesGalenic == 'Noodles other'));
      });
  }

  /**
   * exportAsExcel
   */
  public exportAsExcel = () => {
    const previousState = this.rawDataVisible;
    if (!previousState ) { this.rawDataVisible = true; }
    this.changeDetector.detectChanges();
    this._onExcelDownload.emit({ el: this.tableRawData.table, name: this.getNameFromParameters(this.element, this.lang)});
    this.rawDataVisible = previousState;
  }

  private getNameFromParameters = (parameters: any, lang: string): string => {
    let name = '';
    if (parameters && parameters.hasOwnProperty('name')) {
      if (parameters.name.hasOwnProperty(lang) && parameters.name[lang].length) {
        name = `${name}${parameters.name[lang]}`;
      } else {
        name = `${name}${parameters.name[Languages.Default]}`;
      }
    }
    try {
      name = `${name}_${parameters.parameters.configs.subtitle.translations[lang] }`;
    } catch {
      try {
        name = `${name}_${parameters.parameters.configs.subtitle.translations[Languages.Default] }`;
      } catch ( e ) {
        name = `${name}_export`;
      }
    }
    return name;
  }

  saveGraphOptions ( event ) {
    this.comment = event.comment.trim();
    this.commentFontSize = event.commentSize;
    this.commentFontBold = event.commentBold;
    this.cdr.detectChanges();
  }
}
