import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  NgbActiveModal, NgbModalOptions, NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';
import {
  Preference,
  PreferenceTrad,
  Workspace,
  DNAGraphsTypes,
  TypeCampaign,
  ApplicationsZones,
  AttributeData
} from '../../../../../types';
import * as _ from 'lodash';
import { ReferenceTypeService } from '../../../../../shared/services/reference-type.service';
import { AddCharacterizationByHubComponent } from '../add-caracterization-by-hub/add-caracterization-by-hub.component';
import { WorkspaceService } from '../../workspaces.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'dna-workspace-preferences',
  templateUrl: './workspacePreferencesModal.component.html',
  styleUrls: ['./workspacePreferencesModal.component.less']
})

export class WorkspacePreferencesModalComponent implements OnInit, OnDestroy {
  @Input() idWorkspace: string;
  preferenceTrad: PreferenceTrad;
  optionUserVolunteer: any;
  listSelectedViews: any;
  defaultView: string;
  graphs: any[];
  listArraysPreference: any = {};
  typeCampaigns: TypeCampaign[];
  applicationZones: typeof ApplicationsZones = ApplicationsZones;
  workspace: Workspace;
  showSpinner: boolean = false;
  error: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  routerSubscription: Subscription;
  currentUrl: string;
  
  constructor(
    private activeModal: NgbActiveModal,
    private errorManagerService: ErrorManagerService,
    private referenceType: ReferenceTypeService,
    private modalService: NgbModal,
    private workspaceService: WorkspaceService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.showSpinner = true;
    this.graphs = DNAGraphsTypes;
    this.typeCampaigns = this.referenceType.getTypeCampaign();
    this.preferenceTrad = new PreferenceTrad();
    this.workspaceService.getWorkspace(this.idWorkspace).pipe(
      tap(workspace => {
        this.workspace = workspace;
        this.initVariables();
        if (!this.workspace.preferences) {
          this.workspace.preferences = new Preference();
        } else {
          this.addMissingPropertiesToObject(new Preference(), this.workspace.preferences);
        }
      }),
      catchError(err => {
        this.error = true;
        return this.catchError(err);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  addMissingPropertiesToObject(source: any = {}, target: any = {}) {
    _.map(source, (value, key) => {
      let targetKey = target[key];
      if (_.isUndefined(targetKey)) {
        target[key] = value;
      } else if (_.isObject(value)) {
        this.addMissingPropertiesToObject(value, target[key]);
      }
    })
  }

  initVariables() {
    _.forEach(this.workspace.preferences, (value, key) => {
      if (key !== 'defaultView') {
        this.listArraysPreference[key] = _.map(value, this.fromObjectToArray);
      }
    });
    this.optionUserVolunteer = this.listArraysPreference.users.find(user => user.key === 'volunteers');
    this.listSelectedViews = this.listArraysPreference.views.filter(view => view.value.isActive);
  }

  fromObjectToArray(value, key) {
    return {
      key: key,
      value: value
    };
  }

  compareKey(option1: any, option2: any) {
    return option1 && option2 ? option1.key === option2.key : false;
  }

  compare(option1: any, option2: any) {
    return option1 && option2 ? option1.id === option2.id : false;
  }

  clickOptions(object: any, key: string, property: string, isChecked: boolean) {
    object[key][property] = isChecked;
    if(key === 'applicationZone' && !isChecked) {
      object.routine.isActive = false;
    }
  }

  clickOptionsUsers(object: any, key: string, property: string, isChecked: boolean) {
    object[key][property] = isChecked;
    // Case when the user switchs the defaultValue for the option Volunteers to TRUE
    // Need to update the option Collaborative mode to TRUE
    if (key === 'volunteers' && property === 'defaultValue' && isChecked) {
      let optionUserCollaboratif = this.listArraysPreference.users.find(user => user.key === 'collaborative');
      optionUserCollaboratif.value.defaultValue = true;
      this.workspace.preferences.users.collaborative.defaultValue = true;
    }
  }

  clickOptionsViews(object: any, key: string, property: string, isChecked: boolean) {
    object[key][property] = isChecked;
    this.listSelectedViews = this.listArraysPreference.views.filter(view => view.value.isActive);
    // Case when at least 1 view display is selected
    // If the default view doesn't exist, select by default the first view in List
    if (this.listSelectedViews.length > 0) {
      let defaultView = this.workspace.preferences.defaultView;
      let findDefaultView = this.listSelectedViews.find(view => this.preferenceTrad.views[view.key].viewType === defaultView);
      if (!findDefaultView) {
        this.workspace.preferences.defaultView = this.preferenceTrad.views[this.listSelectedViews[0].key].viewType;
      }
    }
  }

  cancel() {
    this.activeModal.dismiss();
  };

  save() {
    if (this.listSelectedViews.length > 0) {
      this.activeModal.close(this.workspace);
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_FORM", "danger");
    }
  }

  chooseCaracterisation() {
    let modalOption: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg"
    };
    let options = _.cloneDeep(modalOption);
    const modal = this.modalService.open(AddCharacterizationByHubComponent, options);
    modal.componentInstance.characterizations = _.cloneDeep(_.get(this.workspace, 'preferences.characterizations', []));
    modal.componentInstance.metier = this.workspace.metier;
    modal.result.then(
      (carac: { [hub: string]: AttributeData[] }) => {
        Object.keys(carac).forEach(hub => carac[hub] = carac[hub].filter(c => c.isSelected));
        this.workspace.preferences.characterizations = carac;
      },
      (reason) => {}
    );
  }
  
  catchError = (error) => {
    this.showSpinner = false;
    this.errorManagerService.catchError(error);
    this.errorManagerService.displayMessage("ON_ERROR_UPDATE", "danger");
    return of();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
