import { VisitsPageComponent } from '../campaigns/detail/edit-campaign/visits/visits-page/visits-page.component';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  CommonModule,
  DatePipe
} from '@angular/common';
import { GridsterModule } from 'angular-gridster2';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import {
  NgModule
} from '@angular/core';
import {
  RouterModule
} from '@angular/router';

import {
  NgxLoadingModule,
} from 'ngx-loading';
import {
  DragulaModule
} from 'ng2-dragula';
import {
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {
  TagInputModule
} from 'ngx-chips';
import {
  TranslateModule
} from '@ngx-translate/core';

import {
  AccountableService
} from '../campaigns/accountable.service';
import {
  ActionBarComponent
} from './actionBar/dnaActionBar.component';
import {
  AnalyseService
} from '../campaigns/detail/edit-campaign/analyse/analyse.service';
import {
  AnonymousFormulaComponent
} from '../campaigns/detail/edit-campaign/formula/anonymous-formula/anonymous-formula.component';
import {
  ApplicationInsightsService
} from './services/applicationInsights.service';
import {
  AssessmentsService
} from '../trainings/detail/edit-training/raw-data/assessments.service';
import {
  AuthGuardService
} from './services/authenticationGuard.service';
import {
  AutofocusDirective
} from './autoFocus/autoFocus.directive';
import {
  BlockService
} from '../blocks/blocks.service';
import {
  BrickStateComponent
} from './brickState/brickState.component';
import {
  CampaignService
} from '../campaigns/campaigns.service';
import {
  ChartTypeService
} from './services/chartType.service';
import {
  CheckboxComponent
} from './checkbox/checkbox.component';
import {
  ComponentsDetail
} from '../components/detail/dnaComponentsDetail.component';
import {
  ConfirmModalComponent
} from './modals/confirm/confirm-modal.component';
import {
  CopyrightComponent
} from './copyright/copyright.component';
import {
  CreateLibraryItemModalComponent
} from './modals/create-library-item/create-library-item-modal.component';
import {
  DatalakeComponent
} from './dnaDatalake/dnaDatalake.component';
import {
  DataVizLinkComponent
} from './data-viz-link/data-viz-link.component';
import {
  DisplayInformationComponent
} from './dnaDisplayInformation/dnaDisplayInformation.component';
import {
  dnaButtonDisplayOnlyComponent
} from './dnaButtonDisplayOnly/dnaButtonDisplayOnly.component';
import {
  dnaCounterComponent
} from './dnaCounter/dnaCounter.component';
import {
  dnaDisplayFilterTableComponent
} from './dnaDisplayFilterTable/dnaDisplayFilterTable.component';
import {
  DnaSearchLogoComponent
} from './dnaSearch/dnaSearchLogo.component';
import {
  DNATranslateService
} from './services/translate.service';
import {
  EditNameAndHubComponent
} from './edit-name-and-hub/edit-name-and-hub.component';
import {
  ErrorManagerService
} from './services/errorManager.service';
import {
  EvaluationService
} from '../campaigns/detail/edit-campaign/raw-data/evaluations.service';
import {
  FavoritesComponent
} from './dnaFavorites/dnaFavorites.component';
import {
  FilterDateComponent
} from './dnaFilterDate/dnaFilterDate.component';
import {
  FilterListComponent
} from './filters/list/filter-list.component';
import {
  FilterService
} from './services/filter.service';
import {
  FiltersProtocolComponent
} from './filters/protocol/filters-protocol.component';
import {
  FiltersProtocolHairComponent
} from './filters/protocol/hair/filters-protocol-hair.component';
import {
  FiltersProtocolSkinComponent
} from './filters/protocol/skin/filters-protocol-skin.component';
import {
  FiltersTargetComponent
} from './filters/target/filters-target.component';
import {
  FiltersTargetHairComponent
} from './filters/target/hair/filters-target-hair.component';
import {
  FiltersTargetSkinComponent
} from './filters/target/skin/filters-target-skin.component';
import {
  FilterTableComponent
} from './dnaFilterTable/dnaFilterTable.component';
import { GeneratePDFService } from './services/generate-pdf.service';
import {
  HeaderComponent
} from '../general/header/dnaHeader.component';
import {
  HttpInterceptorService
} from './services/httpInterceptor.service';
import {
  HttpRestService
} from './services/httpRest.service';
import { ImagesOnePagerComponent } from './images/one-pager/images-one-pager.component';
import {
  LayoutComponent
} from '../general/layout/layout.component';
import {
  LoadNameComponent
} from './dnaLoadName/dnaLoadName.component';
import {
  MandatoryComponent
} from './dna-mandatory.component';
import {
  MaxDirective
} from './validators/max.validator';
import {
  MinDirective
} from './validators/min.validator';
import {
  MultiCampaignsService
} from '../multi-campaigns/multi-campaigns.service';
import { OnePagerService } from './services/one-pager.service';
import {
  PagerService
} from './services/pager.service';
import {
  PasswordStrengthBarComponent
} from './passwordStrengthBar/password-strength-bar.component';
import {
  PowerBIDescriptorTableComponent
} from '../campaigns/detail/edit-campaign/workflows/power-bi/descriptor-table/power-bi-descriptor-table.component';
import {
  PowerBIModalAddDescriptorComponent
} from '../campaigns/detail/edit-campaign/workflows/power-bi/add-descriptor/power-bi-modal-add-descriptor.component';
import {
  PowerBIService
} from './powerbi/powerbi.service';
import {
  PowerBIViewerComponent
} from './powerbi/powerBIViewer/powerBIViewer.component';
import {
  ProgressUserComponent
} from './progress-user/progress-user.component';
import {
  ReferenceTypeService
} from './services/reference-type.service';
import {
  SaveCancelComponent
} from './saveCancel/saveCancel.component';
import {
  ScrollToTopDirective
} from './scroll-to-top/scroll.to.top';
import {
  SearchTextComponent
} from './dnaSearch/dnaSearchText.component';
import {
  SideBarMenuComponent
} from './sideBarMenu/sideBarMenu.component';
import {
  SimultaneousComparisonComponent
} from '../campaigns/detail/edit-campaign/formula/simultaneous-comparison/simultaneous-comparison.component';
import {
  StateService
} from './services/state.service';
import {
  SwitchComponent
} from './switch/switch.component';
import {
  SwitchConfirmComponent
} from './switchConfirm/switch-confirm.component';
import {
  SynergyDemandService
} from './services/synergy-demand.service';
import {
  TableComponent
} from './table/table.component';
import {
  TestsComponent
} from './dnaTests/dnaTests.component';
import {
  TrainingService
} from '../trainings/training.service';
import {
  TranslateComponent
} from './dnaTranslate/dnaTranslate.component';
import {
  UserProfileComponent
} from '../general/my-profile/user-profile/user-profile.component';
import {
  UserService
} from './services/user.service';
import {
  UtilService
} from './services/util.service';
import {
  VolunteerService
} from '../volunteers/volunteers.service';
import {
  WorkflowService
} from '../workflows/workflows.service';
import {
  WorkspaceSelectionComponent
} from './workspace-selection/workspace-selection.component';
import {
  WorkspaceService
} from '../general/my-profile/workspaces/workspaces.service';
import { GeneratePDFMultiService } from './services/generate-pdf-multi.service';
import { ActivateGuardService } from './services/activateGuard.service';
import { ImageModalComponent } from './modals/image-modal/image-modal.component';
import { LocalStorageService } from './services/localStorage.service';
import { DnaErrorPageComponent } from './dna-error-page/dna-error-page.component';
import { RoutinePreviewModalComponent } from './routine-preview-modal/routine-preview-modal.component';
import { UploadImagesComponent } from './images/uploadImages/uploadImages.component';
import { TruncatePipe } from '../campaigns/detail/edit-campaign/analyse/reports/chart-module/pipes';
import { GraphsPageComponent } from '../campaigns/detail/edit-campaign/workflows/power-bi/page/graphs-page/graphs-page.component';
import { OnePagerContextComponent } from './one-pager/one-pager-context/one-pager-context.component';
import { OnePagerImagesComponent } from './one-pager/one-pager-images/one-pager-images.component';
import { OnePagerConclusionsComponent } from './one-pager/one-pager-conclusions/one-pager-conclusions.component';
import { QuillModule } from 'ngx-quill';
import { MultiGraphAddDescriptorModal } from '../campaigns/detail/edit-campaign/workflows/power-bi/add-descriptor-multi/add-descriptor-multi.component';
import { DescriptorMultiTableComponent } from '../campaigns/detail/edit-campaign/workflows/power-bi/descriptor-multi-table/descriptor-multi-table.component';
import { MilorAdditionalInfosComponent } from './modals/milorAdditionalInfos/milorAdditionalInfos.component';
import { MilorAdditionalInfosMultiComponent } from './modals/milorAdditionalInfosMulti/milorAdditionalInfosMulti.component';
import { dnaTimepickerComponent } from './dna-timepicker/dna-timepicker.component';
import { ActiviewDataComponent } from './modals/actiview-data/actiview-data.component';
import { Table2Component } from './table2/table2.component';
import { TranslationsRowComponent } from '../general/my-profile/translations-management/translations-row/translations-row.component';
import { EditorModule, TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular';
import {CustomModalComponent} from './modals/customModal/custom-modal.component';
import { PageEditorComponent } from './page-editor/page-editor.component';
import { ImageLayoutComponent } from './images/image-layout/image-layout.component';
import { ImageGalleryComponent } from './images/image-gallery/image-gallery.component';
import { ModalGroupVolunteersComponent } from './modalGroupVolunteers/modalGroupVolunteers.component';
import {AssociateStudiesComponent} from './associateStudies/associateStudies.component';
import { ExportDataSetRowComponent } from '../export-data-set/row/export-data-set-row.component';
import { RichTextWrapperComponent } from './rich-text-wrapper/rich-text-wrapper.component';
import { DateService } from './services/date.service';
import { ActiviewSyncModalComponent } from './modals/actiview-sync/actiview-sync.component';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    DragulaModule.forRoot(),
    FormsModule,
    HttpClientModule,
    NgxLoadingModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    TagInputModule,
    TranslateModule,
    GridsterModule,
    QuillModule,
    EditorModule,
  ],
  exports: [
    ActionBarComponent,
    AnonymousFormulaComponent,
    AutofocusDirective,
    BrickStateComponent,
    BrowserAnimationsModule,
    CheckboxComponent,
    CommonModule,
    ComponentsDetail,
    ConfirmModalComponent,
    ActiviewSyncModalComponent,
    ImageModalComponent,
    CopyrightComponent,
    CreateLibraryItemModalComponent,
    DatalakeComponent,
    DataVizLinkComponent,
    DisplayInformationComponent,
    dnaButtonDisplayOnlyComponent,
    dnaCounterComponent,
    dnaDisplayFilterTableComponent,
    DnaErrorPageComponent,
    DnaSearchLogoComponent,
    EditNameAndHubComponent,
    FavoritesComponent,
    FilterDateComponent,
    FilterListComponent,
    FiltersProtocolComponent,
    FiltersProtocolHairComponent,
    FiltersTargetComponent,
    FiltersTargetHairComponent,
    FilterTableComponent,
    FormsModule,
    HeaderComponent,
    dnaTimepickerComponent,
    ImagesOnePagerComponent,
    LayoutComponent,
    LoadNameComponent,
    MilorAdditionalInfosComponent,
    MilorAdditionalInfosMultiComponent,
    MandatoryComponent,
    MaxDirective,
    MinDirective,
    NgbModule,
    NgxLoadingModule,
    TruncatePipe,
    OnePagerContextComponent,
    OnePagerImagesComponent,
    OnePagerConclusionsComponent,
    PasswordStrengthBarComponent,
    PowerBIDescriptorTableComponent,
    DescriptorMultiTableComponent,
    PowerBIViewerComponent,
    ProgressUserComponent,
    RouterModule,
    SaveCancelComponent,
    ScrollToTopDirective,
    SearchTextComponent,
    SideBarMenuComponent,
    SimultaneousComparisonComponent,
    SwitchComponent,
    SwitchConfirmComponent,
    TableComponent,
    Table2Component,
    TagInputModule,
    TestsComponent,
    TranslateComponent,
    TranslateModule,
    UploadImagesComponent,
    UserProfileComponent,
    WorkspaceSelectionComponent,
    GraphsPageComponent,
    VisitsPageComponent,
    ActiviewDataComponent,
    TranslationsRowComponent,
    AssociateStudiesComponent,
    ExportDataSetRowComponent,
    RichTextWrapperComponent,
  ],
  declarations: [
    ActionBarComponent,
    AnonymousFormulaComponent,
    TruncatePipe,
    AutofocusDirective,
    BrickStateComponent,
    CheckboxComponent,
    ComponentsDetail,
    ConfirmModalComponent,
    ActiviewSyncModalComponent,
    ModalGroupVolunteersComponent,
    AssociateStudiesComponent,
    dnaTimepickerComponent,
    ImageModalComponent,
    CopyrightComponent,
    CreateLibraryItemModalComponent,
    DatalakeComponent,
    DataVizLinkComponent,
    DisplayInformationComponent,
    dnaButtonDisplayOnlyComponent,
    dnaCounterComponent,
    dnaDisplayFilterTableComponent,
    DnaErrorPageComponent,
    DnaSearchLogoComponent,
    EditNameAndHubComponent,
    dnaTimepickerComponent,
    FavoritesComponent,
    FilterDateComponent,
    FilterListComponent,
    FiltersProtocolComponent,
    FiltersProtocolHairComponent,
    FiltersProtocolSkinComponent,
    FiltersTargetComponent,
    FiltersTargetHairComponent,
    FiltersTargetSkinComponent,
    FilterTableComponent,
    HeaderComponent,
    ImagesOnePagerComponent,
    MilorAdditionalInfosComponent,
    MilorAdditionalInfosMultiComponent,
    UploadImagesComponent,
    LayoutComponent,
    LoadNameComponent,
    MandatoryComponent,
    MaxDirective,
    MinDirective,
    OnePagerContextComponent,
    OnePagerImagesComponent,
    OnePagerConclusionsComponent,
    PasswordStrengthBarComponent,
    PowerBIDescriptorTableComponent,
    DescriptorMultiTableComponent,
    PowerBIModalAddDescriptorComponent,
    MultiGraphAddDescriptorModal,
    PowerBIViewerComponent,
    ProgressUserComponent,
    RoutinePreviewModalComponent,
    SaveCancelComponent,
    ScrollToTopDirective,
    SearchTextComponent,
    SideBarMenuComponent,
    SimultaneousComparisonComponent,
    SwitchComponent,
    SwitchConfirmComponent,
    TableComponent,
    Table2Component,
    TestsComponent,
    TranslateComponent,
    UserProfileComponent,
    WorkspaceSelectionComponent,
    ImageModalComponent,
    GraphsPageComponent,
    VisitsPageComponent,
    ActiviewDataComponent,
    CustomModalComponent,
    PageEditorComponent,
    ImageLayoutComponent,
    ImageGalleryComponent,
    TranslationsRowComponent,
    ExportDataSetRowComponent,
    RichTextWrapperComponent,
  ],
  providers: [
    AccountableService,
    AnalyseService,
    ApplicationInsightsService,
    AssessmentsService,
    AuthGuardService,
    ActivateGuardService,
    BlockService,
    LocalStorageService,
    CampaignService,
    ChartTypeService,
    DatePipe,
    DNATranslateService,
    ErrorManagerService,
    EvaluationService,
    FilterService,
    GeneratePDFService,
    GeneratePDFMultiService,
    HttpRestService,
    OnePagerService,
    PagerService,
    PowerBIService,
    ReferenceTypeService,
    StateService,
    SynergyDemandService,
    TrainingService,
    UserService,
    UtilService,
    VolunteerService,
    WorkflowService,
    WorkspaceService,
    MultiCampaignsService,
    DateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule { }
