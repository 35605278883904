import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DNATranslateService } from './translate.service';
import { User } from '@app/types';
import { UserService } from './user.service';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import packageInfo from '../../../../package.json';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  userService: UserService;
  translationService: DNATranslateService;
  router: Router;
  aiService: ApplicationInsightsService;

  constructor(
    private inj: Injector,
    private authService: MsalService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.userService = this.inj.get(UserService);
    this.translationService = this.inj.get(DNATranslateService);
    this.router = this.inj.get(Router);
    this.aiService = this.inj.get(ApplicationInsightsService);

    const user: User = this.userService.getUser();
    let headers: HttpHeaders;
    let dupReq: HttpRequest<any>;
    headers = req.headers
      .set('x-dna-origin', 'studio')
      .set('x-dna-clienttimestamp', new Date().getTime().toString())
      .set('x-dna-pzone', new Date().getTimezoneOffset().toString())
      .set('x-dna-language', this.translationService.getLanguage())
      .set('studio-version', packageInfo.version)
      .set('x-dna-workspace', user?.currentWorkspace?.id ?? '');

    if (this.userService.getClientIpAddress()) {
      headers = headers.set('True-Client-IP', this.userService.getClientIpAddress());
    }
    const account = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

    if (!account) {
      // Rediriger l'utilisateur vers la page de connexion s'il n'est pas connecté
      this.router.navigate(['connection']);
      return throwError(() => new Error('No active account found'));
    }

    if (req.url.indexOf(environment.server_url_studio()) > - 1 && !headers.get('authorization') && user !== null) {
      headers = headers
        .set('authorization', `Bearer ${user.token}`)
        .set('x-dna-tokenv2', `Bearer ${account.idToken}`)
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');

    } else {
      headers = headers
        .set('accept', 'application/vnd.dna.v1+json')
        .set('authorization', `Bearer ${account.idToken}`)
        .set('x-dna-subscription-name', 'digitexpert')
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
    }

    dupReq = req.clone({headers: headers});
    return next.handle(dupReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.headers?.get('X-Gtw-Response')) {
          this.aiService.trackLatency(event.url, new Date().getTime() - parseInt(event.headers.get('x-gtw-response'), 10));
        }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.userService.removeUser();
              this.router.navigate(['/']);
            }
            if (err.status === 200 && err.url.indexOf('workinprogress') > - 1) {
              this.userService.removeUser();
              location.assign(err.url);
            }
          }
        }
      ),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          this.userService.removeUser();
          this.router.navigate(['connection']);
        }
        return throwError(() => error);
      })
    );
  }
}
