
import { empty as observableEmpty, merge as observableMerge, from as observableFrom, Observable, Subscription, of, } from 'rxjs';

import { toArray, finalize, mergeMap, map, catchError, tap, flatMap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationStart,
  Router
} from '@angular/router';

import * as _ from 'lodash';
import {
  DragulaOptions, DragulaService
} from 'ng2-dragula';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';


import {
  Block,
  ChartType,
  DNAComponent,
  States,
  User,
  ImageBlob,
  JsonPatch,
  Etags
} from '../../../types';
import {
  BlockService
} from '../../blocks.service';
import {
  ChartTypeService
} from '../../../shared/services/chartType.service';
import {
  ComponentModalEditNameComponent
} from '../component/edit-name/component-modal-edit-name.component';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  UserService
} from '../../../shared/services/user.service';
import {
  UtilService
} from '../../../shared/services/util.service';
import { CreateLibraryItemModalComponent } from '../../../shared/modals/create-library-item/create-library-item-modal.component';
import { StateService } from '../../../shared/services/state.service';
import { HttpRestService } from '../../../shared/services/httpRest.service';
import { v4 as uuid } from 'uuid';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-block-detail',
  templateUrl: './block-detail.component.html',
  styleUrls: ['./block-detail.component.less']
})
export class BlockDetailComponent implements OnInit, AfterViewInit {

  block: Block = new Block();
  blockStates: typeof States = States;
  chartTypes: ChartType[];
  currentLanguage: string;
  idDragula: string;
  isBlockChanged: boolean = false;
  languages: string[] = [];
  modalOption: NgbModalOptions = {};
  originalBlock: Block = new Block();
  showSpinner: boolean = false;
  user: User;
  oldComponents: DNAComponent[];
  subs = new Subscription();
  isCollapsedBlockName: boolean = true;
  isCollapsedReportConfiguration: boolean = true;
  idBlock: string;
  isPageComponents: boolean = false;
  initTime = performance.now();
  routerSubscription: Subscription;
  currentUrl: string;

  constructor(
    private blockService: BlockService,
    private chartType: ChartTypeService,
    private DNATranslate: DNATranslateService,
    private dragulaService: DragulaService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private aiService: ApplicationInsightsService,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private httpRestService: HttpRestService
  ) {
    //setup dragNdrop for creating content
    this.idDragula = this.utilService.generateRandomID();
    const dragulaOptions: DragulaOptions<DNAComponent> = {
      copy: (el: Element) => {
        return el.className.indexOf('copy-allowed') !== -1;
      },
      copyItem: (item: DNAComponent) => _.cloneDeep(item),
      accepts: (el: Element, target: Element, source: Element, sibling: Element) => {
        return target.id !== 'source' && this.block.state !== this.blockStates.Published;
      }
    };
    dragulaService.createGroup(this.idDragula, dragulaOptions);

    this.subs.add(dragulaService.dropModel(this.idDragula).subscribe(() => {
      this.onDropModel();
    }));
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Block Details', '', performance.now() - this.initTime, templateUrl);
  }

  hasBlockChanged() {
      return this.isBlockChanged || this.blockService.isBlockChanged;
  }

  ngOnInit() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.user = this.userService.getUser();
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.chartTypes = this.chartType.getChartType();

    this.block = this.blockService.getLocalBlock();
    this.originalBlock = this.blockService.getBlockUntouched();
    this.isBlockChanged = !_.isEqual(this.block, this.blockService.blockUntouched);

    if (this.originalBlock.state === States.Published) {
      this.errorManager.displayMessage('BLOCK_IS_PUBLISHED', 'info');
    }

    this.DNATranslate.getLanguages().then(
      request => this.languages = request
    );

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });

    this.replaceImages(this.block.components);
  }

  isAuthorized(role: string) {
    return this.userService.isAuthorized(role);
  }

  addOrRemoveMandatory(componentIndex) {
    if (this.block.state !== States.Published) {
      this.blockService.toggleMandatoryComponent(componentIndex);
      this.isBlockChanged = true;
    } else {
      this.errorManager.displayMessage('BLOCK_IS_PUBLISHED', 'info');
    }
  }

  cancelEditBlock() {
    this.block = this.blockService.blockUntouched;
    this.blockService.setLocalBlock(this.blockService.blockUntouched);
    this.blockService.updateName(this.blockService.blockUntouched);
    this.blockService.clearComponentToDelete();
    this.isBlockChanged = false;
    this.blockService.isBlockChanged = false;
  }

  checkMandatoryComponents(block: Block) {
    return block.components.map(component => {
      if (_.isUndefined(component.mandatory)) {
        component.mandatory = false;
      }
    });

  }

  duplicateComponent(component: DNAComponent) {
      this.blockService.addComponentToBlock({..._.pick(component, ['args', 'type']), index: this.block.components.length });
      this.isBlockChanged = true;
  }

  editComponentConfiguration(key: number, component: DNAComponent) {
    this.blockService.component = component;
    this.blockService.indexComponent = key;
    this.isPageComponents = true;
    this.router.navigate(['blocks', this.block.id, 'editComponent']);
  }

  onChange() {
    this.isBlockChanged = true;
  }

  onDropModel() {
    this.isBlockChanged = true;
  }

  openModalEditComponentName(component: DNAComponent) {
    const modal = this.modalService.open(ComponentModalEditNameComponent, this.modalOption);
    modal.componentInstance.inactive = this.block.state === States.Published;
    modal.componentInstance.label = component.args.label;
    modal.result.then((translatedLabel) => {
      if (!_.isEqual(component.args.label, translatedLabel)) {
        this.isBlockChanged = true;
        this.blockService.updateComponentFromBlock({
          ...component,
          args: {
            ...component.args,
            label: translatedLabel
          }
        });
      }
    }, (reason) => { });
  }

  preview() {
    if (this.isBlockChanged) {
      this.errorManager.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', "default");
    }
    else {
      this.router.navigate(['blocks', this.block.id, 'previewComponents']);
    }
  }

  publishBlock() {
    this.showSpinner = true;
    return this.blockService.publishBlock(this.block.id).pipe(
      catchError(this.catchError),
      tap((block: Block) => {
        const updatedBlock = {
          ...block,
          components: this.block.components
        };
        this.block = updatedBlock;
        this.blockService.setLocalBlock(updatedBlock);
        this.blockService.setBlockUntouched(updatedBlock);
      }),
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  removeFromList(index: number) {
    if (this.block.state !== States.Published) {
      this.isBlockChanged = true;
      const localComponent = this.block.components.find(component => component.index === index);

      if (localComponent.id) {
        this.blockService.addComponentToDelete(localComponent.id);
        this.blockService.setLocalBlock({
          ...this.block,
          components: this.block.components
              .sort((a, b) => a.index - b.index)
              .filter(component => component.id !== localComponent.id).map((currentComponent, currentIndex) => {
              return {
                  ...currentComponent,
                  index: currentIndex
              };
          })
        });
      } else {
        this.blockService.setLocalBlock({
          ...this.block,
          components: this.block.components
              .sort((a, b) => a.index - b.index)
              .filter(component => component.index !== index).map((currentComponent, currentIndex) => {
              return {
                  ...currentComponent,
                  index: currentIndex
              };
          })
        });
      }
      this.block = this.blockService.block;
    }
  }

  saveBlock() {
    this.checkMandatoryComponents(this.block);
    if (this.verifData()) {
      this.updateBlock();
    }
  }


  selectComponent() {
    this.blockService.indexComponent = undefined;
    this.isPageComponents = true;
    this.router.navigate(['blocks', this.block.id, 'addComponents']);
  }

  suspendBlock() {
    this.showSpinner = true;
    return this.blockService.suspendBlock(this.block.id).pipe(
      catchError(this.catchError),
      tap((result: Block) => {
        const updatedBlock = {
          ...result,
          components: this.block.components
        };
        this.block = updatedBlock;
        this.blockService.setLocalBlock(updatedBlock);
        this.blockService.setBlockUntouched(updatedBlock);
      }),
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  forceRemoveUnusedComponents() {
    this.showSpinner = true;
    return this.blockService.forceRemoveUnusedComponents(this.block.id).pipe(
      catchError(this.catchError),
      tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  updateBlock() {
    this.showSpinner = true;
    const richTextComponent = this.block.components
      .map(c => this.replaceImagesFromRichText(c))
      .map((c, index) => this.getImagesFromRichText(c, index));
    observableFrom(_.flatten(richTextComponent).filter(m => m.length)).pipe(
      mergeMap((media: { image: string, index: string }) => this.uploadMedia(media.image, media.index)),
      toArray(),
      flatMap(() => this.blockService.saveBlock(this.block.id, this.block, this.blockService.componentsToDelete)),
      catchError((err) => {
        if (409 === _.get(err, 'status', 0)) {
          this.errorManager.displayMessage("ON_ERROR_ETAG", "danger");
          return of();
        } else {
          this.isBlockChanged = true;
          return this.catchError(err);
        }
      }),
      tap((result: Block) => {
          this.block = result;
          this.blockService.setLocalBlock(result);
          this.blockService.setBlockUntouched(result);
          this.blockService.clearComponentToDelete();
          this.blockService.isBlockChanged = false;
      }),
      tap(() => {
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
        this.isBlockChanged = false;
      }),
      finalize(() => {
        this.showSpinner = false;
      })
    ).subscribe();
  }

  verifData() {
    if (_.isEmpty(this.block.components)) {
      this.errorManager.displayMessage('ON_ERROR_EMPTY_BLOCK', 'danger');
      return false;
    }
    if (!this.block.name.english) {
      this.errorManager.displayMessage('ON_ERROR_NAME_BLOCK', 'danger');
      return false;
    }
    return true;
  }

  createNewBlock() {
    const modalNew = this.modalService.open(CreateLibraryItemModalComponent, this.modalOption);
    modalNew.componentInstance.data = new Block();
    modalNew.componentInstance.faIcon = 'fa-list-ul';
    modalNew.componentInstance.title = 'CREATE_BLOCK';
    modalNew.componentInstance.type = this.stateService.BLOCKS;
    modalNew.result.then(result => {
      this.showSpinner = true;
      this.blockService.createBlock(result).pipe(
        catchError(this.catchError),
        tap((block: Block) => {
          this.router.navigate(['blocks', block.id]);
          this.blockService.block = block;
          this.blockService.blockUntouched = _.cloneDeep(block);
          this.showSpinner = false;
          this.ngOnInit();
        }))
        .subscribe();
    }).catch((err) => {
      this.router.navigate(['blocks']);
    });
  }

  private replaceImages(components: DNAComponent[]) {
    observableFrom(components.filter(c => c.type === 'dna-rich-text')).pipe(
      mergeMap(c => this.replaceURLByImages(c)))
      .subscribe();
  }

  /**
   * get the id of images from richtext only if it already exists
   * the id will replace the image data in the richtext
   * @param {DNAComponent} component
   * @return {DNAComponent}
   */
  private replaceImagesFromRichText(component: DNAComponent): DNAComponent {
    if (component.type === 'dna-rich-text' && _.get(component, 'args.richText', '') != null) {
      let componentTemp = _.cloneDeep(component);
      const newImagesFromRichText = component.args.richText.match(new RegExp("<img\\s(src=).*?.+?>", "g"));
      _.forEach(newImagesFromRichText, imageRichText => {
        if (!imageRichText.includes("alt")) {
          componentTemp.args.richText = componentTemp.args.richText.replace(imageRichText, '');
        }
      });
      const oldImagesFromRichText = componentTemp.args.richText.match(new RegExp("<img\\s(src=).*?.+?>\">", "g"));
      _.forEach(oldImagesFromRichText, imageRichText => {
        const mediaID = imageRichText.match(new RegExp("<mediaUUID\\s.*?.+?>"));
        component.args.richText = component.args.richText.replace(imageRichText, mediaID);
      });
    }
    return component;
  }

  /**
   * get images from richtext and transform it into url only if doesn't exist
   * the mediaID will replace the image data in the richtext
   * @param {DNAComponent} component
   * @return {{image: string, index: string}[]}  ==> return the image and the index of the component
   */
  private getImagesFromRichText(component: DNAComponent, index: number): { image: string, index: number }[] {
    let imageData: { image: string, index: number }[] = [];
    if (component.type === 'dna-rich-text' && _.get(component, 'args.richText', '') != null) {
      const newImagesFromRichText = component.args.richText.match(new RegExp("<img\\s(src=).*?.+?>", "g"));
      if (newImagesFromRichText) {
        newImagesFromRichText.forEach((image) => {
          // const index = component.index.toString();
          imageData.push({ image, index });
        });
      }
    }
    return imageData;
  }

  /**
  * Send the image data to the server and get the id
  * then replace the image data by the id in the richtext
  * @param {string} media ==> the image data
  * @param {string} index ==> index of the component
  * @return {Observable<string>}
  */
  private uploadMedia(media: string, index: string): Observable<string> {
    const newUuid = uuid();
    let mediaData = media.substring(10, media.length - 2).split(',');
    return this.httpRestService.uploadMedia(new ImageBlob(mediaData[1], mediaData[0]), newUuid).pipe(
      catchError(this.catchError),
      map((response: { mediaUUID: string }) => response.mediaUUID),
      tap(response => {
        this.block.components[index].args.richText = this.block.components[index].args.richText.replace(media, `<mediaUUID ${response}>`);
        this.oldComponents[index].args.richText = this.oldComponents[index].args.richText.replace(media, `${media.substring(0, media.length - 1)} alt="<mediaUUID ${response}>">`)
      }));
  }

  /**
  * find the mediaID from component's richtext and replace it by the image's data
  * @param {DNAComponent}
  */
  private replaceURLByImages(component: DNAComponent): Observable<DNAComponent> {
    let download: Observable<DNAComponent>[] = [];
    if (_.get(component, 'args.richText', '') != null && !_.get(component, 'args.richText', '').includes("<img")) {
      const mediaIDFromRichText = component.args.richText.match(new RegExp("<mediaUUID\\s.*?.+?>", "g"));
      if (!_.isEmpty(mediaIDFromRichText)) {
        download = mediaIDFromRichText.map((media) => {
          return this.httpRestService.downloadMedia(media.substring(11, media.length - 1)).pipe(
            tap((image: any) => {
              component.args.richText = component.args.richText.replace(media, `<img src="${image.type},${image.media}" alt="${media}">`);
            }));
        });
      }
    }
    return observableMerge(...download);
  }

  catchError = (error) => {
    this.showSpinner = false;
    if (error.error == "name") {
      this.errorManager.displayMessage('NAME_ALREADY_TAKEN', 'danger')
    } else {
      this.errorManager.displayMessage("ON_ERROR_UPDATE", "danger");
    }
    return of();
  }

  changeIndexComponents(event) {
    const updatedComponentsOrder = event.map((component: DNAComponent, index: number) => {
      return {
        ...component,
        index
      };
    });
    this.blockService.setBlockComponents(updatedComponentsOrder);
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
