import { ModalGroupVolunteersComponent } from '../../../shared/modalGroupVolunteers/modalGroupVolunteers.component';
import { ErrorManagerService } from './../../../shared/services/errorManager.service';
import { ReferenceTypeService } from './../../../shared/services/reference-type.service';
import { HttpRestService } from '../../../shared/services/httpRest.service';
import { GroupVolunteers, Hub, HubById, Hubs, User } from '../../../types';
import { VolunteerService } from '../../../volunteers/volunteers.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'dna-edit-group-horsarcs-row',
  templateUrl: './edit-group-horsarcs-row.component.html',
  styleUrls: ['./edit-group-horsarcs-row.component.css']
})
export class EditGroupHorsArcsRowComponent implements OnInit {

  @Input() countriesIn: Hubs[];
  @Input() hubIn: HubById;
  @Input() formHorsArcs: any;
  @Input() user: User;
  
  hubOS : HubById;
  countryOS: Hub;
  groupVolunteerToAdd: any;
  osIncorrect: boolean;
  groupVolunteers: string[];
  routerSubscription: Subscription;
  currentUrl: string;
  
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  constructor(
    private referenceTypeService: ReferenceTypeService,
    private httpRestService: HttpRestService,
    private modalService: NgbModal,
    private errorManager: ErrorManagerService,
    private volunteerService: VolunteerService,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
   }

  ngOnInit() {
    this.hubOS = this.hubIn;
    this.countryOS = this.referenceTypeService.getHubFromHubById(this.hubOS);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.getValuesForForm();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  
  setCountryOS(country: Hub) {
    this.countryOS = country;
    this.hubOS = this.referenceTypeService.getHubByIdFromHub(country);
    this.getValuesForForm();
  }

  openModalEditGroupVolunteer() {
    if (this.groupVolunteerToAdd && this.groupVolunteerToAdd[0])
      this.getGroupPanelistInfo(this.groupVolunteerToAdd[0].groupName);
    else if (this.formHorsArcs) 
      this.getGroupPanelistInfo(this.formHorsArcs);
    else
    {
      console.log("Input is empty");
      this.osIncorrect = true;
    }
  }

  getGroupPanelistInfo(groupVolunteer:string)
  {
    this.httpRestService.getGroupPanelistHorsArcs(this.hubOS, groupVolunteer).subscribe(
      {
        next : (gp: GroupVolunteers) => {
          this.osIncorrect = false;
          const modal = this.modalService.open(ModalGroupVolunteersComponent, this.modalOption);
          modal.componentInstance.id = gp.id;
          modal.componentInstance.groupVolunteersOriginal = gp;
          modal.componentInstance.horsArcsSystem = gp.hub;
          modal.componentInstance.isAssociateStudyPossible = true;
          modal.componentInstance.isUserAdmin = this.user.roles.includes('DNA_ADMIN');
          modal.result.then(
            (data) => {/* nothing to do */},
            (reason) => {/* nothing to do */}
          ).catch(() => this.errorManager.sendMessage('error', 'danger', {}));
        },
        error : (err: any) => {
          console.log("Error when get group", err);
          this.osIncorrect = true;
        }
      }
    );
  }

  getValuesForForm()
  {
    this.groupVolunteerToAdd = [];
    this.formHorsArcs = "";

    this.volunteerService.getVolunteerGroupShort(this.hubOS).subscribe({
      next: (result) => {
        const list = result.list;
        this.groupVolunteers = this.resultToString(list);
      }
    });
  }

  resultToString(valueList):string[]
  {
    let returnValue: string[] = [];

    for (let value of valueList)
    {
       if (value.groupName)
         returnValue.push(value.groupName);
    }

    return returnValue;
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
