import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dna-confirm-modal',
    templateUrl: './confirm-modal.component.html'
})

export class ConfirmModalComponent implements OnInit {

    @Input() message: string;
    @Input() title: string;

    constructor(
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit() { }

    cancel() {
        this.activeModal.dismiss(false);
    }

    validate() {
        this.activeModal.close(true);
    }

}
