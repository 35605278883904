<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-lg my-auto">
        <h5>{{ "TARGET" | translate }} - {{ "METIER_SKIN" | translate }}</h5>
      </div>
      <div class="col-12 col-lg my-auto">
        <dna-checkbox class="d-inline-block" [isChecked]="allFormChecked" [text]="(allFormChecked ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate"
          (onBooleanChanged)="allFormChecked ? uncheckAllForm() : checkAllForm()" [disabled]="publishedTemplate">
        </dna-checkbox>
      </div>
      <div class="col-12 col-lg my-auto">
        <div *ngIf="isChangedTarget()" class="float-end d-flex flex-row gap-1">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit()" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="target" class="ibox-content">
    <div class="row border-bottom" *ngIf="target.cheeksBlemish && target.cheeksBlemish.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'CHEEKS_BLEMISH'" ngbTooltip="{{ (lineSelectedObject.cheeksBlemish ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheeksBlemish" [disabled]="publishedTemplate"
          (onBooleanChanged)="lineSelectedObject.cheeksBlemish = selectUnselectLine(target.cheeksBlemish, lineSelectedObject.cheeksBlemish)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheeksBlemish of target.cheeksBlemish; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheeksBlemish.IsValue" [disabled]="publishedTemplate" [text]="cheeksBlemish.Text" (onBooleanChanged)="onChangeData(target.cheeksBlemish, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.cheeksRedness && target.cheeksRedness.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'CHEEKS_REDNESS'" ngbTooltip="{{ (lineSelectedObject.cheeksRedness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.cheeksRedness"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.cheeksRedness = selectUnselectLine(target.cheeksRedness, lineSelectedObject.cheeksRedness)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let cheeksRedness of target.cheeksRedness; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="cheeksRedness.IsValue" [disabled]="publishedTemplate" [text]="cheeksRedness.Text" (onBooleanChanged)="onChangeData(target.cheeksRedness, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.dilatedPores && target.dilatedPores.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'DILATED_PORES'" ngbTooltip="{{ (lineSelectedObject.dilatedPores ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.dilatedPores"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.dilatedPores = selectUnselectLine(target.dilatedPores, lineSelectedObject.dilatedPores)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let dilatedPores of target.dilatedPores; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="dilatedPores.IsValue" [disabled]="publishedTemplate" [text]="dilatedPores.Text" (onBooleanChanged)="onChangeData(target.dilatedPores, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.eyelashesCurve && target.eyelashesCurve.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'EYELASHES_CURVE'" ngbTooltip="{{ (lineSelectedObject.eyelashesCurve ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesCurve"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesCurve = selectUnselectLine(target.eyelashesCurve, lineSelectedObject.eyelashesCurve)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let eyelashesCurve of target.eyelashesCurve; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="eyelashesCurve.IsValue" [disabled]="publishedTemplate" [text]="eyelashesCurve.Text" (onBooleanChanged)="onChangeData(target.eyelashesCurve, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.eyelashesDensity && target.eyelashesDensity.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'EYELASHES_DENSITY'" ngbTooltip="{{ (lineSelectedObject.eyelashesDensity ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesDensity"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesDensity = selectUnselectLine(target.eyelashesDensity, lineSelectedObject.eyelashesDensity)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let eyelashesDensity of target.eyelashesDensity; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="eyelashesDensity.IsValue" [disabled]="publishedTemplate" [text]="eyelashesDensity.Text" (onBooleanChanged)="onChangeData(target.eyelashesDensity, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.eyelashesLength && target.eyelashesLength.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'EYELASHES_LENGTH'" ngbTooltip="{{ (lineSelectedObject.eyelashesLength ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.eyelashesLength"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.eyelashesLength = selectUnselectLine(target.eyelashesLength, lineSelectedObject.eyelashesLength)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let eyelashesLength of target.eyelashesLength; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="eyelashesLength.IsValue" [disabled]="publishedTemplate" [text]="eyelashesLength.Text" (onBooleanChanged)="onChangeData(target.eyelashesLength, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.facialBrightness && target.facialBrightness.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'FACIAL_BRIGHTNESS'" ngbTooltip="{{ (lineSelectedObject.facialBrightness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.facialBrightness"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.facialBrightness = selectUnselectLine(target.facialBrightness, lineSelectedObject.facialBrightness)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let facialBrightness of target.facialBrightness; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="facialBrightness.IsValue" [disabled]="publishedTemplate" [text]="facialBrightness.Text" (onBooleanChanged)="onChangeData(target.facialBrightness, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.facialSkin && target.facialSkin.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'FACIAL_SKIN'" ngbTooltip="{{ (lineSelectedObject.facialSkin ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.facialSkin"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.facialSkin = selectUnselectLine(target.facialSkin, lineSelectedObject.facialSkin)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let facialSkin of target.facialSkin; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="facialSkin.IsValue" [disabled]="publishedTemplate" [text]="facialSkin.Text" (onBooleanChanged)="onChangeData(target.facialSkin, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.lipsSize && target.lipsSize.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'LIPS_SIZE'" ngbTooltip="{{ (lineSelectedObject.lipsSize ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.lipsSize"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.lipsSize = selectUnselectLine(target.lipsSize, lineSelectedObject.lipsSize)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let lipsSize of target.lipsSize; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="lipsSize.IsValue" [disabled]="publishedTemplate" [text]="lipsSize.Text" (onBooleanChanged)="onChangeData(target.lipsSize, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.lipsThickness && target.lipsThickness.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'LIPS_THICKNESS'" ngbTooltip="{{ (lineSelectedObject.lipsThickness ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.lipsThickness"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.lipsThickness = selectUnselectLine(target.lipsThickness, lineSelectedObject.lipsThickness)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let lipsThickness of target.lipsThickness; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="lipsThickness.IsValue" [disabled]="publishedTemplate" [text]="lipsThickness.Text" (onBooleanChanged)="onChangeData(target.lipsThickness, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.phototype && target.phototype.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'PHOTOTYPE'" ngbTooltip="{{ (lineSelectedObject.phototype ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.phototype"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.phototype = selectUnselectLine(target.phototype, lineSelectedObject.phototype)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let phototype of target.phototype; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="phototype.IsValue" [disabled]="publishedTemplate" [text]="phototype.Text" (onBooleanChanged)="onChangeData(target.phototype, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.foreheadTone && target.foreheadTone.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'FOREHEAD_TONE'" ngbTooltip="{{ (lineSelectedObject.foreheadTone ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.foreheadTone"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.foreheadTone = selectUnselectLine(target.foreheadTone, lineSelectedObject.foreheadTone)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let foreheadTone of target.foreheadTone; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="foreheadTone.IsValue" [disabled]="publishedTemplate" [text]="foreheadTone.Text" (onBooleanChanged)="onChangeData(target.foreheadTone, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.skinType && target.skinType.length > 0">
      <div class="col-12 col-lg-4">
        <dna-checkbox class="d-inline-block" [text]="'SKIN_TYPE'" ngbTooltip="{{ (lineSelectedObject.skinType ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.skinType"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.skinType = selectUnselectLine(target.skinType, lineSelectedObject.skinType)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let skinType of target.skinType; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="skinType.IsValue" [disabled]="publishedTemplate" [text]="skinType.Text" (onBooleanChanged)="onChangeData(target.skinType, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.upperEyelidsRelaxation && target.upperEyelidsRelaxation.length > 0">
      <div class="col-12">
        <dna-checkbox class="d-inline-block" [text]="'UPPEREYELIDS_RELAXATION'" ngbTooltip="{{ (lineSelectedObject.upperEyelidsRelaxation ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.upperEyelidsRelaxation"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.upperEyelidsRelaxation = selectUnselectLine(target.upperEyelidsRelaxation, lineSelectedObject.upperEyelidsRelaxation)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let upperEyelidsRelaxation of target.upperEyelidsRelaxation; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="upperEyelidsRelaxation.IsValue" [disabled]="publishedTemplate" [text]="upperEyelidsRelaxation.Text" (onBooleanChanged)="onChangeData(target.upperEyelidsRelaxation, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.upperEyelidsSurfaceArea && target.upperEyelidsSurfaceArea.length > 0">
      <div class="col-12">
        <dna-checkbox class="d-inline-block" [text]="'UPPEREYELIDS_SURFACE_AREA'" ngbTooltip="{{ (lineSelectedObject.upperEyelidsSurfaceArea ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.upperEyelidsSurfaceArea"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.upperEyelidsSurfaceArea = selectUnselectLine(target.upperEyelidsSurfaceArea, lineSelectedObject.upperEyelidsSurfaceArea)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let upperEyelidsSurfaceArea of target.upperEyelidsSurfaceArea; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="upperEyelidsSurfaceArea.IsValue" [disabled]="publishedTemplate" [text]="upperEyelidsSurfaceArea.Text" (onBooleanChanged)="onChangeData(target.upperEyelidsSurfaceArea, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.wrinklesUnderEye && target.wrinklesUnderEye.length > 0">
      <div class="col-12">
        <dna-checkbox class="d-inline-block" [text]="'WRINKLES_UNDER_EYE'" ngbTooltip="{{ (lineSelectedObject.wrinklesUnderEye ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.wrinklesUnderEye"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.wrinklesUnderEye = selectUnselectLine(target.wrinklesUnderEye, lineSelectedObject.wrinklesUnderEye)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let wrinklesUnderEye of target.wrinklesUnderEye; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="wrinklesUnderEye.IsValue" [disabled]="publishedTemplate" [text]="wrinklesUnderEye.Text" (onBooleanChanged)="onChangeData(target.wrinklesUnderEye, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom" *ngIf="target.darkCircles && target.darkCircles.length > 0">
      <div class="col-12">
        <dna-checkbox class="d-inline-block" [text]="'DARK_CIRCLES_UNDER_EYES'" ngbTooltip="{{ (lineSelectedObject.darkCircles ? 'UNCHECK_ALL' : 'CHECK_ALL') | translate }}" [isChecked]="lineSelectedObject.darkCircles"
          [disabled]="publishedTemplate" (onBooleanChanged)="lineSelectedObject.darkCircles = selectUnselectLine(target.darkCircles, lineSelectedObject.darkCircles)">
        </dna-checkbox>
      </div>
      <div class="col-auto">
        <div class="row">
          <div *ngFor="let darkCircle of target.darkCircles; let index = index" class="col-auto">
            <dna-checkbox class="d-inline-block" [isChecked]="darkCircle.IsValue" [disabled]="publishedTemplate" [text]="darkCircle.Text" (onBooleanChanged)="onChangeData(target.darkCircles, index)">
            </dna-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
