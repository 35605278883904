import { ModalGroupVolunteersComponent } from './../../../../shared/modalGroupVolunteers/modalGroupVolunteers.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GroupVolunteers, Volunteers } from './../../../../types';

import {of as observableOf,
  Observable,
  of,
  throwError,
  Subscription
} from 'rxjs';

import { filter, finalize, tap, flatMap, catchError, map} from 'rxjs/operators';
import {
  ActivatedRoute,
  Router,
  ParamMap,
  NavigationStart
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';
import * as XLSX from 'xlsx';

import {
  AccountableService
} from '../../../accountable.service';
import {
  Campaign,
  CampaignStates,
  Languages,
  SynergyDemand,
  TableHeader,
  User,
  UserInCampaign,
  Workspace,
  Arcs,
  UserCampaign,
  CampaignUsers
} from '../../../../types';
import {
  CampaignService
} from '../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import {
  HttpRestService
} from '../../../../shared/services/httpRest.service';
import {
  SynergyDemandService
} from '../../../../shared/services/synergy-demand.service';
import {
  UserService
} from '../../../../shared/services/user.service';
import {
  UtilService
} from '../../../../shared/services/util.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-campaign-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit, AfterViewInit {

  campaign: CampaignUsers;
  campaignOriginal: CampaignUsers;
  campaignStates: typeof CampaignStates = CampaignStates;
  currentLanguage: Languages;
  currentWorkspace: Workspace = new Workspace();
  data: any;
  enableDropMode: boolean = false;
  enableDropModeVolunteer: boolean = false;
  horsArcs: any = [];
  isClickSave: boolean = false;
  isDisabled: boolean = false;
  showSpinner: boolean = false;
  submitted: boolean = false;
  userTableHeaders: Observable<TableHeader[]>;
  volunteerTableHeaders: Observable<TableHeader[]>;
  user: User;
  users: User[];
  error: boolean = false;
  initTime = performance.now();
  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  teams: any[];
  routerSubscription: Subscription;
  currentUrl: string;

  @ViewChild('userForm') userForm: NgForm;

  constructor(
    private accountableService: AccountableService,
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private errorManager: ErrorManagerService,
    private httpRestService: HttpRestService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private router: Router,
    private synergyDemandService: SynergyDemandService,
    private userService: UserService,
    private utilService: UtilService,
    private modalService: NgbModal
  ) { 
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.users = this.campaignService.users;
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.user = this.userService.getUser();
    this.currentWorkspace = this.user.currentWorkspace;
    this.init();
    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
      this.userTableHeaders = this.utilService.createHeadersDropUser();
      this.volunteerTableHeaders = this.utilService.createHeadersDropVolunteer();
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Users', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.error = false;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.campaignService.getCampaignUsers(params.get("idCampaign"))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaign: CampaignUsers) => this.campaignOriginal = campaign),
      tap((campaign: CampaignUsers) => this.horsArcs = campaign.groupsPanelistsHorsArcs),
      tap((campaign: CampaignUsers) => this.campaignOriginal.users.accountables = this.utilService.addNamesToUsers(_.get(campaign, 'users.accountables', []), this.users)),
      tap((campaign: CampaignUsers) => this.isDisabled = this.utilService.isNotEditable(campaign.state)),
      tap((campaign: CampaignUsers) => this.campaignService.updateBreadCrumb(campaign.name)),
      tap((campaign: CampaignUsers) => campaign.actiview ? this.getActiviewTeams(campaign.actiview) : this.showSpinner = false)
    ).subscribe(() => {
      if(!this.campaignOriginal.users.areUserSettingsAlreadySet)
      {
        this.campaignOriginal.users.isCollaborative = this.currentWorkspace.preferences.users.collaborative.defaultValue;
        this.campaignOriginal.users.isVolunteer = this.currentWorkspace.preferences.users.volunteers.defaultValue;
      }
      this.campaign = _.cloneDeep(this.campaignOriginal);
      this.userTableHeaders = this.utilService.createHeadersDropUser();
      this.volunteerTableHeaders = this.utilService.createHeadersDropVolunteer();

      if(this.campaignOriginal.state === "PUBLISHED") {
        for (const volunteer of this.campaignOriginal.users.volunteers) {
          if (volunteer.isActive === false) {
            this.enableDropModeVolunteer = true;
          }
        }
      }
    });

  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.campaign, this.campaignOriginal);
  }

  canIRemoveThisElement(element: any, type: string, id: string) {
    if (this.campaign.state == CampaignStates.Published && this.campaign.users.isVolunteer && type.endsWith('accountables') && this.campaign.users.accountables.length === 0) {
      this.errorManager.displayMessage("ON_ERROR_USER_STUDY_NUMBER", 'warning');
      this.utilService.getSubObject(this.campaign, type).push(element);
    }
  }

  isInOriginalCampaign(element: any, type: string, id: string) {
    return _.find(this.utilService.getSubObject(this.campaignOriginal, type), (o: any) => o[id] == element[id]);
  }

  onCancel() {
    this.campaign = _.cloneDeep(this.campaignOriginal);
  }

  isChangedCampaign() {
    return this.utilService.isDifferent(this.campaignOriginal, this.campaign);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary'
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <any>(XLSX.utils.sheet_to_json(ws, {
        header: 1
      }));
      for (var i = 0; i < this.data.length; i++) {
        for (var j = 0; j < this.users.length; j++) {
          if (this.data[i][0] == this.users[j].name) {
            var temp = new UserInCampaign();
            temp.name = this.users[j].name;
            temp.key = this.users[j].key;
            // temp.pzone = this.users[i].pzone;
            this.campaign.users.accountables.push(temp)
          }
        }
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onUserAdded(user: {
    key: string,
    name: string,
    pzone: number,
    peopleUserKey: string
  }) {
    let userInCampaign = new UserInCampaign();
    userInCampaign.key = user.key;
    userInCampaign.name = user.name;
    userInCampaign.pzone = user.pzone;
    userInCampaign.peopleUserKey = user.peopleUserKey;
    let index = this.campaign.users.accountables.findIndex(u => u.key === user.key);
    this.campaign.users.accountables[index] = userInCampaign;
  }

  save(form: NgForm) {
    form ? this.submitted = true : '' ;
    this.showSpinner = true;
    this.accountableService.setCurrentAccountables(this.campaign.users.accountables);
    this.campaign.users.areUserSettingsAlreadySet = true;

    let campaignToSend = _.cloneDeep(this.campaign);
    campaignToSend.users = this.updateVolunteerIsActive(this.campaign.users);
    const linkToCampaign$ = of(campaignToSend).pipe(
      filter((c: Campaign) => c.synergy && typeof c.synergy.requestNumber !== "undefined" && c.synergy.requestNumber.length > 0),
      flatMap((c: Campaign) => this.synergyDemandService.getSynergyDemandById(c.synergy.requestNumber)),
      flatMap((synergyDemand: SynergyDemand) => this.updateSynergyDemandContributorsAndReferent(synergyDemand, campaignToSend))
      )
    this.campaignService.putCampaignUsers(campaignToSend).pipe(
      tap(() => this.updateUserCampaign()), // dans le cas où une étude est déjà publiée, on force la maj de la collection accountStudies (fix car la maj n'était faite qu'à la publication sinon)
      finalize(() => this.showSpinner = false),
      tap(() => linkToCampaign$.subscribe()),)
      .subscribe((result) => {
        this.campaign = _.cloneDeep(campaignToSend);
        if (!_.isEmpty(_.get(result, 'errorDelete.volunteers', []))) {
          this.campaign.users.volunteers = _.cloneDeep(result.users.volunteers);
          const names = _.get(result, 'errorDelete.volunteers').map(vol => `${vol.name}`).join(', ');
          this.DNATranslate.translateMessage("ON_SUCCESS_UPDATE_WITH_ERRORS_VOLUNTEERS", { idVolunteers: names}).subscribe((translated) => {
            this.errorManager.displayMessage(translated, 'warning', {timeOut:10000}, false);
          });
        }
        else if (!_.isEmpty(_.get(result, 'errorDelete.users', []))) {
          this.campaign.users.accountables = this.utilService.addNamesToUsers(_.get(result, 'users.accountables', []), this.users);
          const names = _.get(result, 'errorDelete.users').map(user => `${_.get(this.users.find(usr => usr.key === user.key), 'name', '')}`).join(', ');
          this.DNATranslate.translateMessage("ON_SUCCESS_UPDATE_WITH_ERRORS_USERS", { idUsers: names}).subscribe((translated) => {
            this.errorManager.displayMessage(translated, 'warning', {timeOut:10000}, false);
          });
        } else if(form) {
          this.errorManager.displayMessage("ON_SUCCESS_UPDATE");
          this.router.navigate(['campaigns', campaignToSend.id, 'edit', 'formulas']);
        } else {
          this.errorManager.displayMessage("ON_SYNCHRO_CONTRIBUTORS_ACTIVIEW");
        }

        this.campaignOriginal = _.cloneDeep(this.campaign);
        this.isClickSave = true;
      },
      error => { error?.error?.message ? this.errorManager.displayMessage(error.error.message, "danger") :
        this.errorManager.displayMessage("ON_ERROR_FORM", "danger")
      });
  }

  sort(accountables: UserInCampaign[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case 'USERS':
        return this.utilService.sortListByType(accountables, 'name', reverse);
      case 'VOLUNTEERS':
        return this.utilService.sortListByType(accountables, 'name', reverse);
    }
  }

  updateCollaborative(isVolunteer) {
    this.campaign.users.isVolunteer = isVolunteer;
    // comportement souhaité : activer le mode collaboratif automatiquement si volontaire, mais pas le désactiver si mode volontaire désactivé
    if(this.campaign.users.isVolunteer) this.campaign.users.isCollaborative = isVolunteer;
  }

  toggleHorsArcs(isVolunteerHorsArcs) {
    const volunteerList = [];

    this.campaign.users.isHorsArcsVolunteer = isVolunteerHorsArcs;

    for (const groupHorsArcs of this.horsArcs)
    {
      for (const panelistHorsArcs of groupHorsArcs.panelists)
      {
          volunteerList.push(panelistHorsArcs.panelistNumber);
      }
    }

    if (isVolunteerHorsArcs)
    {
      for (const panelistHorsArcs of volunteerList)
      {
        if (!this.campaign.users.volunteers.find((volunteer) => volunteer.name === panelistHorsArcs))
          this.campaign.users.volunteers.push( {name: panelistHorsArcs} );
      }
    }
    else
    {
      for (const panelistHorsArcs of volunteerList)
      {
        const indexOfPanelist = this.campaign.users.volunteers.findIndex((volunteer) => volunteer.name === panelistHorsArcs);
        if (indexOfPanelist !== -1)
          this.campaign.users.volunteers.splice(indexOfPanelist, 1);
      }
    }

  }

  updateSynergyDemandContributorsAndReferent(synergyDemand: SynergyDemand, campaign: Campaign): Observable<SynergyDemand> {
    synergyDemand.contributors = campaign.users.accountables;
      synergyDemand.referent = _.get(synergyDemand,'referent', []).reduce((array, ref) => {
        if(campaign.users.accountables.findIndex(acc => acc.key === ref.key) !== -1){
          array.push(ref);
        }
        return array;
      }, [])
    if(_.isEmpty(synergyDemand.referent)) synergyDemand.referent.push(campaign.users.accountables[0]);
    return this.synergyDemandService.patchSynergyDemand(synergyDemand, campaign.id);
  }

  updateVolunteersHorsArcs(group: GroupVolunteers) {
    this.showSpinner = true;
    this.httpRestService.getGroupPanelistHorsArcs(group.hub, group.groupName).subscribe(
      {
        next : (gp: GroupVolunteers) => {
          const modal = this.modalService.open(ModalGroupVolunteersComponent, this.modalOption);
          modal.componentInstance.id = gp.id;
          modal.componentInstance.groupVolunteersOriginal = gp;
          modal.componentInstance.horsArcsSystem = gp.hub;
          modal.componentInstance.isAssociateStudyPossible = true;
          modal.componentInstance.isUserAdmin = this.user.roles.includes('DNA_ADMIN');
          modal.result.then(
            (data) => {
              this.route.parent.paramMap.pipe(
                flatMap((params: ParamMap) => this.campaignService.getCampaignUsers(params.get("idCampaign"))),
                tap((campaign) => {
                  this.horsArcs = campaign.groupsPanelistsHorsArcs
                  this.campaignOriginal.users.volunteers = campaign.users.volunteers;
                  this.campaign.users.volunteers = this.campaign.users.volunteers.filter((vol) => !vol.name.startsWith(group.groupName));
                  campaign.users.volunteers.forEach((vol) => {
                    if (vol.name.startsWith(group.groupName)) {
                      this.campaign.users.volunteers.push(vol);
                    }
                  });
                  this.showSpinner = false;
                })
              ).subscribe();
              this.showSpinner = false;
            },
            (reason) => {
              this.showSpinner = false;
            }
          ).catch(() => {
            this.showSpinner = false;
            this.errorManager.displayMessage('ERROR_OPEN_GROUP_HORSARCS', 'danger', {}, true);
          });
        },
        error : (err: any) => {
          this.showSpinner = false;
          console.log("Error when get group", err);

          this.errorManager.displayMessage('ERROR_GET_GROUP_HORSARCS', 'danger', {}, true);
        }
      }
    );

  }

  updateVolunteers(arcs: Arcs) {
    this.showSpinner = true;
    this.httpRestService.getArcsInformation(arcs.arcsSystem, arcs.arcsStudy).pipe(
      finalize(() => this.showSpinner = false))
      .subscribe(users => {
        arcs = this.utilService.getVolunteersForProject(users.list, arcs.arcsSystem, arcs.arcsStudy);
        const volunteersForCampaign = this.utilService.getVolunteersForCampaign(arcs);
        this.campaign.users.volunteers = _.uniqBy(volunteersForCampaign.concat(this.campaign.users.volunteers), 'name');
      });
  }

  updateVolunteerIsActive(users: UserCampaign) {
    if (users.isVolunteer && !_.isEmpty(_.get(users, 'volunteers', []))) {
      users.volunteers.filter(volunteer => _.isUndefined(volunteer.isActive)).forEach(volunteer => {
        volunteer.isActive = true;
      })
    }
    return users;
  }

  private updateUserCampaign()
  {
    this.showSpinner = true;
    this.campaignService.getCurrentCampaign(this.campaign.id).pipe(
      map(campaign => this.campaignService.updateUserCampaign(campaign).subscribe()),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  getActiviewTeams(actiview: any) {
    this.showSpinner = true;
  
    if (actiview?.activityNumber) {
      return this.httpRestService.getActiview(actiview.activityNumber, true).pipe(
        map(actiview => {
          this.teams = actiview.output?.teams;
          const peopleUserKeys = actiview.output?.teams.map((user) => user.x_fst_userid);
          this.synchroUsersActiview(peopleUserKeys, this.users);
        }),
        catchError((error) => {
          console.error('Error fetching actiview teams:', error);
          return of(null);
        }),
        finalize(() => this.showSpinner = false)
      ).subscribe();
    } else {
      this.showSpinner = false;
    }
  }

  synchroUsersActiview(peopleUserKeys: string[], allUsers: any[]) {
    const usersToAdd = allUsers.filter(user => peopleUserKeys.includes(user.peopleUserKey));
    usersToAdd.forEach(user => {
      const existingUserIndex = this.campaign.users.accountables.findIndex(accountable => accountable.key === user.key);
  
      if (existingUserIndex === -1) {

        let userInCampaign = new UserInCampaign();
        userInCampaign.key = user.key;
        userInCampaign.name = user.name;
        userInCampaign.peopleUserKey = user.peopleUserKey;
        delete userInCampaign.pzone;
        delete userInCampaign.pdata;
        this.campaign.users.accountables.push(userInCampaign);
      }
    });
  
    this.campaign.users.accountables = [...this.campaign.users.accountables]; 
    if(this.campaign.users.accountables.length > 0){
      this.save(this.userForm);
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
