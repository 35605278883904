import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { VolunteerService } from '../../volunteers/volunteers.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ErrorManagerService } from '../services/errorManager.service';
import { GroupVolunteers, VolunteerOS, StudyGroupVolunteers, Hub, HubById, ExportVolunteerOS, User } from '../../types';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import { ImportExcelModalComponent } from '../../campaigns/detail/edit-campaign/publication/import-excel/import-excel-modal.component';
import * as XLSX from 'xlsx';
import { ReferenceTypeService } from '../services/reference-type.service';
import { finalize } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {
  TranslateService
} from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dna-group-volunteers',
  templateUrl: './modalGroupVolunteers.component.html',
  styleUrls: ['./modalGroupVolunteers.component.css']
})

export class ModalGroupVolunteersComponent implements OnInit {

  @Input() studyId: string = "";
  @Input() studyName: string = "";
  @Input() studyHub: Hub;
  @Input() isAssociateStudyPossible :boolean=false;
  @Input() horsArcsSystem :HubById;
  @Input() groupVolunteersOriginal: GroupVolunteers;
  @Input() isUserAdmin: Boolean;
  @Input() gvNumberVolunteers: number;
  @Input() areVolunteersAdded: boolean;
  @Input() numberVolunteersModal: number;


  editionMode: boolean = false;
  titleCreate: string = "CREATE_GROUP_VOLUNTEERS";
  titleEdit: string = "EDIT_GROUP_VOLUNTEERS";
  groupVolunteers: GroupVolunteers;
  showSpinner: boolean = false;
  hubs: Hub[] = [];
  oldvaluePanelistShortNumber: number[] = [];
  hub: Hub;
  public isPanelistLocked: boolean[] = [];
  addedGroupName = "";
  routerSubscription: Subscription;
  currentUrl: string;
  
  modalOption: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "sm"
  };
  hasHorsArcsSystem : boolean = false;
  constructor(
    private volunteerService: VolunteerService,
    private errorManager: ErrorManagerService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
   }

  isPanelistToBeLocked(panelist): boolean
  {
    return !this.isUserAdmin && panelist.panelistShortNumber !== undefined && panelist.firstName && panelist.lastName && panelist.firstName !== '' && panelist.lastName !== ''
  }

  ngOnInit() {
    this.hubs = this.referenceTypeService.getHubs();
    if (_.isUndefined(this.groupVolunteersOriginal)) {
      this.groupVolunteersOriginal = new GroupVolunteers(v4());
      this.groupVolunteersOriginal.hub = this.referenceTypeService.getHubByIdFromHub(this.studyHub)
    } else {
      this.hub = this.referenceTypeService.getHubFromHubById(this.groupVolunteersOriginal.hub);
      this.editionMode = true;
      if (_.isUndefined(this.groupVolunteersOriginal.panelists)) {
        this.groupVolunteersOriginal.panelists = [];
      }
      if (_.isUndefined(this.groupVolunteersOriginal.studies)) {
        this.groupVolunteersOriginal.studies = [];
      }
      if (this.editionMode){
        const panelists = this.groupVolunteersOriginal.panelists;
        this.isPanelistLocked = new Array(panelists.length).fill(false)
        for (let i = 0; i < panelists.length; i++)
        {
          this.isPanelistLocked[i] = this.isPanelistToBeLocked(panelists[i]);
        }
      }
    }
    
    if(this.studyId && this.studyName){
      this.groupVolunteersOriginal.studies.push({
        studyId: this.studyId,
        studyName: this.studyName,
      });
    }
    
    if(this.horsArcsSystem){
      this.hub = this.referenceTypeService.getHubFromHubById(this.groupVolunteersOriginal.hub);
      this.groupVolunteersOriginal.hub = this.horsArcsSystem;
      this.hasHorsArcsSystem = true;
    }

    this.groupVolunteers = _.cloneDeep(this.groupVolunteersOriginal);
    this.numberVolunteersModal = this.groupVolunteersOriginal.numberOfPanelists;
  }

  hasChanged() {
    return !_.isEqual(this.groupVolunteersOriginal, this.groupVolunteers);
  }

  alertPanelistShortNumber(value) {
    const shortNumberExists = this.editionMode && this.groupVolunteersOriginal.panelists && 
      this.groupVolunteersOriginal.panelists.filter(p => p.panelistShortNumber === value).length > 0;
  
    if (shortNumberExists) {
      this.errorManager.displayMessage('ALERT_CHANGE_KEY_PANELIST', 'warning');
    }
  }

  save() {
    this.showSpinner = true;

    this.groupVolunteers.panelists.map((panelist) => {
      if (typeof(panelist.panelistShortNumber) === "string")
        panelist.panelistShortNumber = parseInt(panelist.panelistShortNumber)
    })

    this.groupVolunteers.groupName = this.groupVolunteers.groupName.trim();
    this.groupVolunteers.hub = this.referenceTypeService.getHubByIdFromHub(this.hub);
  
    let formNotComplete = this.groupVolunteers.groupName.length === 0 || 
      (this.groupVolunteers.numberOfPanelists > 0 && 
      this.groupVolunteers.panelists.filter(p => _.isNull(p.panelistShortNumber)).length > 0);

    if (formNotComplete) {
      this.errorManager.displayMessage('ERROR_COMPLETE_FORM', 'danger', undefined, true);
      this.showSpinner = false;
      return;
    }
    
    const shortNumberList = this.groupVolunteers.panelists.map(p => p.panelistShortNumber);
    const hasDuplicates = new Set(shortNumberList).size != shortNumberList.length;

    if (hasDuplicates) {
      this.errorManager.displayMessage('ERROR_DUPLICATE_PANELIST_SHORT_NUMBER', 'danger', undefined, true);
      this.showSpinner = false;
      return;
    }

    if (this.editionMode) {
      this.volunteerService.putVolunteersGroup(this.groupVolunteers).subscribe({
        next: (result) => {
          this.showSpinner = false;
          this.errorManager.displayMessage('ON_SUCCESS_UPDATE_GROUP');
          this.groupVolunteersOriginal = _.cloneDeep(this.groupVolunteers);
          //this.activeModal.close(this.groupVolunteers.groupName);
        },
        error: (err) => {
          console.log('Error on put', err);
          this.showSpinner = false;
          this.errorManager.displayMessage(`${err.statusText}: ${err.error}`, 'danger')
        }
      })

    } else {
      this.volunteerService.postVolunteersGroup(this.groupVolunteers).subscribe(
        (result) => {
          this.showSpinner = false;
          this.errorManager.displayMessage('ON_SUCCESS_CREATE_GROUP');
          this.groupVolunteersOriginal = _.cloneDeep(this.groupVolunteers);
          this.editionMode = true;
          this.addedGroupName = this.groupVolunteers.groupName;
          //this.activeModal.close(this.groupVolunteers.groupName);
        },
        (err) => {
          console.log(err)
          this.showSpinner = false;
          if (err.error === 'NAME'){
            this.errorManager.displayMessage('ON_ERROR_GV_NAME', 'danger');
          }
          
          this.errorManager.displayMessage(` ${err.statusText}: ${err.error}`, 'danger');
        }
      )
    }
  }

  cancel() {
    this.groupVolunteers = _.cloneDeep(this.groupVolunteersOriginal);
  }

  close() {
    this.activeModal.close(this.addedGroupName);
  }

  onDeleteVolunteersCheck() {
    this.groupVolunteers.numberOfPanelists = this.numberVolunteersModal;
    let numberVolunteers = this.groupVolunteers.numberOfPanelists;
    const groupPanelistInitialLength = this.groupVolunteers.panelists.length;
    
    while (numberVolunteers < groupPanelistInitialLength && !this.isPanelistLocked[numberVolunteers])
    {
      numberVolunteers++;
    }
    return numberVolunteers == groupPanelistInitialLength;
  }

  onChangeNumberVolunteers() {
    this.groupVolunteers.numberOfPanelists = this.numberVolunteersModal;
    const numberVolunteers = this.groupVolunteers.numberOfPanelists;
    if (this.groupVolunteers.panelists.length !== numberVolunteers) {
      if (this.groupVolunteers.panelists.length < numberVolunteers) {
        //on ajoute des lignes
        let lastShortNumber: number = 0;
        if (this.groupVolunteers.panelists && this.groupVolunteers.panelists.length > 0)
          lastShortNumber = parseInt(_.last(this.groupVolunteers.panelists).panelistShortNumber);
        const volunteersToAdd = numberVolunteers - this.groupVolunteers.panelists.length;
        for (let i:number = 0; i < volunteersToAdd; i++) {
          this.groupVolunteers.panelists.push(new VolunteerOS(lastShortNumber + i + 1, "", ""));
          this.isPanelistLocked.push(false);
        }
      } else {
        //on supp des lignes
        const isVolunteerDeletable = this.onDeleteVolunteersCheck()
        if (this.isUserAdmin || !this.editionMode || isVolunteerDeletable)
        {
          this.groupVolunteers.panelists = this.groupVolunteers.panelists.slice(0, numberVolunteers);
          this.isPanelistLocked = this.isPanelistLocked.slice(0, numberVolunteers);
        }
        if (!isVolunteerDeletable)
        {
          this.gvNumberVolunteers = this.groupVolunteers.panelists.length;
          this.groupVolunteers.numberOfPanelists = this.groupVolunteers.panelists.length;
          this.numberVolunteersModal = this.groupVolunteers.panelists.length;
          this.errorManager.displayMessage('CANT_REMOVE_PANELIST', "danger");
        }
      }
      this.groupVolunteers.panelists.sort(function (a, b) { return a.panelistShortNumber - b.panelistShortNumber });
      this.groupVolunteers.numberOfPanelists = numberVolunteers;
      this.numberVolunteersModal = numberVolunteers;
    }
  }

  onUpdateStudies(studies: StudyGroupVolunteers[]){
    this.groupVolunteers.studies = studies;
  }

  public jsonToArray(panelistsList: ExportVolunteerOS[]): any[]
  {
    let panelistArray = []
    panelistArray.push [this.groupVolunteers.groupName, "numberInc", "firstName", "lastName"]
    panelistsList.forEach(panelist => {
      if (typeof(panelist.numberInc) === "string")
        panelist.numberInc = parseInt(panelist.numberInc);
      panelistArray.push(["", panelist.numberInc, panelist.firstName, panelist.lastName])
    })

    return panelistArray;
  }

  export() {
    const fileName = (this.groupVolunteers.groupName).replace(/\//g, "_") + '.xlsx';
    let panelistsToExport = this.groupVolunteers.panelists.map(panelist => 
        new ExportVolunteerOS(panelist.panelistShortNumber, panelist.firstName, panelist.lastName));
    const panelistArray = this.jsonToArray(panelistsToExport)
    panelistArray.unshift([this.groupVolunteers.groupName, 'numberInc', 'firstName', 'lastName']);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(panelistArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, fileName);
  }
  exportRapportAgences(){
      this.showSpinner = true;
     
      let panelistsToExport = this.groupVolunteers.panelists.map(panelist => 
         {
           return { 
            id: panelist.id,
            numberInc : panelist.panelistShortNumber,
            firstName: panelist.firstName,
            lastName : panelist.lastName,
          }
        }
      );
      const listStudyIds =  this.groupVolunteers.studies.map((study)=>study.studyId)
      this.volunteerService.exportHttpRapportAgences(panelistsToExport, listStudyIds, this.groupVolunteers.groupName.replace(/\//g, "~")).pipe(
        finalize(() => this.showSpinner = false)
      ).subscribe(
        blob => FileSaver.saveAs(blob, `Rapport_${this.groupVolunteers.groupName}_${new Date().getDate()}${new Date().getMonth() + 1}${new Date().getFullYear()}.xlsx`), 
        err => this.errorManager.catchError(err)
      );
  }

  import() {
    const modal = this.modalService.open(ImportExcelModalComponent, this.modalOption);
    modal.result.then((excel) => {
      if (!_.isEmpty(excel)) {
        this.readExcel(excel);
      }
    }, (reason) => { });
  }

  readExcel(excel: any) {
    /* wire up file reader */
    this.showSpinner = true;
    const target: DataTransfer = <DataTransfer>(excel.target);
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data: ExportVolunteerOS[] = XLSX.utils.sheet_to_json(ws).map(d => _.omit(d, '__rowNum__'));
      data.sort(function (a, b) { return a.numberInc - b.numberInc });
      this.updatePanelistsInGroupFromExcel(data);
      this.showSpinner = false;
    };
  }
  
  private updatePanelistsInGroupFromExcel(data : ExportVolunteerOS[]) {
    this.groupVolunteers.numberOfPanelists = data.length;
    this.numberVolunteersModal = this.groupVolunteers.numberOfPanelists
    this.groupVolunteers.panelists = data.map((panelistToAdd: ExportVolunteerOS) => {
      panelistToAdd.firstName = this.keepFirstThreeLetters(panelistToAdd.firstName);
      panelistToAdd.lastName = this.keepFirstThreeLetters(panelistToAdd.lastName);
      let volunteer = this.groupVolunteers.panelists.find((p: VolunteerOS) => p.panelistShortNumber === panelistToAdd.numberInc);
      if (_.isUndefined(volunteer)) {
        volunteer = new VolunteerOS(panelistToAdd.numberInc, panelistToAdd.firstName, panelistToAdd.lastName);
      } else {
        volunteer.firstName = panelistToAdd.firstName;
        volunteer.lastName = panelistToAdd.lastName;
      }
      return volunteer;
    });
  }

  private keepFirstThreeLetters(input:string)
  {
    if (input && input.length > 3)
      input = input.substring(0,3);

    return input
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}