import { NgModule } from '@angular/core';
import {
  ChartComponent,
  ChartTileComponent,
  GraphOptionsComponent,
  TableRawDataComponent,
  TableGraphComponent,
  ScalebarComponent,
  ChartScalebarComponent,
  ButtonComponent,
  ChartTableComponent,
  SingleInvertedBarchartComponent,
  IncreaseDecreaseSelectionComponent,
  ChartTileSkeletonComponent,
  FiltersComponent,
  DnaLoaderComponent,
  MessageBannerComponent,
  ChartSmTableComponent,
  ScaleBarTooltipComponent,
  RoutinesContainerComponent,
  FiltersMultiComponent
} from './components';
import {
  ChartService,
  FilterService
} from './services';
import {
  ApplyTranslationPipe,
  IsNumberPipe,
  GroupByPipe,
  ApplyMultiTranslationsPipe,
  SafeHtmlPipe,
  IsExistingSymbolPipe
} from './pipes';

import { SharedModule } from '@app/shared/shared.module';
import { GroupCheckboxComponent } from './components/group-checkbox/group-checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ChartComponent,
    ChartTileComponent,
    GraphOptionsComponent,
    TableRawDataComponent,
    ButtonComponent,
    ChartTableComponent,
    TableGraphComponent,
    ScalebarComponent,
    ChartScalebarComponent,
    SingleInvertedBarchartComponent,
    IncreaseDecreaseSelectionComponent,
    ChartTileSkeletonComponent,
    FiltersComponent,
    ApplyTranslationPipe,
    DnaLoaderComponent,
    MessageBannerComponent,
    ChartSmTableComponent,
    IsNumberPipe,
    ScaleBarTooltipComponent,
    RoutinesContainerComponent,
    GroupByPipe,
    ApplyMultiTranslationsPipe,
    SafeHtmlPipe,
    IsExistingSymbolPipe,
    GroupCheckboxComponent,
    FiltersMultiComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    ChartComponent,
    ChartTileComponent,
    GraphOptionsComponent,
    TableRawDataComponent,
    ChartTableComponent,
    ChartTileSkeletonComponent,
    FiltersComponent,
    MessageBannerComponent,
    GroupCheckboxComponent,
    FiltersMultiComponent
  ],
  providers: [
    ChartService,
    FilterService
  ]
})
export class GraphicsModule { }
