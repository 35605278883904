
import {of as observableOf, throwError as observableThrowError,
  Observable
,  Subscription,
of} from 'rxjs';

import {mergeMap, catchError, finalize, tap} from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationStart,
  ParamMap,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  ActionBarButton,
  ActionTypes,
  Block,
  DNATypes,
  Language,
  Pager,
  States,
  TableHeader,
  User
} from '../../types';
import {
  ApplicationInsightsService
} from '../../shared/services/applicationInsights.service';
import {
  BlockService
} from '../blocks.service';
import {
  CreateLibraryItemModalComponent
} from '../../shared/modals/create-library-item/create-library-item-modal.component';
import {
  DNATranslateService
} from '../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../shared/services/errorManager.service';
import {
  FilterService
} from '../../shared/services/filter.service';
import {
  StateService
} from '../../shared/services/state.service';
import {
  UserService
} from '../../shared/services/user.service';
import {
  UtilService
} from '../../shared/services/util.service';

@Component({
  selector: 'dna-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.less']
})

export class BlocksComponent implements OnInit, AfterViewInit, OnDestroy {

  blocks: Block[];
  currentLanguage: Language;
  filter: any;
  modalOption: NgbModalOptions = {};
  showSpinner: boolean = true;
  states: any;
  subscribeLang: Subscription;
  subscribeParam: Subscription;
  subscribeUser: Subscription;
  user: User;
  isCollapsedBlocksFilter: boolean = false;
  totalItems: number;
  error: boolean = false;
  initTime = performance.now();
  routerSubscription: Subscription;
  currentUrl: string;

  tableHeaders$: Observable<TableHeader[]>;

  constructor(
    private aiService: ApplicationInsightsService,
    private blockService: BlockService,
    private dnaTranslateService: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private userService: UserService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef
  ) 
  {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Blocks', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.blockService.blockUntouched = null;
    this.blockService.block = null;
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Block);
    this.user = this.userService.getUser();

    this.blocks = this.blockService.getBlocksInLocal();
    this.currentLanguage = this.dnaTranslateService.getLanguage();
    this.filter = this.filterService.getFilter().blocks;
    this.states = this.stateService.getVisibleStates(this.stateService.BLOCKS);

    this.subscribeParam = this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('state'))
        this.filter.states = [params.get('state')];
    });

    this.updateBlocks();

    this.subscribeLang = this.dnaTranslateService.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
      this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Block);
    });

    this.subscribeUser = this.userService.onUserChanged().subscribe((user: User) => {
      this.showSpinner = true;
      this.currentLanguage = user.language;
      this.user.currentWorkspace = user.currentWorkspace;
      this.updateBlocks();
    });
  }

  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
    this.subscribeParam.unsubscribe();
    this.subscribeUser.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  deleteBlock(block: Block): Observable<Block> {
    this.showSpinner = true;
    return this.blockService.deleteBlock(block.id).pipe(
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }));
  }

  getBlockIfDuplicate(idBlockToDuplicate): Observable<any> {
    if (idBlockToDuplicate) {
      return this.blockService.getBlock(idBlockToDuplicate);
    } else {
      return observableOf({
        components: []
      });
    }
  }

  onClickActionButton(actionButton: ActionBarButton, idBlock: string) {
    const block = this.blocks.find(b => b.id === idBlock);
    const blockName = this.dnaTranslateService.getTranslation(block.name, this.currentLanguage);
    switch (actionButton.id) {
      case ActionTypes.Remove:
        this.utilService.translateMessageModal('CONFIRM_DELETE', blockName, 'THE_BLOCK').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.removeBlock(block)),)
          .subscribe(() => this.updateBlocks());
        break;
      case ActionTypes.PutBack:
        this.utilService.translateMessageModal('CONFIRM_PUT_BACK', blockName, 'THE_BLOCK').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.suspendBlock(idBlock)),)
          .subscribe(() => this.updateBlocks());
        break;
      case ActionTypes.Duplicate:
        this.openModalNewBlock(idBlock);
        break;
      case ActionTypes.Previewing:
        this.router.navigate(['blocks', idBlock, 'previewComponents']);
        break;
      case ActionTypes.Edit:
        this.showSpinner = true;
        this.router.navigate(['blocks', idBlock]).catch(() => {
          this.showSpinner = false;
          this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
        });
        break;
      case ActionTypes.Delete:
        this.utilService.translateMessageModal('CONFIRM_DELETE', blockName, 'THE_BLOCK').pipe(
          mergeMap(modalContent => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.deleteBlock(block)))
          .subscribe(() => this.updateBlocks());
        break;
    }
  }

  onParametersChanged(event: {pager: Pager, type: string}) {
    _.set(this.filter, 'pageIndex', _.get(event, 'pager.currentPage', 1));
    _.set(this.filter, 'numberOfObjectsPerPage', _.get(event, 'pager.pageSize', 1));
    this.updateBlocks();
  }

  onSendDatalakeData(event: any) {
    this.updateBlocks();
  }

  onSuccessDelete(block: Block) {
    _.remove(this.blocks, block);
    this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
  }

  onSuccessUpdateState(block: Block) {
    let findBlockToUpdate = this.blocks.find(blockInList => blockInList.id === block.id);
    this.blocks[this.blocks.findIndex(b => b.id === block.id)] = block;
    this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
  }

  openModalNewBlock(idBlockToDuplicate = '') {
    this.modalOption.size = "lg";
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalNew = this.modalService.open(CreateLibraryItemModalComponent, this.modalOption);
    modalNew.componentInstance.data = new Block();
    modalNew.componentInstance.faIcon = 'fa-list-ul';
    modalNew.componentInstance.title = 'CREATE_BLOCK';
    modalNew.componentInstance.type = this.stateService.BLOCKS;
    modalNew.result.then(result => {
      this.showSpinner = true;
      let request$;
      if (!_.isEmpty(idBlockToDuplicate)) {
        request$ = this.blockService.duplicateBlock(idBlockToDuplicate, result)
      } else {
        request$ = this.blockService.createBlock(result);
      }
      request$.pipe(
        catchError(this.catchError),
        tap((block: Block) => {
          this.blockService.block = block;
          this.blockService.blockUntouched = _.cloneDeep(block);
          this.showSpinner = false;
          this.router.navigate(['blocks', block.id]);
        })
        ).subscribe();
    }).catch((err) => {
      this.router.navigate(['blocks']);
    });
  }

  sort = (headerId: string, reverse: boolean) => {
    switch (headerId) {
      case 'NAME':
        _.set(this.filter, 'orderBy', `name.${this.currentLanguage}`);
        break;
      case 'STATE':
        _.set(this.filter, 'orderBy', `state`);
        break;
      case 'DATE_CREATION':
        _.set(this.filter, 'orderBy', 'created_on');
        break;
      case 'DATE_MODIFICATION':
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
      default:
        _.set(this.filter, 'orderBy', 'updated_on');
        break;
    }
    reverse ? _.set(this.filter, 'order', 'ASC') : _.set(this.filter, 'order', 'DESC');
    this.updateBlocks();
  }

  updateBlocks() {
    this.showSpinner = true;
    this.blockService.getBlocks(this.filter).pipe(
      tap(resp => {
        this.blockService.setBlocks(resp.list);
        this.blocks = resp.list;
        this.totalItems = resp.totalItems;
      }),
      catchError(err => {
        this.error = true;
        return this.catchError(err);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      }))
      .subscribe(() => {},
      error => this.errorManagerService.catchError(error)
      );
  }

  removeBlock(block: Block): Observable<Block> {
    this.showSpinner = true;
    return this.blockService.removeBlock(block).pipe(
      finalize(() => {
        this.showSpinner = false;
      }),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }),);
  }

  suspendBlock(idBlock: string): Observable<Block> {
    this.showSpinner = true;
    return this.blockService.suspendBlock(idBlock).pipe(
      finalize(() => {
        this.showSpinner = false;
      }),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }),);
  }


  updateBlockState(block: Block, newState: States): Observable<Block> {
    this.showSpinner = true;
    return this.blockService.putBlockState(block, newState).pipe(
      finalize(() => {
        this.showSpinner = false;
      }),
      catchError(err => {
        this.errorManagerService.catchError(err);
        return observableThrowError(err);
      }),);
  }

  updateData(text: any, type: string, filter: any) {
    filter[type] = text;
  }

  catchError = (error) => {
    this.showSpinner = false;
    if (error.error.message == "name") {
      this.errorManagerService.displayMessage('NAME_ALREADY_TAKEN', 'danger')
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_UPDATE", "danger");
    }
    return of();
  }

}
