
import {takeUntil} from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Table } from '../../models';
import { Languages } from '../../enums';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-chart-table',
  templateUrl: './chart-table.component.html',
  styleUrls: ['./chart-table.component.scss']
})
export class ChartTableComponent implements AfterViewInit {
  @ViewChild('chart', { read: ElementRef }) chart: ElementRef;
  @Input() table: Table;
  @Input() lang : string;

  public Languages = Languages;
  public head: Array<string>;
  public body: Array<any>;
  public error : boolean = false;
  private colOrder: any = {};
  private oldPosition: any = {};

  public start
  public pressed
  public startX
  public startWidth
  public cellConfigs: any = { index: null, width: null };
  public dr : Array<any> = null;
  public langs : Array<string> = [];

  private unsubsriber$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) { };

  public onMouseDown(event) {
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = this.start.parentElement.clientWidth;
  };

  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    if (this.pressed) {
      let width = this.startWidth + (event['x'] - this.startX);
      this.start.parentElement.style['min-width'] = width;
      this.start.parentElement.style['max-width'] = width;
      let index = this.start.parentElement.cellIndex;
      this.cellConfigs = {
        width,
        index
      }
    }
  };

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.pressed) {
      this.pressed = false;
    }
  };

  public getCallbackOrder(index) {
    if (index == null) return;
    this.order(index);
  }

  public order = (index) => {
    // SAVE CELLS POSITION BEFORE CHANGE
    const flipComponents = document.querySelectorAll("[data-flip]");
    flipComponents.forEach(element => {
      this.oldPosition[element["dataset"].flip] = element.getBoundingClientRect().top;
    });

    // SET SELECTED COLUMN ORDER BY INDEX
    this.colOrder[index] = this.colOrder[index] === undefined ?
      true : !this.colOrder[index];

    // CALL TREE TO ORDER LINES
    this.table.parameters.tree.order(index, this.colOrder[index]);

    // COMPUTE NEW TABLE
    this.table.parameters.transformedHTMLData = this.table.parameters.tree.createTable();

    // CHANGE MODIFIED CELLS POSITION TO SAVED ONE
    requestAnimationFrame(() => {
      const flipComponents = document.querySelectorAll("[data-flip]");
      flipComponents.forEach(element => {
        const currentPosition = element.getBoundingClientRect();
        const transform = this.oldPosition[element["dataset"].flip] - currentPosition.top;
        element["style"].transition = "none";
        element["style"].transform = `translateY(${transform}px)`;
      });
      // CHANGE TO ACTUAL POSITION
      requestAnimationFrame(() => {
        flipComponents.forEach(element => {
          element["style"].transition = "all 0.3s ease";
          element["style"].transform = "translateY(0px)";
        });
      });
    });

  };

  getOrderSymbol(order) {
    switch (order) {
      case 1: {
        return ">";
      }
      case -1: {
        return "<";
      }
      default: {
        return "=";
      }
    }
  }

  getClass(order) {
    switch (order) {
      case 1: {
        return "order-button--up"
      }
      case -1: {
        return "order-button--down"
      }
      default: {
        return null;
      }
    }
  }

  getHeaderId(index, item) {
    return index;
  }

  getHeaderLineId(index, item) {
    return index;
  }

  getLineId(index, item) {
    return item[item.length - 1].branchId;
  }

  getColId(index, item) {
    return index.id;
  }

  ngAfterViewInit() {
    try {
      this.table.setContainer(this.chart.nativeElement);
      if( this.table.parameters.hasOwnProperty('decisionRules') && Object.keys(this.table.parameters.decisionRules).length) {
        this.dr = Object.values(this.table.parameters.decisionRules)
          .map(( x : any ) => {
            if ((x.hasOwnProperty("max") && x.max === 0) || (x.hasOwnProperty('min') && x.min === 0 )) x.color = '#455A64';
            else if( x.hasOwnProperty('min') && x.min > 0 ) x.color = this.table.routines.routines.find(( r : any ) : any  => !r.isBench).color;
            else if( x.hasOwnProperty('min') && x.min < 0 ) x.color = this.table.routines.routines.find(( r : any ) : any  => r.isBench).color;
            return x
          });
      }
      this.table.onOptionChangeSubject.pipe(
      takeUntil(this.unsubsriber$))
      .subscribe({
        next: (options: any) => {
          this.ref.detectChanges()
        }
      })
    }
    catch(e) {
      this.error = true;
    }
    this.ref.detectChanges();
  };

  /**
   * @method isColored
   * @description Method that checks if current node value must be colored by decision rules
   * @param { string | number | any } value
   * @param { Array<any> } decisionRules
   * @return { string | null } The color if found
   */
  public coloredCell = (value : string | number | any, decisionRules : Array<any> ) : string | null => {
    if ( !decisionRules || !decisionRules.length ) return _.get(value, 'color') || null;
    const foundDr = decisionRules.find(( dr : any ) : any => dr.value === value );
    return foundDr ? foundDr.color : null;
  }

  public getDisplayContent(node: any): string {
    if (node.value && node.value.value) {
      if (typeof node.value.value === 'object' && node.value.value !== null) {     
        return node.value.value.english || '';
    }
    return node.value.value
  }
    else if (node.value.translations && node.value.translations.value) {
      return node.value.translations.value.label;
    }
    return '';
  }

  ngOnDestroy() {
    this.unsubsriber$.next();
    this.unsubsriber$.complete();
  };

};
