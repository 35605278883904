import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Content, GridContentType, Image,  ModalContent,  Page} from '../../../types';
import {ImageModalComponent} from '../../modals/image-modal/image-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../services/util.service';
import * as _ from 'lodash';
import {ErrorManagerService} from '../../services/errorManager.service';
import { of, Subscription } from 'rxjs';
import { tap, flatMap, catchError, take } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'dna-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.less']
})
export class ImageGalleryComponent implements OnInit {

  @Input() private _images: Image[];
  @Input() private _pages: Page[];
  @Input() private _page: Page;

  @Output() private _onUpdatedGrid: EventEmitter<Page[]> = new EventEmitter<Page[]>();
  @Output() private _onImagesChanged: EventEmitter<Image[]> = new EventEmitter<Image[]>();
  @Output() private _onPageAdd: EventEmitter<{ images: Image[], pages: Page[] }> = new EventEmitter<{images: Image[]; pages: Page[]}>();

  private _pagesToCaptures: string[] = [];
  private largeImages: Set<string> = new Set();
  private largeImagesCount: number = 0;
  
  routerSubscription: Subscription;
  currentUrl: string;

  constructor(
    private _modalService: NgbModal, private _utilService: UtilService, private _ref: ChangeDetectorRef,
    private _errorManager: ErrorManagerService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) { 
        if (event.url !== this.currentUrl) {
          this.modalService.dismissAll();
        }
        this.currentUrl = event.url;      
      }
    });
  }

  ngOnInit() {
    this._images.sort((a, b) => {
      if (this.checkCurrent(a)) {
        return -1;
      }
      return a.pagesAdded - b.pagesAdded;
    });
    this._ref.detectChanges();

    this.checkImageSizes();
  }

  private checkImageSizes(): void {
    this._images.forEach(image => {
      if (image.size && image.size.height > 1700) {
        this.largeImages.add(image.id);
      }
    });
    this.largeImagesCount = this.largeImages.size;
  }


  public openModalImage(image: Image, data: string): void {
    const modal = this._modalService.open(ImageModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.title = image.name;
    modal.componentInstance.data = data;
  }

  public checkCurrent(image): boolean {
    if (this._pages[image.pagesAdded - 1]) {
      return this._pages[image.pagesAdded - 1].id === this._page.id;
    } else {
      return false;
    }
  }

  public removeImageFromPage(image: Image): void {
    this.pages.map(p => {
      _.remove(p.content, {'url': image.url});
    });
    this._images.forEach((imageOld, index) => {
      if (imageOld.url === image.url) {
        image.pagesAdded = 0;
        this._images[index] = image;
      }
    });
    this._images.sort((a, b) => {
      return a.pagesAdded - b.pagesAdded;
    });
    this._ref.detectChanges();
  }

    public deleteImage(image: Image, index: number): void {
      this._utilService.translateMessageModal('CONFIRM_DELETE', image.name, '').pipe(
        flatMap((modalContent: ModalContent) => this._utilService.openModalConfirm(modalContent)),
        tap(() => this.removeImageFromPage(image)),
        tap(() => this.images.splice(index, 1)),
        catchError(() => of()),
        take(1)
      ).subscribe(() => {
        this._ref.detectChanges();
        this._onUpdatedGrid.emit(this.pages);
        this._onImagesChanged.emit(this.images);
      });
    }


  private _checkIfSpaceAvailable(page: Page, item: Content): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        if (page.options.api.getNextPossiblePosition(item)) {
          page.content.push(item);
          this._emitChange();
          this._addPageToCapture(page);
          return resolve(true);
        }
        this._errorManager.displayMessage('CANNOT_ADD_ITEM', 'danger');
        return reject(false);
      }, 200);
    });
  }

  private _emitChange(): void {
    const pages = this.pages.map(p => _.omit(p, ['options']));
    this._onUpdatedGrid.emit(pages);
  }

  private _addPageToCapture(page: Page): void {
    if (this._pagesToCaptures.find(id => id === page.id) === undefined) {
      this._pagesToCaptures.push(page.id);
    }
  }

  public addImageToPage(image: Image, page: Page): void {
    const item = {
      x: 0,
      y: 0,
      cols: 15,
      rows: 10,
      type: GridContentType.Image,
      url: image.url,
      data: image.data,
      name: image.name,
      id: this._utilService.generateRandomID(5)
    };
    this._checkIfSpaceAvailable(page, item).then(() => {
      this._onPageAdd.emit({images: this.images, pages: this.pages});
    });
  }


  public get images(): Image[] {
    return this._images;
  }

  public set images(value: Image[]) {
    this._images = value;
  }

  public get pages(): Page[] {
    return this._pages;
  }

  public set pages(value: Page[]) {
    this._pages = value;
  }

  public get page(): Page {
    return this._page;
  }

  public set page(value: Page) {
    this._page = value;
  }

  public sortByPageAdd(image: Image[]) {
    return image.sort((a, b) => {
      return a.pagesAdded - b.pagesAdded;
    });
  }

  public applyHoverStyle(event: Event, imageId: string) {
    const element = event.currentTarget as HTMLElement;
    if (!this.isImageLarge(imageId)) {
      element.style.border = '2px solid #1ab394';
    }
  }

  public removeHoverStyle(event: Event, imageId: string) {
    const element = event.currentTarget as HTMLElement;
    if (!this.isImageLarge(imageId)) {
      element.style.border = 'none';
    }
  }

  public isImageLarge(imageId: string): boolean {
      const isLarge = this.largeImages.has(imageId);
      return isLarge;
  }

  public getLargeImagesCount(): number {
    return this.largeImages.size;
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
