import { ErrorManagerService } from './../../services/errorManager.service';
import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign, Language, Languages, Workspace } from '../../../types';
import { WorkspaceService } from '../../../general/my-profile/workspaces/workspaces.service';

import * as _ from 'lodash';
import { DNATranslateService } from '../../services/translate.service';
import { finalize } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'dna-milor-additionalinfos-modal',
    templateUrl: './milorAdditionalInfos.component.html'
})

export class MilorAdditionalInfosComponent implements OnInit {

    @Input() campaign: Campaign;
    @Input() metier: string;
    // @Input() recipients: { display: string, value: string }[] = [];
    @Input() title: string = "";
    @Input() lang: Language = Languages.English;

    private titleTemplate: string = "";
    public activiewSelected: boolean = false;
    public orchestraSelected: boolean = false;
    public workspaceSelected: boolean = false;
    public workspacesSelected: any = {};
    public workspaces: Workspace[] = [];
    public languages: string[];
    showSpinner: boolean;

    constructor(
        private activeModal: NgbActiveModal,
        private errorManager: ErrorManagerService,
        private workspaceService: WorkspaceService,
        private translateService: DNATranslateService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.translateService.getLanguages().then((res) => {
            this.languages = res;
        });

        this.workspaceSelected = false;

        this.titleTemplate = "{{bridge}} - SEE - {{actiview}}{{fieldwork}} [ {{studyname}} ] {{metier}} - {{orchestra}}Study report - {{lang}}";

        this.showSpinner = true;
        this.workspaceService.getWorkspacesInLocal().pipe(
            finalize(() => this.showSpinner = false)
        ).subscribe((wk) => {
            this.workspaces = wk
            this.updateOptions();
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }

    validate(title: string, recipients: { display: string, value: string }[] = []) {
        if (0 === title.length) {
            this.errorManager.displayMessage("TITLE_EMPTY", 'warning', { timeOut: 8000 });
        }
        // else if (_.isEmpty(recipients)) {
        //     this.errorManager.displayMessage("RECIPIENTS_EMPTY_LIST", 'warning', { timeOut: 8000 });
        // }
        else {
        //    this.activeModal.close({ "title": title, "recipients": recipients.map(r => r.value) });
        this.activeModal.close({ "title": title });
        }
    }

    selectOption(element: any, event: any, content?: any) {
        if (event.stopPropagation) event.stopPropagation();
        switch (element) {
            case 'actiview':
                this.activiewSelected = !this.activiewSelected;
                break;
            case 'orchestra':
                this.orchestraSelected = !this.orchestraSelected;
                break;
            case 'workspace':
                if (this.workspacesSelected[content.id]) {
                    delete this.workspacesSelected[content.id];
                } else {
                    this.workspacesSelected[content.id] = content.name;
                }
                break;
            case 'hybrid':
                break;
            default:
                break;
        }

        this.updateTitle();
    }

    hybridStudySelected(isActive: boolean) {
        console.log(isActive);
        this.workspaceSelected = isActive;
    }

    changeLanguage(language: Language) {
        this.lang = language;
        this.updateTitle();
    }

    ISOCodeToLanguage(isoCode: string) {
        switch (isoCode) {
            case "EN":
                return "english";
            case "FR":
                return "french";
            case "JA":
                return "japanese";
            case "ZH":
                return "chinese";
            case "PT":
                return "portuguese";
            case "ES":
                return "spanish";
            default:
                return "english";
        }
    }

    updateTitle() {
        let title = this.titleTemplate;
        title = title.replace("{{bridge}}", this.campaign.synergy.requestNumber);
        const actiview = _.get(this.campaign, 'actiview.activityNumber', "");
        title = title.replace("{{actiview}}", this.activiewSelected && actiview !== "" ? actiview + " - " : "");
        title = title.replace("{{fieldwork}}", _.get(this.campaign, 'fieldWork.id', ""));
        title = title.replace("{{studyname}}", this.campaign.name);
        if (this.workspaceSelected && this.workspacesSelected && Object.keys(this.workspacesSelected).length) {
            const metiers = Object.values(this.workspacesSelected).join(' - ');
            title = title.replace("{{metier}}", metiers);
        }
        else {
            title = title.replace("{{metier}}", this.metier);
        }
        const orchestra = _.get(this.campaign, 'orchestra.orchestraNumber', _.get(this.campaign, 'synergy.orchestra', ""));
        title = title.replace("{{orchestra}}", this.orchestraSelected && orchestra !== "" ? orchestra + " - " : "");
        title = title.replace("{{lang}}", this.utilService.languageToISOCode(this.lang));
        this.title = title;
    }

    updateOptions() {
        console.log('update options');
        //Actiview
        const actiview = _.get(this.campaign, 'actiview.activityNumber', "");
        if (actiview && this.title.indexOf(actiview) > -1) {
            this.activiewSelected = true;
        }
        //Orchestra
        const orchestra = _.get(this.campaign, 'orchestra.orchestraNumber', _.get(this.campaign, 'synergy.orchestra', ""));
        if (orchestra && this.title.indexOf(orchestra) > -1) {
            this.orchestraSelected = true;
        }
        //Hybrid & Workspace
        const workspaces: Workspace[] = _.cloneDeep(this.workspaces);
        const selectedWorkspaces = {};
        workspaces.forEach((value) => {
            if (this.title.indexOf(value.name) > -1) {
                console.log('add workspace', value.id, value.name)
                selectedWorkspaces[value.id] = value.name;
            };
        });
        const selectedWorkspacesKeysLength = (Object.keys(selectedWorkspaces) || []).length;
        if (selectedWorkspacesKeysLength > 0) {
            this.workspacesSelected = _.cloneDeep(selectedWorkspaces);
        }
        //Lang
        const isoLang = this.title.substring(this.title.lastIndexOf('-') + 2);
        this.lang = this.ISOCodeToLanguage(isoLang) as Language;
    }

}
